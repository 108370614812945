<template>
<div>
    <!-- Menu superior -->
    <site-header/>
    <!-- wrapper -->
    <div class="d-flex" id="wrapper">
        <!-- Sidebar -->
        <Sidebar></Sidebar>
        <!-- Page Content -->
        <div class="container-fluid">
            <div class="page-header col-md-12" align="center">
                <h2>Meus imóveis</h2>
            </div>
            <!-- Immobile Box -->
            <div class="row m-1">
                <!-- Loading -->
                <loading
                    :active.sync="isLoading"
                    :is-full-page="false"
                    :can-cancel="false"
                ></loading>
                <!-- fim Loading -->
                <div v-if="announcements && announcements.length > 0" class="col-md-10">
                    <AnnouncementBox v-for="(a, index) in announcements" :immobilebox="a" :key="index"/>
                </div> 
            </div>
        <!-- End Immobile Box -->
        </div>
    <!-- End Page Content-->
    </div>
</div>  
</template>

<script>
import {mapState} from 'vuex';
import AnnouncementBox from '../../components/AnnouncementBox.vue';
import SiteHeader from '../../components/SiteHeader.vue';
import Sidebar from '../../components/Sidebar.vue';
// Import do component de loading
import Loading from 'vue-loading-overlay'
//importa o axios e o http
import {http} from '@/http';


export default {
  name: 'MyImmobile',
  components: {
    //   ImmobileBox,
      AnnouncementBox,
      SiteHeader,
      Sidebar,
      Loading
  },
  data () {
    return {
        isLoading: false,
        announcements: [],
        endpoint_immobile_index: process.env.VUE_APP_ANNOUNCEMENTS_OF_USER,
    }
  },
  mounted () {
      let advertiser = this.user.advertiser
      this.isLoading = true
        if(advertiser){
            this.isLoading = true
            this.obtemDados(this.endpoint_immobile_index+advertiser.id)
        }
    },
    computed: {
        ...mapState(['user'])
    },
    methods: {
        async obtemDados (url) {
            let dados = {};
            let token = localStorage.getItem('token')
            await http.get(url,
            { headers: {
                'Content-type': 'multipart/form-data',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
                } 
            })
            .then(response => {
                this.isLoading = false
                dados = response.data
                console.log('dados', dados)
                this.announcements = dados
                })
            .catch(function (error) {
                console.log(error);
            })
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* Importando o bootstrap 4 */
@import '../../assets/styles/bootstrap4/bootstrap.min.css';

/* Importando o estilo da pagina*/
@import '../../assets/styles/simple_sidebar.css';

/*Estilo para editar o tamanho da imagem do usuario que postou o imovel*/
#img-profile{
    position: relative;
    width: 80%;
    height: 80%;
    border-radius: 90%;
}

/* Estilo para editar o tomanho do icone de area */
#icon-area{
    position: relative;
    width: 8%;
    height: 90%;    
}

/*Estilo para retirar sublinhado do texto dentro de um link */
#link-immobile{
    text-decoration: none;
    color: black;
}

/*Estilo para editar imagem*/
#img-immobile-text{
    position: absolute;
    top: 0%;
    margin-left: 0%;
    padding: 1%;
    color: white;
    background: rgba(255, 0, 0, .7);
    border-radius: 10%;
}
</style>
