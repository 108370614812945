<template>
  <div class="container-fluid">
    <topo-com-ajuda />
    <section class="jumbotron text-center">
        <div class="container">
          <h1 class="jumbotron-heading">Entre para o Clube de Imóveis</h1>
          <p class="lead text-muted">Uma plataforma com foco principal em venda e locação <strong>SEGURA</strong> de imóveis.</p>
          <p class="lead text-muted">Selecione o seu tipo de perfil para continuar o seu cadastro</p>
          <div class="d-flex justify-content-center">
            <div class="p-2">
              <router-link to="/realtor-login" tag="button" class="btn btn-lg btn-primary my-5 p-5">Corretor</router-link>
            </div>
            <div class="p-2">
              <router-link to="/cadastro-proprietario" tag="button" class="btn btn-lg btn-success my-5 ml-4 p-5">Proprietário de Imóvel</router-link>  
            </div>
          </div>
          <p>
            
            
          </p>
        </div>
      </section>
  </div>

</template>

<script>
/* Importantando a navbar do site */
import TopoComAjuda from '../../components/TopoComAjuda.vue'
export default {
  name: 'Anuncie',
  components:{
    TopoComAjuda
  },
  data () {
    return { }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  /* Importando o bootstrap 4 */
  @import "../../assets/styles/bootstrap4/bootstrap.min.css";
</style>
