<template>
    <div>
        <!-- Header Menu Principal -->
        <site-header />
        <div class="d-flex" id="wrapper">
        <!-- Sidebar Menu Lateral -->
        <sidebar/>
        <!-- loading -->
        <div class="flex justify-content-center ">
            <div v-if="loading" class="spinner-border text-success" style="width:3rem; heigth:3rem;" role="status"><span class="sr-only">Loading...</span></div>
        </div>
        <div class="container-fluid" v-if="!loading">
            <!-- As a heading -->
            <nav class="navbar navbar-light bg-light col-md-12">
                <span class="navbar-brand mb-0 h1">Informações do corretor</span>
            </nav>
            <div class=" col-md-2 float-md-right mt-4">
                <img :src="img" class="img-thumbnail" alt="Imagem de perfil">
            </div>
            <br>
            <form class="needs-validation" novalidate>
                <br>
                <h5 class="text-secondary font-weight-bold text-uppercase">Dados pessoais</h5>
                <div class="row">
                    <div class="col">
                    <span  :class="{'badge-success': isActive, 'badge-warning':!isActive}" class="badge">{{status}}</span>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-6 mb-3">
                        <label for="name">Nome</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-user"></i></span>
                            </div>
                            <span class="form-control">{{name +' '+ surname}}</span>
                        </div>
                    </div>

                    <div class="col-md-6 mb-3">
                        <label for="email">Email</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-mail-bulk"></i></span>
                            </div>
                            <span class="form-control">{{email}}</span>
                        </div>
                    </div>

                    <div class="col-md-6 mb-3">
                        <label for="cpf">CPF</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-clipboard-list"></i></span>
                            </div>
                            <span class="form-control">{{cpf}}</span>
                        </div>
                    </div>

                    <div class="col-md-6 mb-3">
                        <label for="telephone">Telefone</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-phone"></i></span>
                            </div>
                            <span class="form-control">{{telephone}}</span>
                        </div>
                    </div>
                </div>

                <hr size="10" width="95%" align="center">
                <h5 class="text-secondary font-weight-bold text-uppercase">Endereço</h5>
                <div class="form-row">
                    <div class="col-md-3 mb-3">
                        <label for="contry">Pais</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-globe-americas"></i></span>
                            </div>
                            <span class="form-control">{{country}}</span>
                        </div>
                    </div>

                    <div class="col-md-3 mb-3">
                        <label for="state">Estado</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-map"></i></span>
                            </div>
                            <span class="form-control">{{state}}</span>
                        </div>
                    </div>

                    <div class="col-md-3 mb-3">
                        <label for="city">Cidade</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-map-marked-alt"></i></span>
                            </div>
                            <span class="form-control">{{city}}</span>
                            <!-- <input type="text" class="form-control" id="city" :value="city" aria-describedby="inputGroupPrepend" readonly> -->
                        </div>
                    </div>

                    <div class="col-md-3     mb-3">
                        <label for="cep">Cep</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-flag"></i></span>
                            </div>
                            <span class="form-control">{{cep}}</span>
                            <!-- <input type="text" class="form-control" id="cep" :value="cep" aria-describedby="inputGroupPrepend" readonly> -->
                        </div>
                    </div>

                    <div class="col-md-4 mb-3">
                        <label for="neighborhood">Bairro</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-map-marked-alt"></i></span>
                            </div>
                            <span class="form-control">{{neighborhood}}</span>
                            <!-- <input type="text" class="form-control" id="neighborhood" :value="neighborhood" aria-describedby="inputGroupPrepend" readonly> -->
                        </div>
                    </div>

                    <div class="col-md-6 mb-3">
                        <label for="street">Logradouro</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-map-marked-alt"></i></span>
                            </div>
                            <span class="form-control">{{street}}</span>
                            <!-- <input type="text" class="form-control" id="street" :value="street" aria-describedby="inputGroupPrepend" readonly> -->
                        </div>
                    </div>

                    <div class="col-md-2 mb-3">
                        <label for="number">Número</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-map-marked-alt"></i></span>
                            </div>
                            <span class="form-control">{{number}}</span>
                            <!-- <input type="text" class="form-control" id="number" :value="number" aria-describedby="inputGroupPrepend" readonly> -->
                        </div>
                    </div>
                </div>

                <hr size="10" width="95%" align="center">
                <h5 class="text-secondary font-weight-bold text-uppercase">Informações profissionais</h5>
                <div class="form-row">
                    <div class="col-md-6 mb-3">
                        <label for="function">Função</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-id-card-alt"></i></span>
                            </div>
                            <span class="form-control">{{role}}</span>
                            <!-- <input type="text" class="form-control" id="function" :value="role" aria-describedby="inputGroupPrepend" readonly> -->
                        </div>
                    </div>

                    <div class="col-md-6 mb-3">
                        <label for="creci">Creci</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-id-card"></i></span>
                            </div>
                            <span class="form-control">{{creci}}</span>
                            <!-- <input type="text" class="form-control" id="creci" :value="creci" aria-describedby="inputGroupPrepend" readonly> -->
                        </div>
                    </div>
                </div>
                <div class="form-row">   
                    <div class="col-md-12">
                    <hr size="10" width="95%" align="center">
                    <!-- <h6 class="p-2 mb-2 bg-primary text-white col-md-2 text-center b rounded">Documentos</h6> -->
                    <h5 class="text-secondary font-weight-bold text-uppercase">Documentos</h5>
                    </div> 
                    
                    <div class="col-md-4 mb-5 mt-3">
                        <span>Registro Creci</span>
                        <br>
                        <img :src="imgCreci" @click="aumentarImagemRg" class="rounded" :class="{'tamanhoNormal': !aumentarImgRg, 'tamanhoAumentado1': aumentarImgRg}" alt="Imagem de perfil">
                    </div>

                    <div class="col-md-4 mb-5 mt-3">
                        <span>RG</span>
                        <br>
                        <img :src="imgRg" @click="aumentarImagemCreci" class="rounded" :class="{'tamanhoNorma2': !aumentarImgCreci, 'tamanhoAumentado2': aumentarImgCreci}" alt="Imagem de perfil">
                    </div>

                    <div class="col-md-4 mb-5 mt-3">
                        <span>Comp. Endereço</span>
                        <br>
                        <img :src="imgComprovante" @click="aumentarImagemComprovante" class="rounded" :class="{'tamanhoNorma3': !aumentarImgComprovante, 'tamanhoAumentado3': aumentarImgComprovante}" alt="Imagem de perfil">
                    </div>
                </div>
                
                    <div class="d-flex justify-content-center mb-5">
                        <div class="p-2" v-if="!isActive">
                            <button @click.prevent="ativarCorretor()" class="btn btn-success">Aprovar Corretor</button>
                        </div>
                        <div class="p-2" v-if="isActive">
                            <button @click.prevent="suspenderCorretor()" class="btn btn-danger">Suspender Corretor</button>
                        </div>
                        <div class="p-2">
                            <router-link to="/list-realtors" tag="button" class="btn btn-info">Lista de Corretores</router-link>
                        </div>
                    </div>
                
            </form>
        </div>
    </div>
    </div>
</template>

<script>
import SiteHeader from '../../components/SiteHeader.vue';
import Sidebar from '../../components/Sidebar.vue';
import axios from 'axios';
import Swal from 'sweetalert2'
export default {
  name: 'ShowProfileRealtorForAdmins',
  components: {
      SiteHeader, Sidebar
  },
  props: {
  },
  data () {
    return {
        endpointRealtorById: process.env.VUE_APP_REALTOR_BY_ID,
        endpointActiveRealtor: process.env.VUE_APP_ACTIVE_REALTOR,
        endpoint_images: process.env.VUE_APP_IMAGES,
        aumentarImgRg: false,
        aumentarImgCreci: false,
        aumentarImgComprovante: false,
        id: 1,
        img:    require('../../assets/img/corretor_image.jpg'),
        imgCreci: require('../../assets/img/creci.jpg'),
        imgRg: require('../../assets/img/rg.png'),
        imgComprovante: require('../../assets/img/comprovante_endereco.jpg'),
        name:"Antônio Marcos",
        surname:"Nascimento de Souza",
        role:"Corretor(a)",
        email:"antmarc1972@gmail.com",
        telephone:"(99) 99113-2168",
        cpf:"002.131.123-12",
        country:"Brasil",
        state:"SP",
        city:"São Paulo",
        cep:"01.540-000",
        neighborhood:"Brás",
        street:"Av. Brigadeiro Felisberto",
        number:"21",
        creci:"CRECI-SP 10002020",
        status:"",
        isActive: false,
        loading: false,
        dados: {}
    }
  },
  async created () {
      const token = localStorage.getItem('token')
      axios.get(this.endpointRealtorById+this.$route.params.id, {
        headers: {
            'Content-type': 'multipart/form-data',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        } 
      }).then( (result) => {
            this.dados = result.data
            console.log('resultado dados do usuario pelo id = ', this.dados)
            this.name         = this.dados.user.name
            this.surname      = ''
            this.email        = this.dados.user.email
            this.telephone    = this.dados.user.profile.telephone
            this.cpf          = this.dados.user.profile.cpf
            this.country      = this.dados.user.profile.country
            this.state        = this.dados.user.profile.state
            this.city         = this.dados.user.profile.city
            this.cep          = this.dados.user.profile.cep
            this.neighborhood = this.dados.user.profile.neighborhood
            this.street       = this.dados.user.profile.public_place
            this.number       = this.dados.user.profile.number
            this.creci        = this.dados.user.profile.registration_creci
            this.status       = this.dados.user.status
            if(this.dados.documents && this.dados.documents.length){
            this.imgCreci = this.endpoint_images+this.dados.documents[0].creci_image
            this.imgRg = this.endpoint_images+this.dados.documents[0].rg_image
            this.imgComprovante = this.endpoint_images+this.dados.documents[0].file_end_image
            }
            this.img = this.endpoint_images+this.dados.user.profile.photograph
            //recebe o status
            this.status = this.dados.user.status
            if(this.status == 'EM VERIFICACAO' || this.status == 'INATIVO' || this.status == 'SUSPENSO'){
                this.isActive = false;
            }else{
                this.isActive = true
            }
            console.log('isActive', this.isActive)
      })
        
        
  },
  methods: {
    aumentarImagemRg(){
        // this.aumentarImgRg = !this.aumentarImgRg;
        //abre a imagem em uma nova aba do navegador
        window.open(this.imgRg, '_blank')
    },
    aumentarImagemCreci(){
        // this.aumentarImgCreci = !this.aumentarImgCreci;
        //abre a imagem em uma nova aba do navegador
        window.open(this.imgCreci, '_blank')
    },
    aumentarImagemComprovante(){
        // this.aumentarImgComprovante = !this.aumentarImgComprovante;
        //abre a imagem em uma nova aba do navegador
        window.open(this.imgComprovante, '_blank')
    },
    ativarCorretor(){
        const token = localStorage.getItem('token')
        const codigo = this.$route.params.id
        //envia o dado
        var dado = new FormData()
        dado.append('id', codigo)
        this.loading = true
        axios({
            method: 'post',
            url: this.endpointActiveRealtor, 
            data: dado,
            headers: {
                'Content-type': 'multipart/form-data',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then( (resultado) => {
            console.log(resultado)
            Swal.fire(
                'Sucesso',
                'Corretor Ativado para anunciar no Clube de Imóveis',
                'success'
            ).then( () => {
                this.$router.push('/list-realtors')
            })
        })
    },
    suspenderCorretor(){}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.tamanhoNormal, .tamanhoNorma2, .tamanhoNorma3{
    width: 100px;
    height: 100px;
}
.tamanhoNormal:hover, .tamanhoNorma2:hover, .tamanhoNorma3:hover{
    cursor: pointer;
}
.tamanhoAumentado1, .tamanhoAumentado2, .tamanhoAumentado3{
    width: 100%;
    height: 100%;
    z-index: 1000;
}
</style>