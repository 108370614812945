<template>
  <div class="conteudo_principal">
    <site-header />

    <section class="secao_principal pt-5">

      <!-- erro 404 -->
      <div class="search-sec">
        <h1 class="titulo_tabs text-center">Erro 404!</h1>
      </div>
    </section>
  </div>

</template>

<script>

/* Importantando a navbar do site */
import SiteHeader from '../../components/SiteHeader.vue'


//importando variaveis de endpoints
import { constantes } from '../../consts.js'

export default {
  name: 'FrontPage',
  components: {
    SiteHeader,
  },
  data () {
    return {
      infoImmobile: [],
      immobileType: 0,
      endpoint_immobile_index: constantes.endpoint_immobile_index
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Importando o bootstrap 4 */
@import "../../assets/styles/bootstrap4/bootstrap.min.css";

@import "../../assets/styles/pagina_inicial.css";

.botao_entrar {
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 32px !important;
}
.botao_corretor {
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 32px !important;
}
</style>
