<template>
<div>
 <site-header/>
    <div class="d-flex" id="wrapper">
    <!-- Sidebar -->
    <Sidebar/>
    <!--<div class="bg-light border-right" id="sidebar-wrapper">
      <div class="sidebar-heading">Clube de Imóveis</div>
      <div class="list-group list-group-flush">
        <router-link to="/myprofile" tag="a" class="list-group-item list-group-item-action bg-light">Meu Perfil</router-link>
                <router-link to="/myfavorite" tag="a" class="list-group-item list-group-item-action bg-light">Favoritos</router-link>
                <router-link to="/myimmobile" tag="a" class="list-group-item list-group-item-action bg-light">Imóveis Cadastrados</router-link>
                <a href="#" class="list-group-item list-group-item-action bg-light">Meus Corretores</a>      
                <router-link to="/confirmbroker" tag="a" class="list-group-item list-group-item-action bg-light">Corretores Cadastrados</router-link>
                 <router-link to="/createimmobile" tag="a" class="list-group-item list-group-item-action bg-light">Cadastrar Imóvel</router-link>
       </div>
    </div>-->
    <!-- /#sidebar-wrapper -->

    <!-- Page Content -->
    <div id="page-content-wrapper">

      

      <div class="container-fluid">
        <h1 class="mt-4">Dashboard</h1>
        <p>Sua sala de análise</p>

        <div class="card">
          <div class="card-body">
            <!-- Títulos -->
            <div class="row">
              <div class="col-md-3 text-center"><h4>Corretores</h4></div>
              <div class="col-md-3 text-center"><h4>Vendedores</h4></div>
              <div class="col-md-3 text-center"><h4>Compradores</h4></div>
              <div class="col-md-3 text-center"><h4>Imóveis</h4></div>
            </div>
            <!-- Dados -->
            <div class="row">
              <div class="col-md-3 text-center"><h1 class="text-primary display-4">10</h1></div>
              <div class="col-md-3 text-center"><h1 class="text-primary display-4">12</h1></div>
              <div class="col-md-3 text-center"><h1 class="text-primary display-4">36</h1></div>
              <div class="col-md-3 text-center"><h1 class="text-success display-4">1736</h1></div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
    <!-- /#page-content-wrapper -->

  </div>
  <!-- /#wrapper -->
  </div>
</template>

<script>
import SiteHeader from '../../components/SiteHeader.vue'
import Sidebar from '../../components/Sidebar.vue'
export default {
  components: { SiteHeader, Sidebar },
  name: 'CommomUserPanel',
  props: {
    msg: String
  },
  data () {
    return {

    }
  },
  computed: {
    loadToken () {
      return this.$store.getters.getToken
    }
  },
  methods: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Importando o bootstrap 4 */
@import '../../assets/styles/bootstrap4/bootstrap.min.css';

/* Importando o estilo da pagina*/
@import '../../assets/styles/simple_sidebar.css';
</style>