<template>
  <div>
    <site-header />
    <loading
      :active.sync="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    ></loading>

    <div
      class="row h-100 justify-content-center align-items-center mb-5"
      style="margin-top:20px"
    >
      <div class="card">

      </div>
      <div class="col-xs-12 col-sm-8 col-md-6">
        <!-- col-sm-offset-2 col-md-offset-3 -->
        <form class="">
          <fieldset>
            <h2>Cadastro de Cliente</h2>
            <hr class="colorgraph">
            <!-- Nome completo -->
            <div class="form-group">
              <!-- <label for="nome completo">Nome Completo</label> -->
              <input
                type="text"
                v-model.trim="name_surname"
                class="form-control"
                :class="{ 'is-invalid': $v.name_surname.$dirty && $v.name_surname.$invalid}"
                @input="$v.name_surname.$touch()"
                placeholder="Nome e sobrenome"
              >
              <small
                class="text-danger"
                v-if="$v.name_surname.$dirty && $v.name_surname.$invalid"
              >O nome completo é obrigatório e deve conter no mínimo 5 caracteres.</small>
            </div>
            <!-- CPF -->
            <!-- <div class="form-group">
						<input type="text" v-model="cpf" v-mask="['###.###.###-##']"  class="form-control input-lg" :class="{ 'is-invalid': $v.cpf.$dirty && $v.cpf.$invalid}" placeholder="CPF">
                        <small class="text-danger" v-if="$v.cpf.$dirty && $v.cpf.$invalid">O CPF é obrigatório.</small>
					</div> -->
            <!-- Email -->
            <div class="form-group">
              <input
                type="email"
                v-model="email"
                id="email"
                class="form-control input-lg"
                :class="{ 'is-invalid': $v.email.$dirty && $v.email.$invalid}"
                placeholder="Email"
              >
              <small
                class="text-danger"
                v-if="$v.email.$dirty && $v.email.$invalid"
              >O email é obrigatório.</small>
            </div>

            <!-- Celular -->
            <div class="form-group">
              <input
                type="text"
                v-model="telephone"
                v-mask="['(##) ####-####', '(##) #####-####']"
                class="form-control"
                :class="{ 'is-invalid': $v.telephone.$dirty && $v.telephone.$invalid}"
                placeholder="Celular"
              >
              <small
                class="text-danger"
                v-if="$v.telephone.$dirty && $v.telephone.$invalid"
              >O Telefone é obrigatória.</small>
            </div>

            <!-- Senha -->
            <div class="form-group">
              <input
                type="password"
                v-model="password"
                id="password"
                class="form-control input-lg"
                :class="{ 'is-invalid': $v.password.$dirty && $v.password.$invalid}"
                placeholder="Senha"
              >
              <small
                class="text-danger"
                v-if="$v.password.$dirty && $v.password.$invalid"
              >A senha é obrigatória.</small>
            </div>
            <!-- Confirme senha -->
            <div class="form-group">
              <input
                type="password"
                v-model="password_confirm"
                class="form-control input-lg"
                :class="{ 'is-invalid': $v.password_confirm.$dirty && $v.password_confirm.$invalid}"
                placeholder="Repetir Senha"
              >
              <small
                class="text-danger"
                v-if="$v.password_confirm.$dirty && $v.password_confirm.$invalid"
              >A confirmação de senha é obrigatória. E deve ser igual a senha.</small>
            </div>

            <!-- Endereço -->
            <!-- <div class="form-group">
						<input type="text" v-model="public_place" :class="{ 'is-invalid': $v.public_place.$dirty && $v.public_place.$invalid}" @input="$v.public_place.$touch()" class="form-control" placeholder="Endereço">
                        <small class="text-danger" v-if="$v.public_place.$dirty && $v.public_place.$invalid">O endereço é obrigatório.</small>
					</div> -->
            <!-- Número -->
            <!-- <div class="form-group">
						<input type="text" v-model="number" class="form-control" placeholder="Número">
					</div> -->
            <!-- Complemento -->
            <!-- <div class="form-group">
						<input type="text" v-model="complement" class="form-control" placeholder="Complemento (Opcional)">
					</div> -->
            <!-- Bairro -->
            <!-- <div class="form-group">
						<input type="text" v-model="neighborhood" class="form-control" placeholder="Bairro">
					</div> -->
            <!-- Estado -->
            <!-- <div class="form-group">
                        <select @change="getCidades($event)" v-model="state" class="form-control">
                            <option value="">Estado</option>
                            <option v-for="state in estadoscidades.states" :key="state.state_id" :value="state.state_id">{{state.state_name}}</option>
                        </select>
					</div> -->
            <!-- Cidade -->
            <!-- <div class="form-group">
                        <select v-model="city" class="form-control" :class="{ 'is-invalid': $v.city.$dirty && $v.city.$invalid}" @input="$v.city.$touch()">
                            <option value="">Cidade</option>
                            <option v-for="c in cidades" :key="c.id" :value="c.name">{{ c.name }}</option>
                        </select>
                        <small class="text-danger" v-if="$v.city.$dirty && $v.city.$invalid">A cidade é obrigatório.</small>
					</div> -->
            <!-- País -->
            <!-- <div class="form-group">
						<input type="text" v-model="country" class="form-control" placeholder="País">
					</div> -->
            <!-- Cep -->
            <!-- <div class="form-group">
						<input type="text" v-model="cep" class="form-control" placeholder="Cep">
					</div> -->
            <!-- Fotografia do perfil -->
            <!--<div class="form-group">
                        <label for="">Foto do perfil</label>
						<file-pond
                                    name="photo"
                                    ref="pond"
                                    maxFiles="1"
                                    allowReorder="true"
                                    labelFileLoading="Carregando..."
                                    credits="null"
                                    itemInsertLocation="before"
                                    label-idle='<span class="fas fa-cloud-upload-alt upload-icon"></span>'
                                    v-bind:allow-multiple="false"
                                    accepted-file-types="image/jpeg, image/png"
                                    v-model="photograph"
                                    v-on:init="handleFilePondInit"/>
					</div>-->
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                @change="isAcceptTerms($event)"
                type="checkbox"
                id="inlineCheckbox1"
                value="option1"
              >
              <label
                class="form-check-label termos-de-uso"
                for="termos de uso"
              >Ao me cadastrar, aceito os <router-link to="/">Termos de Uso</router-link> e <router-link to="/">política de privacidade</router-link> do Clube de Imóveis e afirmo ter mais de 18 anos de idade.</label>
            </div>
            <hr class="colorgraph">
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-12">
                <button
                  v-if="!accept_terms"
                  type="button"
                  class="btn btn-lg btn-secondary btn-block botao_salvar"
                  disabled
                >Salvar</button>
                <button
                  v-else-if="accept_terms"
                  @click.prevent="salvar"
                  type="button"
                  class="btn btn-lg btn-success btn-block botao_salvar"
                >Salvar</button>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </div>

  </div>
</template>
   
<script>
// import json from './../../assets/json/estados-cidades2.json'
import { mask } from 'vue-the-mask'
import { required, minLength, email, sameAs } from 'vuelidate/lib/validators'
import axios from 'axios'
import swal from 'sweetalert'
// Import do component de loading
import Loading from 'vue-loading-overlay';
// Import do stylesheet do loading
import 'vue-loading-overlay/dist/vue-loading.css';
import SiteHeader from '../../components/SiteHeader.vue';
// import '../../components/CepAddress.vue';
// Import Vue FilePond
/*import vueFilePond from 'vue-filepond';
// Import FilePond styles
import 'filepond/dist/filepond.css';
// Import image preview plugin styles
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
// Create component
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);*/

export default {
  directives: { mask },
  name: 'Login',
  components: {
    Loading,
    SiteHeader,
    // FilePond
  },
  props: {
    msg: String
  },
  data () {
    return {
      // option cidades
      // estadoscidades: json,
      // cidades: '',
      role: '["ROLE_GUEST"]',
      name_surname: '',
      // cpf:'',
      email: '',
      password: '',
      password_confirm: '',
      telephone: '',
      // public_place:'',
      // number:'',
      // complement: '',
      // neighborhood:'',
      // city:'',
      // state:'',
      // latitude: '',
      // longitude: '',
      // coordenadas: '',
      // country:'',
      // cep:'',
      accept_terms: false,
      isLoading: false,
      fullPage: true,
      endpoint: process.env.VUE_APP_REGISTER,

      // photograph: ''
    }
  },
  validations: {
    name_surname: {
      required,
      minLength: minLength(5)
    },
    email: {
      required,
      email
    },
    password: {
      required,
    },
    password_confirm: {
      required,
      sameAsPassword: sameAs('password')
    },
    termos_de_uso: {
      required
    },
    // cpf: { required  },
    telephone: { required },
    // cep: { required },
    // public_place: { required },
    // neighborhood: { required },
    // state: { required },
    // city: { required },
  },
  methods: {
    isAcceptTerms (event) {
      if (event.target.checked) {
        this.accept_terms = true
      } else {
        this.accept_terms = false
      }

    },
    /*getCidades (event) {
        let lstcidades = json.cities
        // console.log('event', event.target.value)
        // console.log('array cidades', lstcidades)
        lstcidades.filter(el => el.states_id == event.target.value);
        let cidadesDoEstadoSelecionado = lstcidades.filter(el => el.state_id == event.target.value);
        // console.log('cidade do estado = ', cidadesDoEstadoSelecionado)
        this.cidades = cidadesDoEstadoSelecionado
        // console.log('objeto this',this)
    },*/
    /*handleFilePondInit () {
        console.log('FilePond has initialized');

        // example of instance method call on pond reference
        this.$refs.pond.getFiles();
    },*/
    salvar () {
      this.$v.name_surname.$touch()
      this.$v.email.$touch()
      this.$v.password.$touch()
      // this.$v.cpf.$touch()
      this.$v.password_confirm.$touch()
      this.$v.telephone.$touch()
      // this.$v.city.$touch()
      // this.$v.public_place.$touch()
      // this.$v.neighborhood.$touch()
      if (this.$v.name_surname.$invalid || this.$v.email.$invalid
        || this.$v.password.$invalid || this.$v.password_confirm.$invalid
        || this.$v.telephone.$invalid) {
        //exibe os erros no HTML
      } else {
        //tudo certo pode prosseguir
        //usando a api mapbox para obter coordenadas geográficas do endereço preenchido pelo cliente no formulário de cadastro
        // let estado = this.filtraEstado(this.state);
        // let nomeEstadoSelecionado = estado;
        // let searchMapbox = JSON.stringify( (this.public_place+','+this.number+','+this.city+','+nomeEstadoSelecionado+','+this.cep+','+'Brazil.json').trim() );
        // let urlDoMapbox = encodeURI('https://api.mapbox.com/geocoding/v5/mapbox.places/'+this.public_place.trim()+','+this.number+','+this.city+','+nomeEstadoSelecionado+','+this.cep+','+'Brazil.json');
        // let urlDoMapbox = 'https://api.mapbox.com/geocoding/v5/mapbox.places/'+(searchMapbox.trim() );
        // let config = {
        //     headers: {'content-type': 'application/json'},
        //     params: {
        //         access_token:'pk.eyJ1IjoibmV3dG9ucmVwIiwiYSI6ImNram9rZW95dzE0b3cyeW4wb2l5MmJ4Y3oifQ.DwInb5Ob321M6OxDgi8aLQ',
        //         cachebuster:'1610129327102',
        //         autocomplete:true
        //     },
        // }

        //realiza a consulta ao mapbox e traz coordenadas geográficas do endereço.
        // let self = this;
        // var tests = async () => { let response = await axios.get(urlDoMapbox, config); console.log(response);};
        // console.log('teste usando async await, retorno na variavel tests', tests);
        //método que pega as coordenadas
        //Save response on a variable
        const processaEnvio = async () => {
          try {
            //preloading
            this.isLoading = true

            //obtem as coordenadas geográficas
            // const coordenadas = await axios.get(urlDoMapbox, config);
            // console.log('dados retornados ', coordenadas);
            //fecha o loading
            // this.isLoading = false;
            //seta as coordenadas
            // this.latitude = coordenadas.data.features[0].geometry.coordinates[0];
            // this.longitude = coordenadas.data.features[0].geometry.coordinates[1];
            // console.log('lat', this.latitude);
            // this.latitude = data != undefined ? data.features[0].center[0] : '';
            // this.longitude = data != undefined ? data.features[0].center[1] : '';

            //chama o método que envia os dados para a API
            this.enviaDados({
              name: this.name_surname,
              email: this.email,
              password: this.password,
              role: '["ROLE_CLIENT"]',
              // cpf: this.cpf,
              telephone: this.telephone,
              // country: 'Brasil',
              // state: this.state,
              // city: this.city,
              // public_place_type: 'Rua/Avenida',
              // public_place: this.public_place,
              // number: this.number,
              // neighborhood: this.neighborhood,
              // complement: this.complement,
              // refence: '',
              // cep: this.cep,
              // latitude: this.latitude,
              // longitude: this.longitude,
              // photograph: this.photograph
            });

          }
          catch (err) {
            console.log(err);
          }
        };
        processaEnvio();

        // this.pegaCoordenadas(urlDoMapbox, config, self);
        // let teste = this.pegaCoordenadas(urlDoMapbox, config, self);
        /*let valor = teste.then( r => { 
            console.log('retorno do teste', r);
            self.latitude = r.latitude;
            self.longitude = r.longitude;
            return r.latitude
        });
        console.log('coordenadas após a Promise do axios', valor);
        let c = self.coordenadas;
        //passa latitude e longitude para variaveis globais
        console.log('coordenadas fora da função axios',c);
        this.latitude = this.coordenadas.latitude;
        this.longitude = this.coordenadas.longitude;*/
        //obtem dados do form
        // console.log('latitude', this.latitude);
      }
    },
    enviaDados (obj) {
      console.log('objeto', obj);
      var formData = new FormData()

      //passando o nome
      formData.append('name', obj.name)
      //passa o email
      formData.append('email', obj.email)
      // passa a senha
      formData.append('password', obj.password)
      // passa a role
      formData.append('role', obj.role)
      // passa cpf
      // formData.append('cpf', obj.cpf)
      // passa telefone
      formData.append('telephone', obj.telephone)
      // passa country
      // formData.append('country', 'Brasil')
      // passa state
      // formData.append('state', obj.state)
      // passa city
      // formData.append('city', obj.city)
      // passa public_place_type
      // formData.append('public_place_type', 'Rua/Avenida')
      // passa public_place
      // formData.append('public_place', obj.public_place)
      // passa number
      // formData.append('number', obj.number)
      // passa neighborhood
      // formData.append('neighborhood', obj.neighborhood)
      // passa complement
      // formData.append('complement', obj.complement)
      // passa refence
      // formData.append('refence', '')
      // passa cep
      // formData.append('cep', obj.cep)
      // passa latitude
      // formData.append('latitude', obj.latitude)
      // passa longitude
      // formData.append('longitude', obj.longitude)
      // passa o número creci vazio para usuário comprador ou vendedor
      // formData.append('creci', '');
      // passa photo
      // formData.append('photograph', '')

      //envia os dados do form para a API para salvar o usuário                
      //realiza o envio dos dados para a API
      this.isLoading = true; //exibe o loading
      const self = this;
      axios.post(this.endpoint, formData).then((response) => {
        console.log('resposta da API', response);
        this.isLoading = false; //fecha o loading
        // swal('Sucesso', 'Cadastrado com sucesso.', 'success').then(() => {
        //   this.$router.push({ path: '/' })
        // });
        swal('Sucesso', 'Cadastro em andamento. \n Um código de confirmação foi enviado ao seu email.', 'success').then(() => {
          console.log('self', self);
          self.confirmationCodeRedirect(response.data.user.id);
        })
      }).catch((error) => {
        swal('Erro', 'Desculpe, isso é constrangedor, mas tivemos um erro. Vamos trabalhar para que não ocorra mais falhas. \n Código do erro: ' + error.message, 'error').then(() => {
          //em caso de erro não faz nada, só apresenta a mensagem de erro.
        });
      })
    },
    confirmationCodeRedirect (cod_id) {
      this.$router.push({ name: 'confirmationcode', params: { id: cod_id } });
    },
    /*pegaCoordenadas (urlDoMapbox, config) {
        return axios.get(urlDoMapbox, config).then( response => {
                    this.latitude = response.data.features[0].center[0];
                    this.longitude = response.data.features[0].center[1];
                    this.isLoading = false;
                    // console.log('coordenadas dentro do then do axios', this.coordenadas);
                    // return {
                    //     latitude: response.data.features[0].center[0],
                    //     longitude: response.data.features[0].center[1]
                    //     };
            }).catch( (error) => {
                console.log('mensagem de erro = ', error);
                swal('Erro', 'Houve um erro ao obter as coordenadas geográficas do endereço informado.', 'error').then( () => {
                    //em caso de erro não faz nada, só apresenta a mensagem de erro.
                });
            })
    },*/
    filtraEstado (query) {
      let teste = this.estadoscidades.states;
      const filterItems = (query) => {
        // return teste.filter(el => el.toLowerCase().indexOf(query.toLowerCase()) > -1);
        return teste.filter(el => el.state_id == query);
      };
      return filterItems(query)[0].state_name
    },
    onCancel () {
      console.log('User cancelled the loader.')
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url("https://fonts.googleapis.com/css?family=Roboto");
@import url("https://fonts.googleapis.com/css?family=Italianno");

.botao_salvar {
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 32px !important;
}
.termos-de-uso {
  text-align: left;
  font-family: "Roboto", sans-serif;
  line-height: 1em;
}
h2 {
  font-family: "Italianno", sans-serif;
  font-size: 68px;
  text-shadow: 4px 4px 4px rgb(230, 220, 220);
  /* color:#047204; */
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
