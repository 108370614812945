
<template>
    <div>
        <div class="row">
            <div class="col-md-6">
                <h5 class="mb-0">Agora vamos deixar o seu anúncio fantástico!</h5>
                    
                <p class="mt-2">Adicione suas fotos</p>
                <div class="col-md-12">
                    <small id="small-info">Clique na caixa abaixo ou arraste as fotos para ela.</small>
                </div>
                <div class="col-md-12 mt-4">
                    <div class="col-md-6">
                    
                        <label for="immobile-tipe" class="m-0">Cep<span class="text-danger">*</span></label>
                        <input class="form-control" type="text" v-model="cep" @keyup="searchCep()" v-mask="['########']" placeholder="Digite o cep aqui">
                        <div class="notification is-warning" v-if="cep">
                            <pre>{{ data }}</pre>
                        </div>
                    </div>
                </div>
            </div>
        </div>                    
    </div>
</template>
<script>

import axios from 'axios'
  import {mask} from 'vue-the-mask'

export default {
    directives: {mask},
    name: "CepAddress",
    data(){
        return {
            cep : null,
            data : null,
            messageCep: null
        }
    },
    methods:{
		searchCep () {
			if(this.cep.length == 8) {
				axios.get(`https://viacep.com.br/ws/${ this.cep }/json/`)
				.then( response => this.data = response.data )
				.catch( error => console.log(error) )
			}
		}
	},
}
</script>
<style scoped>

</style>