<template>
        <div class="card mb-4 shadow-sm">
            <!-- Loading -->
                <loading :active.sync="isLoading" 
                :can-cancel="true" 
                :on-cancel="onCancel"
                ></loading>
            <!-- fim Loading -->

            <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                <div class="carousel-inner">
                    <swiper class="swiper" :options="swiperOption">
                        <swiper-slide v-for="(img, index) in immobileBox.photos" :key="index">
                            <img :src="endpoint_images + img.photo" class="bd-placeholder-img card-img-top" alt="Imagem do imóvel">
                        </swiper-slide>
                        <div class="swiper-button-prev" slot="button-prev"></div>
                        <div class="swiper-button-next" slot="button-next"></div>
                    </swiper>
                </div>
            </div>  

            <div class="card-body row">
                <h5 class="card-text font-weight-bold col-md-10 pl-0 pt-2">{{immobileBox.immobile.title}}</h5>
                <p class="card-text mt-0 mb-2 text-muted col-12 pl-0">{{immobileBox.immobile.neighborhood}} - {{immobileBox.immobile.city}} / {{immobileBox.immobile.state}}</p>
                <h5 class="card-text col-8 pl-0 pb-1 mt-1 font-weight-bold text-success">R$ {{immobileBox.immobile.price}}</h5>
                <p class="card-text col-12 p-0 m-0 text-muted"><img src="../assets/img/icons/area.png" id="icon-area" alt=""> {{immobileBox.immobile.ground_area}} m²</p>
                <p v-if="immobileBox.immobile.number_suite === 1" class="card-text col-12 p-0 m-0 text-muted"><i class="fas fa-procedures mr-1"></i> {{immobileBox.immobile.number_suite}} Suíte</p>
                <p v-if="immobileBox.immobile.number_suite > 1" class="card-text col-12 p-0 m-0 text-muted"><i class="fas fa-procedures mr-1"></i> {{immobileBox.immobile.number_suite}} Suítes</p>
                <p v-if="immobileBox.immobile.number_room === 1" class="card-text col-12 p-0 m-0 text-muted"><i class="fas fa-bed mr-1"></i> {{immobileBox.immobile.number_room}} Dormitório</p>
                <p v-if="immobileBox.immobile.number_room > 1" class="card-text col-12 p-0 m-0 text-muted"><i class="fas fa-bed mr-1"></i> {{immobileBox.immobile.number_room}} Dormitórios</p>
                <p v-if="immobileBox.immobile.number_bathroom === 1" class="card-text col-12 p-0 m-0 text-muted"><i class="fas fa-bath mr-2"></i> {{immobileBox.immobile.number_bathroom}} Banheiro</p>
                <p v-if="immobileBox.immobile.number_bathroom > 1" class="card-text col-12 p-0 m-0 text-muted"><i class="fas fa-bath mr-2"></i> {{immobileBox.immobile.number_bathroom}} Banheiros</p>
                <p v-if="immobileBox.immobile.number_space === 1" class="card-text col-12 p-0 m-0 text-muted"><i class="fas fa-car mr-2"></i> {{immobileBox.immobile.number_space}} Garagem</p>
                <p v-if="immobileBox.immobile.number_space > 1" class="card-text col-12 p-0 m-0 text-muted"><i class="fas fa-car mr-2"></i> {{immobileBox.immobile.number_space}} Garagens</p>
                <router-link :to="`/immobileinfo/${immobileBox.immobile.id}`">Mais detalhes</router-link>
            </div>
        </div>      
</template>

<script>
/* import axios from 'axios' */
import { swiper, swiperSlide} from 'vue-awesome-swiper'

// import style (>= Swiper 6.x)
import 'swiper/swiper-bundle.css'

// import style (<= Swiper 5.x)
import 'swiper/swiper.min.css'

// Import do component de loading
import Loading from 'vue-loading-overlay';

export default {
    name: 'FavoriteBox',
    components: {
        swiper,
        swiperSlide,
        Loading,
    },
    props: {
        immobileBox: {}
    },
    data(){
        return {
            isLoading: false,
            swiperOption: {
                slidesPerView: 1,
                spaceBetween: 30,
                effect: 'fade',
                loop: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            },
            favorite: true,
            /*endpointdev_user:  constantes.endpoint_user,
            urldev: constantes.endpoint_favorite,
            endpoint_images: constantes.endpoint_images,*/
            endpointdev_user: process.env.VUE_APP_USER,
            urldev: process.env.VUE_APP_FAVORITE,
            endpoint_images: process.env.VUE_APP_IMAGES,
            user_id: null,
        }
    },
    methods:{
        onCancel () {
            console.log('User cancelled the loader.')
        },
    },
    
}
</script>

<style scoped>

/* Importando o bootstrap 4 */
@import '../assets/styles/bootstrap4/bootstrap.min.css';

/* Importando o estilo da pagina*/
@import '../assets/styles/simple_sidebar.css';

/*Estilo para editar o tamanho da imagem do usuario que postou o imovel*/
#img-profile{
    position: relative;
    width: 80%;
    height: 80%;
    border-radius: 90%;
}

/* Estilo para editar o tomanho do icone de area */
#icon-area{
    position: relative;
    width: 8%;
    height: 90%;    
}

/*Estilo para editar imagem*/
#img-immobile-text{
    position: absolute;
    top: 0%;
    margin-left: 0%;
    padding: 1%;
    color: white;
    background: green;
    border-radius: 10%;
}

#img{
height: 10%;
widows: 10%;
}

/* Estilo do botao de curtir */
#button-broker{
    border: none;
    background: none;
}

#button-broker:focus{
    outline-style: none;
}

.unlike{
    color: #00000030;
    font-size: 2em;
}
.like{
    color: #f44336;
    font-size: 2em;
}
</style>