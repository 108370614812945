<template>
  <div>
    <!-- Sidebar -->
    <div
      class="bg-light border-right"
      id="sidebar-wrapper"
    >
      <div class="sidebar-heading">Clube de Imóveis</div>
      <div class="list-group list-group-flush">
        <router-link
          v-if="isAdmin"
          to="/dashboard"
          tag="a"
          class="list-group-item list-group-item-action bg-light"
        >Dashboard</router-link>
        <router-link
          v-if="isClient"
          to="/myprofile"
          tag="a"
          class="list-group-item list-group-item-action bg-light"
        >Meu Perfil</router-link>
        <router-link
          v-if="isRealtor"
          to="/myprofile"
          tag="a"
          class="list-group-item list-group-item-action bg-light"
        >Meu Perfil</router-link>
        <router-link
          v-if="isAdmin"
          to="/myprofile"
          tag="a"
          class="list-group-item list-group-item-action bg-light"
        >Meu Perfil</router-link>
        <router-link
          v-if="isClient"
          to="/myfavorite"
          tag="a"
          class="list-group-item list-group-item-action bg-light"
        >Favoritos</router-link>
        <router-link
          v-if="isRealtor"
          to="/myimmobile"
          tag="a"
          class="list-group-item list-group-item-action bg-light"
        >Meus Imóveis</router-link>
        <router-link
          v-if="isAdmin"
          to="/list-realtors"
          tag="a"
          class="list-group-item list-group-item-action bg-light"
        >Lista Corretores</router-link>
        <router-link
          v-if="isRealtor"
          to="/createimmobile"
          tag="a"
          class="list-group-item list-group-item-action bg-light"
        >Cadastrar Imóvel</router-link>

        <!-- Links exclusivos de administradores -->
        <router-link
          v-if="isAdmin"
          to="/list-buyers"
          tag="a"
          class="list-group-item list-group-item-action bg-light"
        >Lista Compradores</router-link>

        <router-link
          v-if="isAdmin"
          to="/immobile-admin"
          tag="a"
          class="list-group-item list-group-item-action bg-light"
        >Lista Imóveis</router-link>

      </div>
    </div>
  </div>
</template>

<script scope>
export default {
  data () {
    return {
      isAdmin: false,
      isRealtor: false,
      isClient: false,
      funcoes: []
    }
  },
  mounted () {
    const getUserAuth = localStorage.getItem('user')
    const userAuth = JSON.parse(getUserAuth)
    this.funcoes = JSON.parse(userAuth.user.role)
    const role = userAuth.user.role.replace(/(\[)|(\])|(")/g, "")
    const papel = String(role)
    if (papel == 'ROLE_ADMIN') {
      this.isAdmin = true
    } else if (papel == 'ROLE_REALTOR') {
      this.isAdmin = false
      this.isRealtor = true
    } else if (papel == 'ROLE_CLIENT') {
      this.isAdmin = false
      this.isRealtor = false
      this.isClient = true
    }
    // console.log('isadmin:', this.isAdmin);
  }
}
</script>
