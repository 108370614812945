<template>
  <div class="container">
    <!-- Loading -->
    <loading
      :active.sync="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    ></loading>
    <!-- fim Loading -->
    <h1 class="logomarca">Clube de Imóveis</h1>
    <div
      class="row h-100 justify-content-center align-items-center"
      style="margin-top:20px"
    >
      <div class="card">

      </div>
      <div class="col-xs-12 col-sm-8 col-md-6 col-sm-offset-2 col-md-offset-3">
        <!-- <form role="form" class=""> -->
        <fieldset>
          <h2>Login Corretores</h2>
          <hr class="colorgraph">
          <div class="form-group">
            <label for="email">Email</label>
            <input
              v-model.trim="email"
              :class="{ 'is-invalid': $v.email.$dirty && $v.email.$invalid}"
              @input="$v.email.$touch()"
              class="form-control form-control-lg"
              name="email"
              type="email"
              placeholder="Informe seu email"
            >
            <small
              class="text-danger"
              v-if="$v.email.$dirty && $v.email.$invalid"
            >O email é obrigatório e deve conter no mínimo 5 caracteres.</small>
          </div>
          <div class="form-group">
            <label for="senha">Senha</label>
            <input
              v-model="password"
              :class="{ 'is-invalid': $v.password.$dirty && $v.password.$invalid}"
              class="form-control form-control-lg"
              name="password"
              type="password"
              placeholder=""
            >
            <small
              class="text-danger"
              v-if="$v.password.$dirty && $v.password.$invalid"
            >A senha é obrigatória</small>
          </div>
          <span class="button-checkbox">
            <input
              type="checkbox"
              name="remember_me"
              id="remember_me"
              checked="checked"
              class="hidden"
            >
            <button
              type="button"
              class="btn"
              data-color="info"
            >Permanecer conectado</button>
            <a
              href=""
              class="btn btn-link pull-right"
            >Equeceu sua senha?</a>
          </span>
          <hr class="colorgraph">
          <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-6">
              <button
                @click="submitData"
                class="btn btn-lg btn-success btn-block"
              >Entrar</button>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6">
              Não possui cadastro? <button
                type="button"
                class="btn btn-link"
                @click.prevent="register"
              >Cadastre-se</button>
            </div>
          </div>
        </fieldset>
        <!-- </form> -->
      </div>
    </div>
  </div>
</template>

<script>
//importando recursos
import swal from 'sweetalert'
import { required, email } from 'vuelidate/lib/validators'
// Import do component de loading
import Loading from 'vue-loading-overlay';
// Import do stylesheet do loading
import 'vue-loading-overlay/dist/vue-loading.css';


export default {
  name: 'RealtorLogin',
  props: {
    msg: String
  },
  components: {
    Loading
  },
  data () {
    return {
      email: '',
      password: '',
      isLoading: false,
      fullPage: true,
      endpoint_login: process.env.VUE_APP_LOGIN,
    }
  },
  validations: {
    email: {
      required,
      email
    },
    password: {
      required,
    },
  },
  methods: {
    register () {
      this.$router.push('/realtor-registration')
    },
    submitData () {
      this.$v.email.$touch()
      this.$v.password.$touch()
      if (!this.$v.email.$invalid || !this.$v.email.$invalid) {
        //tudo certo pode passar
        //exibe o loading
        this.isLoading = true;
        this.$store.dispatch('loginUser', { email: this.email, password: this.password, url: this.endpoint_login }).then(() => {
          //encerra o loading
          this.isLoading = false;
          this.$router.push('/dashboard')
        }).catch((error) => {
          swal('Erro', error.message).then(() => {
            //encerra o loading
            this.isLoading = false;
          })
        });
      } else {
        //exibe as mensagens de erro no html
      }

    },
    onCancel () {
      console.log('User cancelled the loader.')
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #60a3bc !important;
}
.user_card {
  height: 400px;
  width: 350px;
  margin-top: auto;
  margin-bottom: auto;
  background: #f39c12;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}
.brand_logo_container {
  position: absolute;
  height: 170px;
  width: 170px;
  top: -75px;
  border-radius: 50%;
  background: #60a3bc;
  padding: 10px;
  text-align: center;
}
.brand_logo {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  border: 2px solid white;
}
.form_container {
  margin-top: 100px;
}
.login_btn {
  width: 100%;
  background: #c0392b !important;
  color: white !important;
}
.login_btn:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
.login_container {
  padding: 0 2rem;
}
.input-group-text {
  background: #c0392b !important;
  color: white !important;
  border: 0 !important;
  border-radius: 0.25rem 0 0 0.25rem !important;
}
.input_user,
.input_pass:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #c0392b !important;
}
.logomarca {
  font-family: "Sacramento", cursive;
  text-shadow: 1px 1px 3px #666, -1px -1px 3px #fff, 1px 1px #666,
    -1px -1px #fff;
  font-size: 5em;
  color: #000;
  padding-top: 50px;
  width: 460px;
  margin: auto;
}
</style>
