<template>
  <div v-show="isLogged" class="row position-static ml-5">
    <div class="card mb-3 shadow-sm p-2 bg-white rounded" style="width: 97%;">
      <div>
        <div class="card-title border-bottom">
            <h5 class="text-center">Fale sobre este imóvel</h5>
        </div>
        <!-- Loading -->
              <loading :active.sync="isLoading" 
              :can-cancel="true" 
              :on-cancel="onCancel"
              :is-full-page="fullPage"></loading>
              <!-- fim Loading -->
        <div v-if="!isLoading" class="card-body text-dark mt-0">
          <div class="row mb-2 form-group">
              <div v-if="aguardando" class="alert alert-success" role="alert">
                <h5 class="text-success sucesso">Comentário enviado, após avaliação será exibido</h5>
              </div>
              
              <label for="comentário">Digite seu comentário</label>
              <textarea v-model="comentario" class="form-control mb-2"></textarea>
          </div>  
          <button @click="comentar" type="button" class="btn btn-success btn-lg btn-block col-md-12"> <i class="fas fa-comments mr-3"></i>Enviar o seu comentário</button>
          <!-- <router-link :to="{ name: 'Chat', params: { immobile_id: immobileId, announcement_id: annoucementId }}" tag="button" class="btn btn-danger btn-lg btn-block col-md-12"> <i class="fas fa-comments mr-3"></i>Quero conversar com um corretor</router-link> -->
        </div>
      </div>
        <!-- <div v-else class="card-body mt-2">
          <h6 ><ins>Comentários</ins></h6>
          <list-comments class="mt-4"/>
        </div> -->
    </div>
</div>
</template>
<script>
// Import mapState
import {mapState, mapActions} from 'vuex'
// Import do component de loading
import Loading from 'vue-loading-overlay';
// import da listagem de comentários
// import ListComments from '../comments/ListComments.vue'
// import axios
import Swal from 'sweetalert2'
export default {
  components: {
        Loading,
        // ListComments
    },
  data () {
    return {
      isLogged: false,
      aguardando: false,
      isLoading: false,
      fullPage: false,
      comentario: '',
      endpoint_comment: process.env.VUE_APP_COMMENT_IN_ANNOUNCEMENT
    }
  },
  async mounted () {
    if(localStorage.getItem('user')){
      this.isLogged = true;
    }
  },
  computed: {
    ...mapState(['user', 'announcement', 'comments']),
  },
  methods: {
    ...mapActions({ 
      setarComentario: (dispatch, payload, options) => {
          return dispatch('setComment', payload, options)
      }
    }),
    async comentar () {
      if(this.user){
        this.isLoading = true
        this.setarComentario({
                  comment: this.comentario,
                  user_id: this.user.user.id,
                  advertiser_id: this.announcement.advertiser_id,
                  announcement_id: this.announcement.id
                }
        ).then( (response) => {
          //exibe o texto de sucesso
          this.aguardando = true
          //limpa o campo comentário
          this.comentario = ''
          //pára o loading
          this.isLoading = false
          console.log('comentarios setados', response)
        }).catch( (error) => {
          //pára o loading
          this.aguardando = false
          //erro no console
          console.log('error in response comment', error)
        })

        setTimeout(() => {
          this.aguardando = false
        },5000);
      }else{
        Swal.fire({
                    icon: 'warning',
                    title: 'Você não está autenticado.',
                    text: 'Somente usuários autenticados podem comentar',
                    footer: '<a href="/register">Crie sua conta</a>'
                })
      }
    },

    onCancel () {
      console.log('User cancelled the loader.')
    },
  }
}
</script>
<style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@500&family=Londrina+Shadow&display=swap&family=Pacifico&display=swap');
  .sucesso{
    font-family: 'Lexend Deca', sans-serif;
    /* font-family: 'Londrina Shadow', cursive; */
    /* font-family: 'Pacifico', cursive; */
  }
</style>