import axios from 'axios'

export const http = axios.create(
  { 
    baseURL: '',
    headers: {
      'Content-type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  }
);

/*import axios from 'axios'

const http = axios.create({
  baseURL: '',
  headers: {
    Accepted: 'application/x-www-form-urlencoded',
    'Content-Type': 'application/json',
  },
});

http.interceptors.request.use(
  config => {
    let token = localStorage.getItem("token")
    config.headers = Object.assign({
      Authorization: `Bearer ${token}`
    }, config.headers)
    return config
  },
  (error) => Promise.reject(error),
);

export default http;*/