<template>
  <div>
    <site-header />
    <div
      class="d-flex"
      id="wrapper"
    >
      <div
        class="bg-light border-right"
        id="sidebar-wrapper"
      >
        <Sidebar></Sidebar>
      </div>
      <!-- /#sidebar-wrapper -->

      <!-- Page Content -->
      <div id="page-content-wrapper">
        <div class="containeredit">
          <h2>Editar imóvel</h2>
          <div class="conteudo">
            <div class="sectitulo">
              <h3>Sobre o imóvel</h3>
            </div>
            <div class="inicioform">
              <div class="sellbuy">
                <label class="m-2 p-0">Alugar ou vender?</label>

                <button
                  @click="buttonRent()"
                  :class="{'btn btn-light': Imovel.availability == 'Vender', 'btn btn-success': Imovel.availability == 'Alugar'}"
                  class="rounded mr-1"
                >Alugar</button>
                <button
                  @click="buttonSell()"
                  :class="{'btn btn-success': Imovel.availability == 'Vender', 'btn btn-light': Imovel.availability == 'Alugar'}"
                  class="rounded ml-1"
                >Vender</button>
              </div>
            </div>

            <div
              class="formgroup"
              v-if="Imovel.availability == 'Vender'"
            >
              <div class="estadoimovel">
                <label class="m-2">Qual o estado do seu imóvel?</label>
                <button
                  @click="buttonNew()"
                  :class="{'btn btn-success': Imovel.state_immobile == 'Novo', 'btn btn-light': Imovel.state_immobile == 'Usado'}"
                  class="rounded mr-1"
                >Novo</button>
                <button
                  @click="buttonUsed()"
                  :class="{'btn btn-success': Imovel.state_immobile == 'Usado', 'btn btn-light': Imovel.state_immobile == 'Novo'}"
                  class="rounded ml-1 "
                >Usado</button>
              </div>
            </div>

            <div class="formgroup">
              <div class="selectgroup">
                <label class="m-2 p-0">Tipo de imóvel</label>
                <select
                  v-model="Imovel.type_id"
                  class="form-select form-control"
                  id="immobile-type"
                >
                  <option
                    disabled
                    value="0"
                  >Escolha o tipo de imóvel</option>
                  <option value="2">Apartamento</option>
                  <option value="1">Casa</option>
                  <option value="3">Chácara</option>
                  <option value="4">Cobertura</option>
                  <option value="5">Duplex</option>
                  <option value="6">Edifício</option>
                  <option value="8">Estúdio</option>
                  <option value="7">Escritorio</option>
                  <option value="10">Flat</option>
                  <option value="9">Fazenda</option>
                  <option value="12">Garagem</option>
                  <option value="11">Galpão</option>
                  <option value="13">Kitnet</option>
                  <option value="14">Loft</option>
                  <option value="16">Lote</option>
                  <option value="15">Loja</option>
                  <option value="17">Negócio</option>
                  <option value="18">Ponto Comercial</option>
                  <option value="19">Prédio</option>
                  <option value="21">Sobrado</option>
                  <option value="20">Sítio</option>
                  <option value="22">Terreno</option>
                </select>
              </div>
            </div> <!-- fim do tipo de imovel -->

            <div class="formgroup">
              <div class="rowimovel">
                <div class="col-md-6">
                  <p
                    class="m-0"
                    v-if="want_sell == 'Alugar'"
                  >Valor do aluguel</p>
                  <p
                    class="m-0"
                    v-else
                  >Valor do imóvel</p>
                </div>
                <span class="realspan">R$</span>
                <input
                  v-if="want_sell == 'Alugar'"
                  v-model.lazy="Imovel.price"
                  v-money='money'
                  type="text"
                  class="col-4 form-control m-0 text-success"
                >
                <input
                  v-else
                  v-model.lazy="Imovel.price"
                  v-money='money'
                  type="text"
                  class="col-4 form-control m-0 text-success"
                >
              </div>
              <div class="rowimovel">
                <div class="col-md-6">
                  <p class="m-0">IPTU </p>
                </div>
                <span class="realspan">R$</span>
                <input
                  v-model.lazy="Imovel.value_iptu"
                  v-money='money'
                  type="text"
                  class="col-4 form-control m-0 text-success"
                >
              </div>
              <div class="rowimovel">
                <div class="col-md-6">
                  <p class="m-0">Condomínio </p>
                </div>
                <span class="realspan">R$</span>
                <input
                  v-model.lazy="Imovel.value_condominium"
                  v-money='money'
                  type="text"
                  class="col-4 form-control m-0 text-success"
                >
              </div>
            </div> <!-- fim do preço do imovel -->

            <div class="formgroup">
              <div class="rowimovel">
                <div class="col-md-7">
                  <p v-if='Imovel.number_room == 0 || Imovel.number_room == 1'>Quarto</p>
                  <p v-else>Quartos</p>
                </div>
                <div class="containercomodos">
                  <button
                    type="button"
                    @click="minusBedroom()"
                    class="btn btn-light ml-4  border border-success"
                  ><span class="fas fa-minus text-success"></span></button>
                  <p
                    class="m-0 text-success"
                    style="font-weight: bold; font-size: 13pt;"
                  >{{Imovel.number_room}}</p>
                  <button
                    type="button"
                    @click="plusBedroom()"
                    class="btn btn-light border border-success"
                  ><span class="fas fa-plus text-success"></span></button>
                </div>

              </div>
              <div class="rowimovel">
                <div class="col-md-7">
                  <p v-if='Imovel.number_suite == 0 || Imovel.number_suite == 1'>Suíte</p>
                  <p v-else>Suítes</p>
                </div>
                <div class="containercomodos">
                  <button
                    type="button"
                    @click="minusSuite()"
                    class="btn btn-light ml-4  border border-success"
                  ><span class="fas fa-minus text-success"></span></button>
                  <p
                    class="m-0 text-success"
                    style="font-weight: bold; font-size: 13pt;"
                  >{{Imovel.number_suite}}</p>
                  <button
                    type="button"
                    @click="plusSuite()"
                    class="btn btn-light border border-success"
                  ><span class="fas fa-plus text-success"></span></button>
                </div>

              </div>
              <div class="rowimovel">
                <div class="col-md-7">
                  <p v-if='Imovel.number_bathroom == 0 || Imovel.number_bathroom == 1'>Banheiro</p>
                  <p v-else>Banheiros</p>
                </div>
                <div class="containercomodos">
                  <button
                    type="button"
                    @click="minusBathroom()"
                    class="btn btn-light ml-4  border border-success"
                  ><span class="fas fa-minus text-success"></span></button>
                  <p
                    class="m-0 text-success"
                    style="font-weight: bold; font-size: 13pt;"
                  >{{Imovel.number_bathroom}}</p>
                  <button
                    type="button"
                    @click="plusBathroom()"
                    class="btn btn-light border border-success"
                  ><span class="fas fa-plus text-success"></span></button>
                </div>

              </div>
              <div class="rowimovel">
                <div class="col-md-7">
                  <p v-if='Imovel.number_space == 0 || Imovel.number_space == 1'>Vaga</p>
                  <p v-else>Vagas</p>
                </div>
                <div class="containercomodos">
                  <button
                    type="button"
                    @click="minusSpace()"
                    class="btn btn-light ml-4  border border-success"
                  ><span class="fas fa-minus text-success"></span></button>
                  <p
                    class="m-0 text-success"
                    style="font-weight: bold; font-size: 13pt;"
                  >{{Imovel.number_space}}</p>
                  <button
                    type="button"
                    @click="plusSpace()"
                    class="btn btn-light border border-success"
                  ><span class="fas fa-plus text-success"></span></button>
                </div>

              </div>
            </div> <!--  fim dos comodos do imovel -->

            <div class="formgroup">
              <div class="rowimovel">
                <div class="col-md-6">
                  <p>Área total</p>
                </div>
                <span class="realspan">m²</span>
                <input
                  v-mask="['#', '##', '###', '####', '#####', '######', '#######']"
                  v-model="Imovel.ground_area"
                  type="text"
                  class="col-4 form-control m-0 text-success"
                >
              </div>
              <div class="rowimovel">
                <div class="col-md-6">
                  <p>Área útil</p>
                </div>
                <span class="realspan">m²</span>
                <input
                  v-mask="['#', '##', '###', '####', '#####', '######', '#######']"
                  v-model="Imovel.ground_area_util"
                  type="text"
                  class="col-4 form-control m-0 text-success"
                >
              </div>
            </div> <!-- fim da area do imovel -->

            <div class="formgroup">
              <div class="titulo">
                <label
                  class="m-0"
                  for="title"
                >Título</label>
                <textarea
                  v-model="Imovel.title"
                  name="title"
                  id="title"
                  maxlength="60"
                  cols="30"
                  rows="2"
                  placeholder="Exemplo: Apartamento bem localizado com 2 quartos para aluguel."
                  required="required"
                  class="form-control"
                ></textarea>
              </div>
            </div> <!-- fim do titulo -->

            <div class="formgroup">
              <div class="desc">
                <label
                  class="m-0"
                  for="desciption"
                >Descrição</label>
                <textarea
                  v-model="Imovel.description"
                  name="description"
                  id="description"
                  cols="30"
                  rows="10"
                  placeholder="Exemplo: 
  Magnífica casa recém construída com detalhes em mármore. Possui design com total integração a todos os ambientes. A sala principal possui pé direito triplo com 12 metros. Possui 4 suítes, todos os armários planejados, cozinha equipada e completa, área de serviço, dependência de empregados e área de armazenamento.

  Toda a área externa possui paisagismo apresentando uma vista espetacular. Possui duas piscinas, adulta de 20m e uma infantil, hidromassagem e sauna. Além de um amplo espaço gourmet para receber convidados.

  Há vagas cobertas para 12 veículos. Já existe um sistema pronto para receber circuito interno de câmeras de segurança, muros com 5 metros de altura e cercas elétricas. Há guarita para guarda na entrada.

  A casa está localizada numa rua totalmente residencial. Possui ruas largas e estruturadas. Próximo à praças e há apenas 5min de carro do shopping Iguatemi de São Paulo. Há museus, bares e restaurantes próximos.

  Não perca sua chance de conhecer essa incrível mansão em uma das melhores áreas de São Paulo. Possui todo o conforto e segurança que você e sua família precisam!"
                  required="required"
                  class="form-control"
                ></textarea>
              </div>
            </div> <!-- fim da descrição -->
            <div class="sectitulo">
              <h3>Fotos do imóvel</h3>
            </div>

            <div class="mt-2" style="width:50rem">
              <div class="row" >

                  <div v-for="(i,index) in imovelImagem" :key="index" class="col ml-0.1 pl-1 pr-1 mb-2">
                    <img :src="i" alt="" style="width:100%">
                  </div>
                
              </div>
              <div class="row">
                <div class="col-md-12">
                <h5 class="mb-0">Adicione fotos do imóvel</h5>
                <file-pond
                  name="immobile-images"
                  ref="pond"
                  maxFiles="50"
                  allowReorder="true"
                  labelFileLoading="Carregando..."
                  type=''
                  credits="null"
                  itemInsertLocation="before"
                  label-idle='<span class="fas fa-cloud-upload-alt upload-icon"></span>'
                  v-bind:allow-multiple="true"
                  accepted-file-types="image/jpeg, image/png"
                  v-model="myFiles"
                  v-on:init="handleFilePondInit"
                />
                </div>
              </div>
            </div> <!-- fim das fotos -->
            <div class="sectitulo">
              <h3>Endereço do imóvel</h3>
            </div>

            <div class="formgroup">

              <p class="mt-2">Informe o cep do seu imóvel</p>
              <div class="col-md-6">
                <label
                  class="m-0"
                  for=""
                >Cep</label>
                <input
                  v-model="Imovel.cep"
                  v-mask="['########']"
                  type="text"
                  id="cep"
                  placeholder="Digite o cep aqui"
                  required="required"
                  class="form-control"
                >
              </div>
              <div class="col-md-12 mt-4">
                <div class="row mt-2">
                  <div class="col-md-8">
                    <label
                      for="city"
                      class="m-0"
                    >Cidade<span class="text-danger">*</span></label>
                    <input
                      class="form-control"
                      type="text"
                      name="city"
                      v-model="Imovel.city"
                    >
                  </div>
                  <div class="col-md-4">
                    <label
                      for="state"
                      class="m-0"
                    >UF<span class="text-danger">*</span></label>
                    <input
                      class="form-control"
                      type="text"
                      name="state"
                      v-model="Imovel.state"
                    >
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-12">
                    <label
                      for="neighborhood"
                      class="m-0"
                    >Bairro<span class="text-danger">*</span></label>
                    <input
                      class="form-control"
                      type="text"
                      name="neighborhood"
                      v-model="Imovel.neighborhood"
                    >
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-9">
                    <label
                      for="stret"
                      class="m-0"
                    >Logradouro<span class="text-danger">*</span></label>
                    <input
                      class="form-control"
                      type="text"
                      name="stret"
                      v-model="Imovel.public_place"
                    >
                  </div>
                  <div class="col-md-3">
                    <label
                      for="stret"
                      class="m-0"
                    >Nº</label>
                    <input
                      class="form-control"
                      type="text"
                      v-mask="'##########'"
                      v-model="Imovel.number"
                      name="stret"
                    >
                  </div>
                </div>

              </div> <!-- fim do conteudo -->
            </div>

            <div class="containerfin">
              <div class="btnfin">
                <button
                  @click="salvarEdicao(Imovel.id)"
                  class="btn btn-success"
                >Salvar alterações</button>
                <!-- <router-link :to="`/immobileinfo/${Imovel.id}`">
                  <button class="btn btn-danger">Cancelar</button>
                </router-link> -->
                <a @click="$router.go(-1)" class="btn btn-danger" style="color:#fff">Cancelar</a>
              </div>
            </div>

          </div> <!-- fim do conteudo -->
        </div>

      </div>
      <!-- /#page-content-wrapper -->

    </div>
    <!-- /#wrapper -->
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'
import { VMoney } from 'v-money'
import vueFilePond from 'vue-filepond';
import 'filepond/dist/filepond.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import axios from 'axios'


//dependências da tela




import SiteHeader from '../../components/SiteHeader.vue'
import Sidebar from '../../components/Sidebar.vue'
import VueSweetalert2 from 'vue-sweetalert2'; //import do sweet alert 2
import swal from 'sweetalert2/dist/sweetalert2.all.min.js'
//componentes da tela

const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);


export default {
  directives: { mask, money: VMoney },
  components: { SiteHeader, Sidebar },
  name: 'EditImmobile',
  props: {
    msg: String
  },

  data () {
    return {
      Imovel: {},
      imovelImagem: [],
      images: [],
      endpoint_images: process.env.VUE_APP_IMAGES,
      endpoint_immobile: process.env.VUE_APP_IMMOBILE,
      endpoint_update_immobile: process.env.VUE_APP_UPDATEIMMOBILE,
      FilePond,
      want_sell: '',
      condition_1: false,
      condition_2: true,
      myFiles: [],
      ImovelTitulo: '',
      itemobjeto: '',
      messageCep: null,
      messageErro: false,
      money: {
        decimal: ',',
        thousands: '.',
        precision: 2,
        masked: false /* doesn't work with directive */
      },
      VueSweetalert2,
      swal

    }
  },
  mounted() {
    var url = this.endpoint_immobile + '/'
    axios.get(url + this.$route.params.id)
      .then(response => {
        this.Imovel = response.data.data
        this.want_sell = this.Imovel.availability
        var photos = response.data.data.photos
        photos.forEach(img => {
          this.imovelImagem.push(this.endpoint_images + img.photo)
        });
      })
      .catch(function (error) {
        console.log(error)
      })
      .then(function () {
      }) 
  },
  computed: {
    loadToken () {
      return this.$store.getters.getToken
    }
  },

  methods: {
    /**
     * função que envia os dados e recebe a resposta da API atualiza ou
     * não o imóvel.
    */
     async salvarEdicao (idImovel) {
      this.ImovelTitulo = this.Imovel.title

      //envia os dados
      var dados = new FormData()

      //passando o nome
      dados.append('type_id', this.Imovel.type_id)
      // dados.append('type', this.selected.immobile_type)
      dados.append('state_immobile', this.Imovel.state_immobile)
      dados.append('availability', this.Imovel.availability)
      dados.append('country', 'Brasil')
      dados.append('state', this.Imovel.state)
      dados.append('city', this.Imovel.city)
      dados.append('neighborhood', this.Imovel.neighborhood)
      dados.append('public_place_type', this.Imovel.public_place_type)
      dados.append('public_place', this.Imovel.public_place)
      dados.append('number', this.Imovel.number)
      dados.append('cep', this.Imovel.cep)
      dados.append('complement', this.Imovel.complemento)
      dados.append('reference', '')
      dados.append('ground_area_util', this.Imovel.ground_area_util)
      dados.append('ground_area', this.Imovel.ground_area)
      dados.append('number_suite', this.Imovel.number_suite)
      dados.append('number_room', this.Imovel.number_room)
      dados.append('number_bathroom', this.Imovel.number_bathroom)
      dados.append('number_space', this.Imovel.number_space)
      dados.append('description', this.Imovel.description)
      this.Imovel.title = this.ImovelTitulo
      dados.append('title', this.Imovel.title)
      //ajuste na formatacao do valor do preço
      let preco = this.Imovel.price.replace(".","")
      dados.append('price', preco)
      dados.append('value_iptu', this.Imovel.value_iptu)
      dados.append('value_condominium', this.Imovel.value_condominium)
      dados.append('note', 'Variável de teste')
      dados.append('latitude', this.Imovel.latitude)
      dados.append('longitude', this.Imovel.longitude)
      //imagens
      this.$refs.pond.getFiles().forEach(file => {
          this.images.push(file.file)
        });
      this.images.forEach(img => {
        dados.append('images[]', img);
        console.log('imagem', img)
      });
      //exibe o loading
      this.isLoading = true;
      //envia os dados do form para a API para salvar o usuário
      axios.post(
        this.endpoint_update_immobile + idImovel,
        dados,
        {
          headers: {
            'Content-type': 'multipart/form-data',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
          }
        }
      ).then((response) => {
        console.log(response);
        this.isLoading = false;
        this.$swal.fire(
          'Sucesso',
          'Parabéns! imóvel atualizado com sucesso',
          'success'
        ).then((result) => {
          console.log(result);
          //redireciona após o cliquen o botão OK do alerta de sucesso
          this.$router.push(`/immobileinfo/${idImovel}`);
        })
      }).catch((error) => {
        this.isLoading = false;
        console.log(error);
        this.$swal.fire(
          'Erro',
          'Houve um erro. Código do erro: ' + error.message,
          'error'
        )
      })
    },
    buttonSell: function () {
      this.Imovel.availability = 'Vender';

    },

    buttonRent: function () {
      this.Imovel.availability = 'Alugar';
    },

    buttonNew: function () {
      this.Imovel.state_immobile = 'Novo';
    },
    buttonUsed: function () {
      this.Imovel.state_immobile = 'Usado';
    },

    handleFilePondInit: function () {
      console.log('FilePond has initialized');
    },

    plusBedroom: function () {
      this.Imovel.number_room += 1
    },

    minusBedroom: function () {
      if (this.Imovel.number_room == 0) {
        this.Imovel.number_room = 0
      } else {
        this.Imovel.number_room -= 1
      }
    },

    plusSuite: function () {
      this.Imovel.number_suite += 1
    },

    minusSuite: function () {
      if (this.Imovel.number_suite == 0) {
        this.Imovel.number_suite = 0
      } else {
        this.Imovel.number_suite -= 1
      }
    },

    plusBathroom: function () {
      this.Imovel.number_bathroom += 1
    },

    minusBathroom: function () {
      if (this.Imovel.number_bathroom == 0) {
        this.Imovel.number_bathroom = 0
      } else {
        this.Imovel.number_bathroom -= 1
      }
    },

    plusSpace: function () {
      this.Imovel.number_space += 1
    },

    minusSpace: function () {
      if (this.Imovel.number_space == 0) {
        this.Imovel.number_space = 0
      } else {
        this.Imovel.number_space -= 1
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
.containeredit {
  padding: 25px;
}

.sectitulo {
  padding-top: 40px;
  padding-bottom: 10px;
  width: 1000px;
  border-bottom: 1px solid #dadada;
}

.estadoimovel {
  padding-left: 16px;
}

.navlinks {
  list-style-type: none;
}

.navlinks li {
  padding: 5px;
}

.selectgroup {
  width: 400px;
}

.inicioform {
  padding-top: 5px;
  padding-left: 8px;
}

.formgroup {
  padding-top: 45px;
  padding-left: 8px;
}

.conteudo {
  padding: 25px;
  width: 500px;
}

.sellbuy {
  padding-top: 5px;
}

.immobiletype {
  padding-top: 15px;
}

#immobile-type {
  border-style: solid;
  border-width: 1px;
  outline: none;
  margin-left: 15px;
  margin-top: 5px;
}

#immobile-type:focus {
  outline: none;
}

.imovelpreco {
  padding-top: 45px;
  padding-left: 8px;
}

.rowimovel {
  display: flex;
  justify-content: space-between;
  width: 500px;
  padding: 5px;
}

.m-0 {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
}

.realspan {
  color: #28a745;
  margin-left: 1.5rem;
  padding-right: 15px;
  padding-left: 20px;
  font-size: 16px;
  align-self: flex-end;
}

.imovelcomodo {
  padding-top: 45px;
  padding-left: 8px;
}

.inputcomodos {
  width: 50px;
  color: #28a745;
  font-size: 1rem;
  background-color: #fff;
  background-image: none;
  line-height: 1.5;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  text-align: center;
}

.inputcomodos:focus {
  outline: none;
}

.containercomodos {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.containercomodos p {
  padding-left: 10px;
  padding-right: 10px;
}

.imovelarea {
  padding-top: 45px;
  padding-left: 8px;
}

.titulo {
  padding: 5px;
}

.containerfin {
  padding-top: 25px;
  width: 1000px;
}

.btnfin {
  padding-top: 25px;
  padding-left: 8px;
  border-top: 1px solid #dadada;
}

.btnfin button {
  margin-right: 10px;
}

/* Importando o bootstrap 4 */
@import "../../assets/styles/bootstrap4/bootstrap.min.css";

/* Importando o estilo da pagina*/
@import "../../assets/styles/simple_sidebar.css";
</style>