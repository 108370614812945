<template>
  <div>
    <site-header />
    
    <div
      class="d-flex mt-2"
      id="wrapper"
    >
      <div class="container">

        <div
          class="row d-flex justify-content-center"
          style="background-color:#5881eb;"
        >

          <loading
            :active.sync="isLoading"
            :can-cancel="true"
            :on-cancel="onCancel"
            :is-full-page="fullPage"
          >
          </loading>

          <div
            class="col-md-12"
            style="background-color:#fff"
          >
            <form-wizard
              @on-complete="onComplete"
              title=""
              subtitle=""
              shape="circle"
              color="#3abc2a"
              error-color="#ff4949"
            >
              <hr
                width="100%"
                align="center"
                class="mt-0"
              >

              <!-- 1º Passo -->
              <tab-content
                title="Sobre o imóvel"
                icon="fas fa-home"
                :before-change="validateFirstStep"
              >
                <div class="row">
                  <div class="col-md-6">
                    <div class="col-md-12 mt-4 p-0">
                      <div class="btn-group">
                        <label class="m-2 p-0">O que você quer fazer?<span class="text-danger">*</span></label>
                        <button
                          type="button"
                          @click="buttonRent()"
                          :class="{'btn btn-success': !condition_1, 'btn btn-light': condition_1}"
                          class="rounded mr-1"
                        >Alugar</button>
                        <button
                          type="button"
                          @click="buttonSell()"
                          :class="{'btn btn-success': condition_1, 'btn btn-light': !condition_1}"
                          class="rounded ml-1"
                        >Vender</button>
                      </div>
                    </div>

                    <div
                      v-if="want_sell == 'Vender'"
                      class="col-md-12 mt-4 p-0"
                    >
                      <div class="btn-group">
                        <label class="m-2">Qual o estado do seu imóvel?<span class="text-danger">*</span></label>
                        <button
                          type="button"
                          @click="buttonNew()"
                          :class="{'btn btn-success': condition_2, 'btn btn-light': !condition_2}"
                          class="rounded mr-1"
                        >Novo</button>
                        <button
                          type="button"
                          @click="buttonUsed()"
                          :class="{'btn btn-success': !condition_2, 'btn btn-light': condition_2}"
                          class="rounded ml-1"
                        >Usado</button>
                      </div>
                    </div>

                    <div class="col-md-12 mt-4">
                      <label
                        for="immobile-type"
                        class="m-0"
                      >Tipo do imóvel<span class="text-danger">*</span></label>
                      <select
                        id="immobile-type"
                        class="form-select form-control"
                        v-model="immobile_type"
                      >
                        <option
                          disabled
                          value=""
                        >Escolha o tipo do seu imóvel</option>
                        <option
                          v-for="(type,index) in immobile_type_array"
                          :key="index"
                          :value="type.id"
                        >{{ type.description }}</option>
                      </select>
                      <small
                        class="text-danger"
                        v-if="$v.immobile_type.$dirty && $v.immobile_type.$invalid"
                      >É obrigatório informar o tipo do imóvel</small>
                    </div>

                    <div class="col-md-12 mt-5 form-group">
                      <p id="subtitle">Preço</p>
                      <div
                        v-if="want_sell == 'Vender'"
                        class="row align-items-center p-2"
                      >
                        <div class="col-md-6">
                          <p class="m-0">Valor do imóvel<span class="text-danger">*</span></p>
                        </div>
                        <span class="col-auto ml-4 mr-0 text-success">R$</span>
                        <!-- v-mask="['##.###,##', '###.###,##', '#.###.###,##', '##.###.###,##', '###.###.###,##' ]" -->
                        <!--<input
                          type="text"
                          v-model="price"
                          v-mask="['#,##', '##,##', '###,###', '#.###,##', '##.###,##', '###.###,##', '#.###.###,##', '##.###.###,##']"
                          class="col-4 form-control m-0 text-success"
                        >-->
                        <money v-model="price" v-bind="money" class="col-4 form-control m-0 text-success"></money>
                        <small
                          class="text-danger"
                          v-if="$v.price.$dirty && $v.price.$invalid"
                        >É obrigatório informar o valor do imóvel</small>
                      </div>
                      <div
                        v-if="want_sell == 'Alugar'"
                        class="row align-items-center p-2"
                      >
                        <div class="col-md-6">
                          <p class="m-0">Valor do aluguel<span class="text-danger">*</span></p>
                        </div>
                        <span class="col-auto ml-4 mr-0 text-success">R$</span>
                        <money v-model="price" v-bind="money" class="col-4 form-control m-0 text-success"></money>
                        <!--v-mask="['##,##', '###,##', '#.###,##', '##.###,##', '###.###,##', '#.###.###,##']"-->
                        <!--<input
                          type="text"
                          v-model="price"
                          v-mask="['#,##', '##,##', '###,###', '#.###,##', '##.###,##', '###.###,##', '#.###.###,##']"
                          class="col-4 form-control m-0 text-success"
                        >-->
                        <small
                          class="text-danger"
                          v-if="$v.price.$dirty && $v.price.$invalid"
                        >É obrigatório informar o valor do aluguel</small>
                      </div>
                      <div class="row align-items-center p-2">
                        <div class="col-md-6">
                          <p class="m-0">IPTU</p>
                        </div>
                        <span class="col-auto ml-4 mr-0 text-success">R$</span>
                        <input
                          type="text"
                          v-model="iptu"
                          v-mask="['###,###', '#.###,###', '##.###,###', '###.###,###', '#.###.###,###']"
                          class="col-4 form-control m-0 text-success"
                        >
                      </div>

                      <div class="row align-items-center p-2">
                        <div class="col-md-6">
                          <p class="m-0">Condomínio</p>
                        </div>
                        <span class="col-auto ml-4 mr-0 text-success">R$</span>
                        <input
                          type="text"
                          v-model="condominio"
                          v-mask="['###,###', '#.###,###', '##.###,###', '###.###,###', '#.###.###,###']"
                          class="col-4 form-control m-0 text-success"
                        >
                      </div>
                    </div>

                    <div class="col-md-12 mt-5 form-group">
                      <p id="subtitle">Sobre os comodos</p>

                      <div class="row align-items-center p-2">
                        <div class="col-md-7">
                          <p
                            v-if="bedroom == 1 || bedroom == 0"
                            class="m-0"
                          >Quarto</p>
                          <p
                            v-else
                            class="m-0"
                          >Quartos</p>
                        </div>
                        <div class="col-md-1">
                          <p
                            class="m-0 text-success"
                            style="font-weight: bold; font-size: 13pt;"
                          >{{bedroom}}</p>
                        </div>
                        <div
                          class="btn-group col-md-4"
                          role="group"
                        >
                          <button
                            type="button"
                            @click="minusBedroom()"
                            class="btn btn-light ml-4 border border-success"
                          ><span class="fas fa-minus text-success"></span></button>
                          <button
                            type="button"
                            @click="plusBedroom()"
                            class="btn btn-light border border-success"
                          ><span class="fas fa-plus text-success"></span></button>
                        </div>
                      </div>

                      <div class="row align-items-center p-2">
                        <div class="col-md-7">
                          <p
                            v-if="suite == 1 || suite == 0"
                            class="m-0"
                          >Suíte</p>
                          <p
                            v-else
                            class="m-0"
                          >Suítes</p>
                        </div>
                        <div class="col-md-1">
                          <p
                            class="m-0 text-success"
                            style="font-weight: bold; font-size: 13pt;"
                          >{{suite}}</p>
                        </div>
                        <div
                          class="btn-group col-md-4"
                          role="group"
                        >
                          <button
                            type="button"
                            @click="minusSuite()"
                            class="btn btn-light ml-4 border border-success"
                          ><span class="fas fa-minus text-success"></span></button>
                          <button
                            type="button"
                            @click="plusSuite()"
                            class="btn btn-lightborder border-success"
                          ><span class="fas fa-plus text-success"></span></button>
                        </div>
                      </div>

                      <div class="row align-items-center p-2">
                        <div class="col-md-7">
                          <p
                            v-if="bathroom == 1 || bathroom == 0"
                            class="m-0"
                          >Banheiro</p>
                          <p
                            v-else
                            class="m-0"
                          >Banheiros</p>
                        </div>
                        <div class="col-md-1">
                          <p
                            class="m-0 text-success"
                            style="font-weight: bold; font-size: 13pt;"
                          >{{bathroom}}</p>
                        </div>
                        <div
                          class="btn-group col-md-4"
                          role="group"
                        >
                          <button
                            type="button"
                            @click="minusBathroom()"
                            class="btn btn-light ml-4 border border-success"
                          ><span class="fas fa-minus text-success"></span></button>
                          <button
                            type="button"
                            @click="plusBathroom()"
                            class="btn btn-light border border-success"
                          ><span class="fas fa-plus text-success"></span></button>
                        </div>
                      </div>

                      <div class="row align-items-center p-2">
                        <div class="col-md-7">
                          <p
                            v-if="space == 1 || space == 0"
                            class="m-0"
                          >Vaga</p>
                          <p
                            v-else
                            class="m-0"
                          >Vagas</p>
                        </div>
                        <div class="col-md-1">
                          <p
                            class="m-0 text-success"
                            style="font-weight: bold; font-size: 13pt;"
                          >{{space}}</p>
                        </div>
                        <div
                          class="btn-group col-md-4"
                          role="group"
                        >
                          <button
                            type="button"
                            @click="minusSpace()"
                            class="btn btn-light ml-4  border border-success"
                          ><span class="fas fa-minus text-success"></span></button>
                          <button
                            type="button"
                            @click="plusSpace()"
                            class="btn btn-light border border-success"
                          ><span class="fas fa-plus text-success"></span></button>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12 mt-5 form-group">
                      <p id="subtitle">Área</p>
                      <div class="row align-items-center p-2">
                        <div class="col-md-6">
                          <p class="m-0">Área total<span class="text-danger">*</span></p>
                        </div>
                        <span class="col-auto ml-4 mr-0 text-success">m²</span>
                        <input
                          type="text"
                          id="ground_area"
                          v-model="ground_area"
                          v-mask="['#', '##', '###', '####', '#####', '######', '#######']"
                          class="col-4 form-control m-0 text-success"
                        >
                        <small
                          class="text-danger"
                          v-if="$v.ground_area.$dirty && $v.ground_area.$invalid"
                        >É obrigatório informar a área total</small>
                      </div>

                      <div class="row align-items-center p-2">
                        <div class="col-md-6">
                          <p class="m-0">Área útil<span class="text-danger">*</span></p>
                        </div>
                        <span class="col-auto ml-4 mr-0 text-success">m²</span>
                        <input
                          type="text"
                          id="ground_area_util"
                          v-model="ground_area_util"
                          v-mask="['#', '##', '###', '####', '#####', '######', '#######']"
                          class="col-4 form-control m-0 text-success"
                        >
                        <small
                          class="text-danger"
                          v-if="$v.ground_area_util.$dirty && $v.ground_area_util.$invalid"
                        >É obrigatório informar a área útil</small>
                        <blockquote
                          id="blockquote-info"
                          class="col-md-12 mt-2"
                        >
                          <p class="pt-2"><span class="fas fa-exclamation mr-2"></span>A área útil nada mais é que a soma de todos os espaços internos da casa, como os quartos, a sala, a cozinha e os banheiros.</p>
                        </blockquote>
                      </div>
                    </div>

                    <p
                      id="subtitle"
                      class="mb-0 mt-5"
                    >Título</p>
                    <div class="col-md-12">
                      <div class="col-md-12">
                        <label
                          for="title"
                          class="m-0"
                        >Título<span class="text-danger">*</span></label>
                        <textarea
                          class="form-control"
                          name="title"
                          id="title"
                          maxlength="60"
                          cols="30"
                          rows="2"
                          v-model="title"
                          placeholder="Exemplo: Apartamento bem localizado com 2 quartos para aluguel."
                          required
                        ></textarea>
                        <small
                          class="text-danger"
                          v-if="$v.title.$dirty && $v.title.$invalid"
                        >É obrigatório informar o título.</small>
                        <blockquote
                          id="blockquote-info"
                          class="col-md-12 mt-2"
                        >
                          <p class="pt-1 pb-1"><span class="fas fa-exclamation mr-2"></span>O título é a informação que vai descrever resumidamente o seu imóvel. É importante que ele seja direto e objetivo.</p>
                        </blockquote>
                      </div>
                    </div>

                    <p
                      id="subtitle"
                      class="mb-0 mt-5"
                    >Descrição do imóvel</p>
                    <div class="col-md-12">
                      <div class="col-md-12">
                        <label
                          for="description"
                          class="m-0"
                        >Descrição<span class="text-danger">*</span></label>
                        <textarea
                          class="form-control"
                          name="description"
                          id="description"
                          cols="30"
                          rows="10"
                          v-model="description"
                          placeholder="Exemplo: 
    Magnífica casa recém construída com detalhes em mármore. Possui design com total integração a todos os ambientes. A sala principal possui pé direito triplo com 12 metros. Possui 4 suítes, todos os armários planejados, cozinha equipada e completa, área de serviço, dependência de empregados e área de armazenamento.

    Toda a área externa possui paisagismo apresentando uma vista espetacular. Possui duas piscinas, adulta de 20m e uma infantil, hidromassagem e sauna. Além de um amplo espaço gourmet para receber convidados.

    Há vagas cobertas para 12 veículos. Já existe um sistema pronto para receber circuito interno de câmeras de segurança, muros com 5 metros de altura e cercas elétricas. Há guarita para guarda na entrada.

    A casa está localizada numa rua totalmente residencial. Possui ruas largas e estruturadas. Próximo à praças e há apenas 5min de carro do shopping Iguatemi de São Paulo. Há museus, bares e restaurantes próximos.

    Não perca sua chance de conhecer essa incrível mansão em uma das melhores áreas de São Paulo. Possui todo o conforto e segurança que você e sua família precisam!"
                          required
                        ></textarea>
                        <small
                          class="text-danger"
                          v-if="$v.description.$dirty && $v.description.$invalid"
                        >É obrigatório informar a descrição do imóvel.</small>
                        <blockquote
                          id="blockquote-info"
                          class="col-md-12 mt-2"
                        >
                          <p class="pt-1 pb-1"><span class="fas fa-exclamation mr-2"></span>Aqui você pode ficar avontade para descrever da melhor forma possível os detalhes do seu imóvel. Pode destacar ambientes de lazer, areas gourmet ou a localização com relação a proximidades com supermercados, padanificadoras, hospitais.</p>
                        </blockquote>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="row justify-content-md-center position-fixed ml-5">
                      <div
                        class="card border-success mb-3 ml-5 shadow-lg p-2 bg-white rounded"
                        style="max-width: 18rem;"
                      >
                        <div class="card-body text-success mt-0">
                          <span
                            id="icon-light"
                            class="far fa-lightbulb ml-1"
                          ></span>
                          <h6 class="card-title mb-0">Oii! Tudo bem?</h6>
                          <p class="card-text text-justify">Nessa página vamos precisar de algumas informações sobre o seu imóvel.</p>
                          <p class="card-text text-justify">Não esqueça de preencher as informações que estão sinalizadas com o asterisco pois estas são obrigatórias. </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </tab-content>

              <!-- 2º Passo -->
              <tab-content
                title="Insira as fotos"
                icon="fas fa-images"
                :before-change="validateSecondStep"
              >
                <div class="row">

                  <div class="col-md-6">
                    <h5 class="mb-0">Agora vamos deixar o seu anúncio fantástico!</h5>

                    <p class="mt-2">Adicione suas fotos</p>
                    <div class="col-md-12">
                      <small id="small-info">Clique na caixa abaixo ou arraste as fotos para ela.</small>
                    </div>

                    <div class="col-md-12 mt-0 p-0 mb-0">
                      <div id="box-file">
                        <small
                          class="text-danger"
                          v-if="erro_photo"
                        >É obrigatório inluir pelo menos 5 fotos do seu imóvel.</small>
                        <file-pond
                          name="immobile-images"
                          ref="pond"
                          maxFiles="50"
                          allowReorder="true"
                          labelFileLoading="Carregando..."
                          credits="null"
                          itemInsertLocation="before"
                          label-idle='<span class="fas fa-cloud-upload-alt upload-icon"></span>'
                          v-bind:allow-multiple="true"
                          accepted-file-types="image/jpeg, image/png"
                          v-model="myFiles"
                          v-on:init="handleFilePondInit"
                        />
                      </div>
                    </div>

                    <blockquote
                      id="blockquote-info"
                      class="col-md-12"
                    >
                      <p class="pt-1 pb-1"><span class="fas fa-exclamation mr-2"></span>Se você quiser reorganizar a sequência das imagens basta clicar sobre uma imagem e arrastar.</p>
                    </blockquote>

                    <blockquote
                      id="blockquote-info"
                      class="col-md-8"
                    >
                      <p class="pt-1 pb-1"><span class="fas fa-camera-retro mr-2"></span>Você pode adiconar até 50 imagens.</p>
                    </blockquote>
                  </div>

                  <div class="col-md-6">
                    <div class="row justify-content-md-center position-fixed ml-5">
                      <div
                        class="card border-success mb-3 ml-5 shadow-lg p-2 bg-white rounded"
                        style="max-width: 18rem;"
                      >
                        <div class="card-body text-success mt-0">
                          <span
                            id="icon-light"
                            class="far fa-lightbulb ml-1"
                          ></span>
                          <h6 class="card-title mb-0">Dicas para suas fotos ficarem sensacionais</h6>
                          <p class="card-text text-justify mt-1 mb-0"><small id="small-dicas">- Organize o local.</small></p>
                          <p class="card-text text-justify mt-1 mb-1"><small id="small-dicas">- Faça uma sequência lógica.</small></p>
                          <p class="card-text text-justify mt-1 mb-1"><small id="small-dicas">- Fotografe todos os ângulos.</small></p>
                          <p class="card-text text-justify mt-1 mb-1"><small id="small-dicas">- Cuidado com a luminosidade.</small></p>
                          <p class="card-text text-justify mt-1 mb-1"><small id="small-dicas">- Faça fotos diferenciadas.</small></p>
                          <p class="card-text text-justify mt-1 mb-1"><small id="small-dicas">- Regule a altura da foto.</small></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </tab-content>

              <!-- 3º Passo -->
              <tab-content
                title="Localização"
                icon="fas fa-map-marked-alt"
              >
                <div class="row">
                  <div class="col-md-6">
                    <h5 class="mb-0">Para concluir, vamos precisar do endereço completo do seu imovel.</h5>

                    <p class="mt-2">Informe o cep do seu imóvel</p>
                    <div class="col-md-12 mt-4">
                      <div class="col-md-6">

                        <label
                          for="immobile-tipe"
                          class="m-0"
                        >Cep<span class="text-danger">*</span></label>
                        <br>
                        <small
                          class="text-danger"
                          v-if="messageErro"
                        >Cep informado não encontrado</small>
                        <small
                          class="text-danger"
                          v-if="requiredCep"
                        >É obrigatório informar o Cep</small>
                        <input
                          class="form-control"
                          type="text"
                          id="cep"
                          v-model="cep"
                          @keyup="searchCep()"
                          v-mask="['########']"
                          placeholder="Digite o cep aqui"
                          required
                        >
                        <small class="text-primary"><a
                            href="http://www.buscacep.correios.com.br/sistemas/buscacep/default.cfm"
                            target="blank"
                          >Não sabe o cep? Clique aqui!</a></small>
                      </div>
                    </div>

                    <div
                      class="col-md-12 mt-4"
                      v-if="messageCep != null"
                    >
                      <div class="row mt-2">
                        <div class="col-md-8">
                          <label
                            for="city"
                            class="m-0"
                          >Cidade<span class="text-danger">*</span></label>
                          <input
                            class="form-control"
                            type="text"
                            name="city"
                            :value="data.localidade"
                            disabled
                          >
                        </div>
                        <div class="col-md-4">
                          <label
                            for="state"
                            class="m-0"
                          >UF<span class="text-danger">*</span></label>
                          <input
                            class="form-control"
                            type="text"
                            name="state"
                            :value="data.uf"
                            disabled
                          >
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col-md-12">
                          <label
                            for="neighborhood"
                            class="m-0"
                          >Bairro<span class="text-danger">*</span></label>
                          <input
                            class="form-control"
                            type="text"
                            name="neighborhood"
                            :value="data.bairro"
                            disabled
                          >
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col-md-9">
                          <label
                            for="stret"
                            class="m-0"
                          >Logradouro<span class="text-danger">*</span></label>
                          <input
                            class="form-control"
                            type="text"
                            name="stret"
                            :value="data.logradouro"
                            disabled
                          >
                        </div>
                        <div class="col-md-3">
                          <label
                            for="stret"
                            class="m-0"
                          >Nº</label>
                          <input
                            class="form-control"
                            type="text"
                            v-mask="'##########'"
                            v-model="numberAddress"
                            name="stret"
                          >
                        </div>
                      </div>

                      <blockquote
                        id="blockquote-info"
                        class="col-md-12 mt-4"
                      >
                        <p class="pt-1 pb-1"><span class="fas fa-exclamation mr-2"></span>Se o imóvel não possuir número deixe o campo <i class="ml-1 mr-1">Nº </i>vazio.</p>
                      </blockquote>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="row justify-content-md-center position-fixed ml-5">
                      <div
                        class="card border-success mb-3 ml-5 shadow-lg p-2 bg-white rounded"
                        style="max-width: 18rem;"
                      >
                        <div class="card-body text-success mt-0">
                          <span
                            id="icon-light"
                            class="far fa-lightbulb ml-1"
                          ></span>
                          <h6 class="card-title mb-0">O endereço</h6>
                          <p class="card-text text-justify mt-1 mb-0"><small id="small-dicas">O endereço é extremamente importante para que usuarios possam saber onde está localizado o seu imóvel.</small></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- linha do mapa -->
                <div class="row" v-if="this.latitude != null">
                  <div class="col-md-12">
                    <h3 class="display-3">Localização</h3>
                  </div>
                  <div class="col-md-12">
                      <div class="card">
                        <div class="card-body">
                            <!-- Google Maps -->
                            <GmapMap
                                :center="{lat: parseFloat(latitude) , lng: parseFloat(longitude)}"
                                :zoom="16"
                                map-type-id="terrain"
                                style="width: 100%; height: 360px"
                                >
                                <GmapMarker
                                    :position="{lat: parseFloat(latitude) , lng: parseFloat(longitude)}"
                                    :clickable="false"
                                    :draggable="true"
                                    @dragend="ajustaCoordenadas($event.latLng)"
                                /> 
                            </GmapMap>
                        </div>
                    </div>
                  </div>
                </div>
              </tab-content>

              <button
                type="button"
                slot="prev"
                class="btn btn-secondary"
              >Anterior</button>
              <button
                type="button"
                slot="next"
                class="btn btn-success"
              >Próxima</button>
              <button
                type="button"
                slot="finish"
                class="btn btn-primary"
              >Enviar</button>
            </form-wizard>
          </div>
        </div>
      </div>
    </div>
    <!-- End Page Content-->
  </div>
</template>

<script>
//vmoney
import {Money} from 'v-money'
//importa os getters do vuex
import { mapGetters } from 'vuex';
// import Vue from 'vue';
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { mask } from 'vue-the-mask'
import axios from 'axios'
import { required } from 'vuelidate/lib/validators'
//sweet alert
import swal from 'sweetalert'

// Import do component de loading
import Loading from 'vue-loading-overlay'

// Import do stylesheet do loading
import 'vue-loading-overlay/dist/vue-loading.css';

//cidade e estados do brasil
import json from './../../assets/json/estados-cidades2.json'
//sweet alert

// Import Vue FilePond
import vueFilePond from 'vue-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.css';

import '../../components/CepAddress.vue';

/* Importantando a navbar do site */
import SiteHeader from '../../components/SiteHeader.vue'

// Import image preview plugin styles
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';



// Create component
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);

export default {
  directives: { mask },
  name: 'CreateImmobile',
  components: {
    FormWizard,
    TabContent,
    Loading,
    FilePond,
    SiteHeader,
    Money
  },
  data () {
    return {
      money: {
          decimal: ',',
          thousands: '.',
          prefix: '',
          suffix: '',
          precision: 2,
          masked: false
        },
      //Variaveis para armazenar as fotos dos imoveis e uma logica de validacao
      myFiles: [],
      images: [],
      erro_photo: false,

      //faz o teste condional dos botoes de vender ou alugar para assim conseguir mudar o estilo dos botoes
      condition_1: false,

      //faz o teste condional dos botoes de novo ou usado para assim conseguir mudar o estilo dos botoes
      condition_2: true,
      //a variavel want_sell começa com o valor vender significando que o usuario quer vender
      //mas se ele clicar no botao alugar essa variavel vai ser alterada para alugar
      want_sell: 'Alugar',

      //a variavel state_immobile começa com o valor novo significando que o imovel é novo
      //mas se ele clicar no botao usado essa variavel vai ser alterada para usado
      state_immobile: 'Novo',

      //variavel que vai receber a opcao seleciona no select que lista os tipos de imoveis
      immobile_type: '',
      immobile_type_array: [],
      selected: {
        immobile_type: ''
      },

      //variaveis de preco, iptu e condominio
      price: '',
      iptu: '',
      condominio: '',

      //variaveis que irao conter as informacoes sobre a quantidade de cada comodo
      bedroom: 0,
      suite: 0,
      bathroom: 0,
      space: 0,

      //variaveis que irao conter as informacoes de area do imovel
      ground_area: '',
      ground_area_util: '',

      //variaveis para armazenar endereco do imovel
      cep: null,
      data: null,
      messageCep: null,
      messageErro: false,
      requiredCep: false,
      numberAddress: null,
      latitude: null,
      longitude: null,
      coordenadas: null,

      //titulo e descricao do imovel
      title: '',
      description: '',

      estadoscidades: json,
      cidades: json.cities,
      userToken: this.$store.getters.getToken,
      endpoint: process.env.VUE_APP_IMMOBILE,
      endpoint_type_immobile: process.env.VUE_APP_IMMOBILE_TYPE,
      apikey: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
      isLoading: false,
      fullPage: true,
      resposta: null,
      user: JSON.parse(localStorage.getItem('user'))
    }
  },
  validations: {
    immobile_type: { required },
    price: { required },
    ground_area: { required },
    ground_area_util: { required },
    cep: { required },
    title: { required },
    description: { required },

  },
  /*created () {
      console.log('token',localStorage.getItem('token'));
      this.isLoading = true;
      this.resposta = axios.get(this.endpointdev_user, {
      headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
      }).then( (response) => {
          console.log('response', response);
          this.isLoading = false;
          this.user = response.data;
      }).catch( (error) => {
          console.log('erro', error);
      });

      
  
  },*/
  mounted () {
    this.getImmobileTypes();
  },
  computed: {
    ...mapGetters(['getUser', 'getToken']) // aqui os getters recuperam o token e os dados do cliente logado

  },
  methods: {
    getImmobileTypes () {
      //exibe o loading
      this.isLoading = true;
      axios.get(this.endpoint_type_immobile, 
          {
            headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
          })
        .then((response) => {
          console.log(response.data);
          this.immobile_type_array = response.data;
          //esconde o loading
          this.isLoading = false;
        }).catch(response => {
          console.log('error', response.message);
        })
    },
    handleFilePondInit: function () {
      console.log('FilePond has initialized');
    },

    searchCep () {
      if (this.cep.length == 8) {
        //obtém endereço
        axios.get(`https://viacep.com.br/ws/${this.cep}/json/`)
          .then(
            response => { 
              this.data = response.data, 
              this.messageErro = response.data.erro, 
              this.messageCep = response.data.cep }
          ).catch(error => console.log(error))

        //obtém coordenadas geográficas latitude e longitude
        axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${this.cep}&key=${this.apikey}`)
        .then( (response) => {
          // console.log('dados api google maps', response)
          this.latitude = response.data.results[0].geometry.location.lat;
          this.longitude = response.data.results[0].geometry.location.lng;
          // console.log('latitude', this.latitude)
          // console.log('longitude', this.longitude)
        }).catch(
          error => console.log(error)
        )
      }
    },

    // metodos para alternar entre as opcoes de botoes vender e alugar 
    buttonSell: function () {
      this.condition_1 = true;
      this.want_sell = "Vender";
    },
    buttonRent: function () {
      this.condition_1 = false;
      this.want_sell = "Alugar";
    },

    // metodos para alternar entre as opcoes de botoes novo e usado             
    buttonNew: function () {
      this.condition_2 = true;
      this.state_immobile = "Novo";
    },
    buttonUsed: function () {
      this.condition_2 = false;
      this.state_immobile = "Usado";
    },

    //metodos para acrescentar e diminuir a quantidade de quartos na variavel bedroom
    plusBedroom: function () {
      this.bedroom += 1;
    },
    minusBedroom: function () {
      if (this.bedroom == 0) {
        this.bedroom = 0;
      }
      else {
        this.bedroom -= 1;
      }
    },

    //metodos para acrescentar e diminuir a quantidade de suites na variavel suite
    plusSuite: function () {
      this.suite += 1;
    },
    minusSuite: function () {
      if (this.suite == 0) {
        this.suite = 0;
      }
      else {
        this.suite -= 1;
      }
    },

    //metodos para acrescentar e diminuir a quantidade de banheiros na variavel bathroom
    plusBathroom: function () {
      this.bathroom += 1;
    },
    minusBathroom: function () {
      if (this.bathroom == 0) {
        this.bathroom = 0;
      }
      else {
        this.bathroom -= 1;
      }
    },

    //metodos para acrescentar e diminuir a quantidade de Vagas na variavel space
    plusSpace: function () {
      this.space += 1;
    },
    minusSpace: function () {
      if (this.space == 0) {
        this.space = 0;
      }
      else {
        this.space -= 1;
      }
    },
    validateFirstStep () {
      this.$v.immobile_type.$touch()
      this.$v.price.$touch()
      this.$v.ground_area.$touch()
      this.$v.ground_area_util.$touch()
      this.$v.title.$touch()
      this.$v.description.$touch()
      if (this.$v.immobile_type.$invalid ||
        this.$v.price.$invalid ||
        this.$v.ground_area.$invalid ||
        this.$v.ground_area_util.$invalid ||
        this.$v.title.$invalid ||
        this.$v.description.$invalid
      ) {
        //exibe os erros no HTML
      } else {
        //tudo certo pode passar para o 2º passo
        return true;
      }
    },
    validateSecondStep () {
      if (this.myFiles.length < 5) {
        this.erro_photo = true;
      } else {
        //tudo certo pode passar para o 2º passo
        this.$refs.pond.getFiles().forEach(file => {
          this.images.push(file.file)
        });
        return true;
      }
    },
    //function send data to API on form completed
    onComplete: async function () {
      if (this.messageCep != null || this.cep >= 8) {
        //Esse codigo vai transformar o cep em coordenadas geografica de latitude e longitude
        //usando a api mapbox para obter coordenadas geográficas do endereço preenchido pelo cliente no formulário de cadastro
        let urlDoMapbox = encodeURI('https://api.mapbox.com/geocoding/v5/mapbox.places/' + this.data.logradouro.trim() + ',' + this.numberAddress + ',' + this.data.localidade + ',' + this.data.uf + ',' + this.data.cep + ',' + 'Brazil.json');
        let config = {
          headers: { 'content-type': 'application/json' },
          params: {
            access_token: 'pk.eyJ1IjoibmV3dG9ucmVwIiwiYSI6ImNram9rZW95dzE0b3cyeW4wb2l5MmJ4Y3oifQ.DwInb5Ob321M6OxDgi8aLQ',
            cachebuster: '1610129327102',
            autocomplete: true
          },
        }

        //obtem as coordenadas geográficas
        const coordenadas = await axios.get(urlDoMapbox, config);
        console.log('dados retornados ', coordenadas);


        //seta as coordenadas
        // this.latitude = coordenadas.data.features[0].geometry.coordinates[0];
        // this.longitude = coordenadas.data.features[0].geometry.coordinates[1];
        // this.latitude = coordenadas.data.features[0].geometry.coordinates[1];
        // this.longitude = coordenadas.data.features[0].geometry.coordinates[0];


        // console.log(this.latitude);
        // console.log(this.longitude);

        this.user = this.user.user

        //envia os dados
        var dados = new FormData()

        //passando o nome
        dados.append('type_id', this.immobile_type)
        // dados.append('type', this.selected.immobile_type)
        dados.append('state_immobile', this.state_immobile)
        dados.append('availability', this.want_sell)
        dados.append('country', 'Brasil')
        dados.append('state', this.data.uf)
        dados.append('city', this.data.localidade)
        dados.append('neighborhood', this.data.bairro)
        dados.append('public_place_type', this.data.logradouro)
        dados.append('public_place', this.data.logradouro)
        dados.append('number', this.numberAddress)
        dados.append('cep', this.data.cep)
        dados.append('complement', this.data.complemento)
        dados.append('reference', '')
        dados.append('ground_area_util', this.ground_area_util)
        dados.append('ground_area', this.ground_area)
        dados.append('number_suite', this.suite)
        dados.append('number_room', this.bedroom)
        dados.append('number_bathroom', this.bathroom)
        dados.append('number_space', this.space)
        dados.append('description', this.description)
        dados.append('title', this.title)
        //trata o valor
        // this.price = String(displayValue).replace(/,/g, "");
        dados.append('price', this.price)
        dados.append('value_iptu', this.iptu)
        dados.append('value_condominium', this.condominio)
        dados.append('note', '')
        dados.append('latitude', this.latitude)
        dados.append('longitude', this.longitude)
        this.images.forEach(img => {
          dados.append('images[]', img);
        });
        dados.append('user_id', this.user.id)

        //exibe o loading
        this.isLoading = true;
        
        //envia os dados do form para a API para salvar o usuário
        axios.post(
          this.endpoint,
          dados,
          {
            headers: {
              'Content-type': 'multipart/form-data',
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
          }
        ).then((response) => {
          console.log(response);
          this.isLoading = false;
          swal('Sucesso', 'Parabéns! Seu imóvel foi anunciado com sucesso.', 'success').then(() => {
            this.$router.push('/');
          })
        }).catch((error) => {
          this.isLoading = false;
          console.log(error);
          swal('Parece que algo deu errado', error.message, 'error').then(() => {
          })
        })
      } else {
        this.requiredCep = true;
      }
    },
    exibeLoading () {
      let loader = this.$loading.show({
        loader: 'dots'
      });
      // simulate AJAX
      setTimeout(() => {
        loader.hide()
      }, 2000)
    },

    onCancel () {
      console.log('User cancelled the loader.')
    },


    /**
     * novas coordenadas após movimentar o pin no mapa da localização do imovel
     */
    ajustaCoordenadas(coordenadas){
      console.log('latitude', coordenadas.lat());
      this.latitude = coordenadas.lat();
      this.longitude = coordenadas.lng();
    }

  }
}
</script>
<style scoped>
/* Importando o bootstrap 4 */
@import "../../assets/styles/bootstrap4/bootstrap.min.css";

/* Importando o estilo da pagina*/
@import "../../assets/styles/simple_sidebar.css";

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lobster&family=Sacramento&display=swap");

.logomarca {
  color: #000;
}

label {
  font-family: "Montserrat", sans-serif;
}

.form-control::-webkit-input-placeholder {
  color: #4c4;
}
input:-moz-placeholder {
  color: #4c4;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: red;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: red;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: red;
}
.esquerda {
  text-align: left;
}
.form-row {
  margin-bottom: 10px;
}

/* Estilo para o select do tipo de imovel */
#immobile-type {
  border-style: solid;
  border-width: 0px 0px 1px 0px; /* top, right, bottom, left */
  outline: none;
}

#immobile-type:focus {
  box-shadow: none;
  border-bottom: black;
}

select {
  font-family: "Montserrat", sans-serif;
}

/* Estilos para bottons da tela */
button:focus {
  box-shadow: none;
}

/* Estilo para as tags com id subtitle */
#subtitle {
  font-family: "Roboto", "sans-serif";
  font-size: 14pt;
  font-weight: bold;
  opacity: 80%;
}

/* estilo dos paragrafos */
p {
  font-family: "Montserrat", sans-serif;
}

h5 {
  font-weight: 600;
}

.filepond--drop-label {
  border-style: dotted;
  border-color: blue;
}

/* estilo do icone lampada */
#icon-light {
  font-size: 18pt;
}

/* estilo da bloco de informação*/
#blockquote-info {
  background-color: rgba(2, 137, 247, 0.185);
  border-left: 6px solid;
  border-color: rgba(2, 137, 247, 0.384);
  padding-left: 12px;
  padding-right: 5px;
  padding-top: 4px;
  font-size: 11pt;
  font-weight: bold;
  color: rgb(2, 137, 247);
}

/* estilo do small-info */
#small-info {
  font-weight: 300;
}

/* estilo do small-dicas */
#small-dicas {
  font-weight: 600;
}
</style>