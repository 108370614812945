<template>
  <div class="list-group">
        <a
          v-on:click="messagesUserSelected(usrChat)"
          v-for="(usrChat,index) in usuarios_da_conversa"
          :class="{active: userActiveStyle(usrChat)}"
          :userFirstCurrent="setFirstCurrentUser"
          :key="index" 
          class="list-group-item list-group-item-action flex-column"
          style="cursor:pointer"
        > 
          <!-- div se usuário logado for o responsável pelo imovel -->
          <div 
            v-if="(usrChat.id === userLogged.user.id)" 
            class="d-flex flex-row"
          >
            <div class="p-2">
              <img
                class="rounded-circle"
                :src="endpoint_images + usrChat.photograph"
                alt=""
                width="30" 
                height="30"
              >
            </div>
            <div class="d-flex flex-column p-1">
              <strong>{{ usrChat.name }}</strong>
              <span>{{ usrChat.city}} - {{ usrChat.state }}</span>
              <small>{{ usrChat.created_at}}</small>
            </div>
            <div v-if="notification" class="circle-notification"> </div>
          </div>
          
          <!-- div se usuário logado NÃO for o responsável pelo imovel -->
          <div 
            v-if="(usrChat.id !== userLogged.user.id)" 
            class="d-flex flex-row"
          >
            <div class="p-2">
              <img
                class="rounded-circle"
                :src="endpoint_images + usrChat.photograph"
                alt=""
                width="30" 
                height="30"
              >
            </div>
            <div class="d-flex flex-column p-1">
              <strong>{{ usrChat.name }}</strong>
              <span>{{ usrChat.city}} - {{ usrChat.state }}</span>
              <small>{{ usrChat.created_at}}</small>
            </div>
            <div class="p-1">
              <svg class="bd-placeholder-img rounded-circle" width="10" height="10" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false" role="img" aria-label="Completely round image: 10x10"><title></title><rect width="100%" height="100%" fill="#008000"></rect><text x="50%" y="50%" fill="#dee2e6" dy=".3em"></text></svg>
            </div>
          </div>
        </a>        
  </div>
</template>

<script>
//vuex
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'Conversas',
  props: ['usuarios_da_conversa'],
  data () {
    return {
      isloading: false,
      endpoint_images: process.env.VUE_APP_IMAGES,
      users: [],
      userActive: null,
      primeiroDaLista: {},
      notificationUser: null,
    }

  },
  async mounted () {
    let varLoggedUserId = this.userLogged.user.id
    let varFirstUserId = await this.listUsersChat
    this.primeiroDaLista = varFirstUserId;
    this.$store.dispatch('setListConversationsChat', {
        immobileId: this.$route.params.immobile_id,
        announcementId: this.$route.params.announcement_id,
        userSelectedId: varFirstUserId[0].id, 
        loggedUserId: varLoggedUserId
      })
    this.$store.dispatch('setCurrentUser', varFirstUserId[0].id).then( () => {
      // console.log('execta após o retorno da promise de setCurrentUser no store', result)
      this.isloading = true
    })
    // console.log('lista de usuarios do chat', this.listUsersChat)
  },
  computed: {
    ...mapGetters(['getUser', 'isAuthenticated']),
    ...mapState({
        userLogged: state => state.user, 
        listUsersChat: state => state.listUsersChat,
        currentUser: state => state.currentUser,
        notification: state => state.notification
      }),
  },
  methods: {
    setFirstCurrentUser () {
      this.$store.dispatch('setCurrentUser', this.listUsersChat[0])
      return this.getCurrentUser;
    },
    userActiveStyle (user) {
      //verifica se anunciante logado e se não
      if(user.id === this.userLogged.user.id && this.currentUser === null){
        //anunciante logado
        //verifica e seleciona o primeiro da lista de usuários do chat
        if(this.listUsersChat[0].id){
          //chama as mensagens
          this.loadMessages(user)
          return true;
        }
      }
      if(user.id !== this.userLogged.user.id){
        //cliente logado
        return false;
      }
      return false;
    },
    messagesUserSelected (user) {
      let varLoggedUserId = this.userLogged.user.id
      //seta as conversas do cliente com o anunciante do imóvel
      this.$store.dispatch('setListConversationsChat', {
        immobileId: this.$route.params.immobile_id,
        announcementId: this.$route.params.announcement_id,
        userId: user.id, 
        loggedUserId: varLoggedUserId
      })
      this.$store.dispatch('setCurrentUser', user.id)
    }
  }

}
</script>

<style lang="scss" scoped>
  .circle-notification {
     background: #BADA55;
     border-radius: 50%;
     width: 30px;
     height: 30px;
  }
</style>