<template>
  <div>
    <!-- Loading -->
    <loading :active.sync="isLoading" 
    :can-cancel="true" 
    :on-cancel="onCancel"
    :is-full-page="fullPage"></loading>
    <!-- fim Loading -->
    
    <!-- ## Cards com imóveis ## -->
    <div class="card">
        <div class="card-horizontal">
            <div class="img-square-wrapper">
                <img 
                    :src="endpoint_images + immobilebox.imagem" 
                    alt="Card image cap"
                    class="img-thumbnail" 
                    style="width:200px; height:200px;"
                >
            </div>
            <div class="card-body">
                <h3 class="card-title"><strong>{{immobilebox.titulo}}</strong></h3>
                <p class="card-text">
                    <span class="card-text" v-if="immobilebox.descricao.length<50">{{immobilebox.descricao}}</span>
                    <span class="card-text" v-if="immobilebox.descricao.length>=50">{{immobilebox.descricao.substring(0,50)+'...'}}</span>
                    <br>
                    {{immobilebox.endereco}}
                    <br>
                    <small class="">atualizado em: {{immobilebox.atualizado_em}}</small>
                    <br>    
                    <span class="card-text text-success">
                        <h5><strong>{{immobilebox.preco | currency('R$', 2, {thousandsSeparator: '.', decimalSeparator: ',', spaceBetweenAmountAndSymbol: true })}}</strong></h5>
                        <!-- <h5><strong>{{immobilebox.preco | currency('R$ ')}}</strong></h5> -->
                    </span>
                </p>
                <!-- <p class="card-text">
                    {{immobilebox.endereco}}
                    <br>
                    <small class="">cadastrado em: {{immobilebox.cadastrado_em}}</small>
                    <br>
                    <small class="">atualizado em: {{immobilebox.atualizado_em}}</small>
                </p> -->
                <!-- <p class="card-text text-success">
                    <strong>{{immobilebox.preco | currency('R$', 2, {thousandsSeparator: '.', decimalSeparator: ',', spaceBetweenAmountAndSymbol: true })}}</strong>
                </p> -->
                <p class="card-text">
                    <router-link 
                        :to="{ name: 'editimmobile', params: { id: immobilebox.anuncio_id }}"
                        tag="a"
                        class="btn btn-sm btn-info"
                    >
                        <i class="fa fa-edit"></i> Editar
                    </router-link>
                    <!-- <router-link 
                        :to="{ name: 'editimmobile', params: { id: immobilebox.anuncio_id }}"
                        tag="a"
                        class="btn btn-sm btn-success ml-3"
                    >
                        <i class="fa fa-success"></i> Marcar como Vendido
                    </router-link> -->
                    <!-- botão INATIVAR anúncio-->
                    <button
                      v-if="status === 'ATIVO'"
                      class="btn btn-outline-danger btn-sm ml-3"
                      @click="suspendOrActiveAnnouncement(immobilebox.anuncio_id, 'INATIVO')"
                    ><span class="fas fa-trash-alt mr-2"></span>Suspender Anúncio</button>
                    <!-- botão ATIVAR anúncio -->
                    <button
                      v-if="status === 'INATIVO'"
                      class="btn btn-outline-success btn-sm ml-3"
                      @click="suspendOrActiveAnnouncement(immobilebox.anuncio_id, 'ATIVO')"
                    ><span class="fas fa-check-circle mr-2"></span>Ativar Anúncio</button>
                    <router-link 
                        :to="{ name: 'editimmobile', params: { id: immobilebox.anuncio_id }}"
                        tag="a"
                        class="btn btn-sm btn-link ml-2"
                    >
                        <i class="fa fa-comment"></i> Comentários
                    </router-link>
                </p>
            </div>
        </div>
        <!-- <div class="card-footer">
            <small class="text-muted">Last updated 3 mins ago</small>
        </div> -->
    </div>
  </div>       
</template>

<script>
// ES6 Modules or TypeScript
import Swal from 'sweetalert2'
//importando axios
import axios from 'axios' 
// Import do component de loading
import Loading from 'vue-loading-overlay';
import {mapState} from 'vuex'
//filtro preços
import Vue2Filters from 'vue2-filters'

export default {
    components: {
        Loading,
    },
    mixins: [Vue2Filters.mixin],
    props: {
        immobilebox: {}
    },
    computed: {
        ...mapState({
            user: state => state.user,
        }),
        teste () {
            console.log('immobileBox', this.immobileBox);
            return 1;
        }
    },
    data(){
        return {
            isLoading: false,
            fullPage: false,
            favorite: false,
            endpointdev_user: process.env.VUE_APP_USER,
            urldev: process.env.VUE_APP_FAVORITE,
            endpoint_images: process.env.VUE_APP_IMAGES,
            endpoint_suspend_active_announcement: process.env.VUE_APP_ANNOUNCEMENT_SUSPEND_OR_ACTIVE,
            user_id: null,
            status: this.immobilebox.status,
            money: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: '',
                precision: 2,
                masked: false
            },
        }
    },
    methods:{
        async mudarEstilo (){
            this.isLoading = true;

            if(localStorage.getItem('token') == null){
                /*swal('Ops', 'Você deve fazer o login primeiro.', 'info').then( () => {
                    this.$router.push('/login');
                })*/
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                }).then( (result) => {
                    console.log('result',result)
                    this.$router.push('/login');
                })
            }else{
                /* pegando o id do usuario logado */
                this.user_id = this.user.user.id;
                
                //envia os dados
                var dados = new FormData()
                dados.append('user_id', this.user_id)
                dados.append('immobile_id', this.immobileBox.id)
                dados.append('favorited', true)   
                
                /* favoritando o imovel de acordo com id do usuario logado */
                await axios.post(
                    this.urldev + this.user_id,
                    dados, 
                    { headers: {
                    'Content-type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
                });

                this.favorite = !this.favorite;
                this.isLoading = false;
                
            }
            
        },
        suspendOrActiveAnnouncement (id, acao) {
            //se ação for igual a INATIVAR, então pergunta se tem certeza que deseja inativar
            if(acao === 'INATIVO'){
                // pergunta se deseja realmente suspender o anúncio
                Swal.fire({
                        title: 'Está certo disso?',
                        text: 'Tem certeza que deseja desativar o anúncio?',
                        icon: 'warning',
                        confirmButtonText: 'Sim'
                    }).then( ()=>{
                        this.executaAcao(id, acao)
                    })
            }
            if(acao === 'ATIVO'){
                // pergunta se deseja realmente suspender o anúncio
                Swal.fire({
                        title: 'Está certo disso?',
                        text: 'Tem certeza que deseja ativar o anúncio?',
                        icon: 'warning',
                        confirmButtonText: 'Sim'
                    }).then( ()=>{
                        this.executaAcao(id, acao)
                    })
            }
    },
    async executaAcao(id, acao) {
        const self = this;
        //obj formdata para guardar os dados
        let dados = new FormData()
        //passando o id do anúncio a ser bloqueado ou ativado
        dados.append('id', id)
        //passando a ação a ser feita, se ativar ou inativar o anúncio
        dados.append('acao', acao)
        // exibe o loading
        this.isLoading = true;
        let token = localStorage.getItem('token')
        //envia os dados do form para a API para suspender o anúncio
        await axios.post(
            this.endpoint_suspend_active_announcement, 
            dados,
            { headers: {
                'Content-type': 'multipart/form-data',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
                } 
            }).then( (response) => {
                console.log('resposta =',response.data)
                console.log('acao', acao)
                self.isLoading = false;
                Swal.fire({
                    title: 'Sucesso',
                    text: response.data.message,
                    icon: 'success',
                    confirmButtonText: 'OK'
                }).then( ()=>{
                   this.status = response.data.status
                })
        }).catch( (error) => {
            Swal.fire({
                    title: 'Erro',
                    text: 'houve um erro: '+error.message,
                    icon: 'error',
                    confirmButtonText: 'OK'
                }).then( ()=>{
                    
                })
        })
    },
    onCancel () {
        console.log('User cancelled the loader.')
    },
    },
    
}
</script>

<style scoped>

/* Importando o bootstrap 4 */
@import '../assets/styles/bootstrap4/bootstrap.min.css';

/* Importando o estilo da pagina*/
@import '../assets/styles/simple_sidebar.css';

.card-horizontal {
    display: flex;
    flex: 1 1 auto;
}

/*Estilo para editar o tamanho da imagem do usuario que postou o imovel*/
#img-profile{
    position: relative;
    width: 80%;
    height: 80%;
    border-radius: 90%;
}

/* Estilo para editar o tomanho do icone de area */
#icon-area{
    position: relative;
    width: 8%;
    height: 90%;    
}

/*Estilo para editar imagem*/
#img-immobile-text{
    position: absolute;
    top: 0%;
    margin-left: 0%;
    padding: 1%;
    color: white;
    background: green;
    border-radius: 10%;
}

#img{
height: 10%;
widows: 10%;
}

/* Estilo do botao de curtir */
#button-broker{
    border: none;
    background: none;
}

#button-broker:focus{
    outline-style: none;
}

.unlike{
    color: #00000030;
    font-size: 2em;
}
.like{
    color: #f44336;
    font-size: 2em;
}
</style>