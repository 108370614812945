<template>
  <div>
    <site-header />
    <div
      class="d-flex"
      id="wrapper"
    >

      <!-- Sidebar -->
      <Sidebar></Sidebar>
      <loading
        :active.sync="isLoading"
        :can-cancel="true"
        :on-cancel="onCancel"
        :is-full-page="fullPage"
      />

      <div class="container-fluid">
        <!-- <ListBroker users="{{users}}"/> -->
        <table
          v-if="listRealtorExists"
          class="table table-borderless mt-5"
        >
          <tbody>
            <tr
              v-for="user in users"
              :key="user.id"
              class="row"
            >
              <td class="col-md-1 pl-4 align-middle">
                <img
                  v-if="user.profile.photograph"
                  width="32"
                  height="32"
                  :src="endpoint_images+user.profile.photograph"
                  :alt="1"
                >
                <img
                  v-else
                  width="32"
                  height="32"
                  src="https://png.pngtree.com/png-vector/20190710/ourmid/pngtree-user-vector-avatar-png-image_1541962.jpg"
                  :alt="1"
                >
              </td>
              <td class="col-md-4 align-middle">{{user.name}}<span class="font-italic blockquote-footer">{{user.email}}</span></td>
              <td class="col-md-3 align-middle">{{user.profile.telephone}}</td>
              <td
                v-if="user.status === 'ATIVO'"
                class="col-md-2 align-middle"
              >
                <p class="text-success" id="situation-active">Ativo</p>
              </td>
              <td
                v-else-if="user.status === 'EM VERIFICACAO'"
                class="col-md-2 align-middle"
              >
                <p class="text-warning" id="situation-inactive">Em Verificação</p>
              </td>
              <td
                v-else
                class="col-md-2 align-middle"
              >
                <p class="text-danger" id="situation-inactive">Suspenso</p>
              </td>
              <td class="col-md-2">
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle border border-primary text-primary"
                    href="#"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Ações
                  </a>
                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <router-link
                      :to="`/show-profile-realtor/${user.id}`"
                      tag="a"
                      class="dropdown-item text-primary"
                      href="#"
                      :data-id="user.id"
                    ><span class="fas fa-user pr-2"></span>Visualizar perfil</router-link>
                    <a
                      v-if="user.status == 'INATIVO'"
                      class="dropdown-item text-warning"
                      href="#"
                    ><span class="fas fa-times-circle pr-2"></span>Desativar corretor</a>
                    <button
                      v-if="user.status != 'ATIVO'"
                      class="btn btn-link dropdown-item text-success"
                      @click="activeRealtor(user.id).stop.prevent"
                    ><span class="fas fa-check-circle pr-2"></span>Ativar corretor</button>
                    <button
                      v-if="user.status != 'SUSPENSO'"
                      class="btn btn-link dropdown-item text-danger"
                      @click="suspendRealtor(user.id).stop.prevent"
                    ><span class="fas fa-trash-alt pr-2"></span>Suspender corretor</button>
                  </div>
                </div>
              </td>

              <hr
                width="95%"
                align="center"
              >
            </tr>
          </tbody>
        </table>
        <div v-else>
          <h5 class="text-center mt-5">Não existem corretores a exibir</h5>
        </div>
      </div>
    </div>
    <!-- /#wrapper -->
  </div>
</template>

<script>
// import ListBroker from '../../components/ListBroker.vue'
import SiteHeader from '../../components/SiteHeader.vue';
import Sidebar from '../../components/Sidebar.vue';
//sweet alert
import swal from 'sweetalert'
//importa o axios e o http
// import { http } from '@/http';
// Import do component de loading
import Loading from 'vue-loading-overlay'
import axios from 'axios'

export default {
  name: 'ListRealtors',
  components: {
    // ListBroker,
    SiteHeader,
    Loading,
    Sidebar,
  },
  props: {
  },
  data () {
    return {
      users: [],
      endpoint: process.env.VUE_APP_REALTORS_LIST,
      endpoint_images: process.env.VUE_APP_IMAGES,
      suspend_endpoint: process.env.VUE_APP_SUSPEND_REALTOR,
      active_endpoint: process.env.VUE_APP_ACTIVE_REALTOR,
      isLoading: false,
      fullPage: false,
      listRealtorExists: false,
      token: localStorage.getItem('token')
    }
  },
  created ()  {
    try {
        this.isLoading = true;
        
        /* pegando o id do usuario logado */
        axios.get(this.endpoint, {
            headers: {
              Accepted: 'application/x-www-form-urlencoded',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${this.token}`
            }
        }).then((response) => {
          if (response) {
            this.isLoading = false
            let retorno = response.data
            retorno.forEach(element => {
              // console.log('role', String(element.role))
              if (element.role == "[\"ROLE_REALTOR\"]") {
                this.users.push(
                  {
                    id: element.id,
                    name: element.name,
                    email: element.email,
                    status: element.status,
                    role: element.role,
                    profile: {
                      telephone: element.profile.telephone,
                      photograph: element.profile.photograph
                    }
                  }
                )
              }
            });
            //se não houver corretores, listRealtorExists recebe false
            (this.users.length > 0) ? this.listRealtorExists = true : this.listRealtorExists = false;
          }

        }).catch((error) => {
          console.log('error', error)
          this.isLoading = false
          swal('Erro', 'Não foi possível localizar os dados do usuário', 'error')
            .then(() => {

            })
        })
      } catch (error) {
        console.log('error', error);
      }
  },
  methods: {
    suspendRealtor (id) {
      const self = this;
      //envia os dados
      var dados = new FormData()
      //passando o id do corretor a ser bloqueado
      dados.append('id', id)
      //exibe o loading
      this.isLoading = true;
      //envia os dados do form para a API para salvar o usuário
      axios.post(
          this.suspend_endpoint, 
          dados,
          { headers: {
            'Content-type': 'multipart/form-data',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + this.token
            } 
          }).then( (response) => {
              console.log('resposta =',response.data)
              self.isLoading = false;
              swal('Sucesso', 'Corretor Bloqueado com sucesso. \n Um aviso foi enviado ao email do corretor para que entre em contato com a administração do clube.', 'success').then( () => {
                  window.location.reload()
                  return false            
              })
          // }
      }).catch( (error) => {
          swal('Erro', error.message, 'error').then( () => {

          })
      })
    },
    activeRealtor(id) {
      const self = this;
      //envia os dados
      var dados = new FormData()
      //passando o id do corretor a ser bloqueado
      dados.append('id', id)
      //exibe o loading
      this.isLoading = true;
      //envia os dados do form para a API para salvar o usuário
      axios.post(
          this.active_endpoint, 
          dados,
          { headers: {
            'Content-type': 'multipart/form-data',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + this.token
            } 
          }).then( (response) => {
              console.log('resposta =',response.data)
              self.isLoading = false;
              swal('Sucesso', 'Corretor Bloqueado com sucesso. \n Um aviso foi enviado ao email do corretor para que entre em contato com a administração do clube.', 'success').then( () => {
                  window.location.reload()
                  return false            
              })
          // }
      }).catch( (error) => {
          swal('Erro', error.message, 'error').then( () => {

          })
      })
    },
    onCancel () {
      console.log('cancelou o loading');
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>