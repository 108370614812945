<template>
<div>
    <site-header />
    
    <div class="row d-flex justify-content-center" style="background-color:#5881eb">
        <loading :active.sync="isLoading" 
            :can-cancel="true" 
            :on-cancel="onCancel"
            :is-full-page="fullPage"></loading>

        <div class="col-md-12" style="background-color:#fff">
            <div class="mx-auto">
                <h1 class="logomarca">Clube de Imóveis </h1>
                <h3 class="realtorsubtitle mt-2 mb-3">Cadastro de Proprietário de Imóvel</h3>
            </div>
            <form-wizard @on-complete="onComplete" title="" subtitle="" shape="circle" color="#28a745" error-color="#ff4949">
                <!-- 1º Passo -->
                <tab-content title="Dados de acesso" icon="" :before-change="validateFirstStep">
                    <div class="form-row mb-4">
                        <div class="col">
                            <!-- email -->
                            <label class="label esquerda" style="text-align:left">Email</label>
                            <input v-model.trim="email" :class="{ 'is-invalid': $v.email.$dirty && $v.email.$invalid}" @input="$v.email.$touch()" class="form-control" name="email"  type="email"   placeholder="">
                            <small class="text-danger" v-if="$v.email.$dirty && $v.email.$invalid">O email é obrigatório e deve conter no mínimo 5 caracteres.</small>
                            
                        </div>
                    </div>
                    
                    <div class="form-row">
                        <div class="col">
                            <!-- senha -->
                            <label class="label esquerda">Senha</label>
                            <input v-model="password" :class="{ 'is-invalid': $v.password.$dirty && $v.password.$invalid}" class="form-control" name="password"  type="password"   placeholder="">
                            <small class="text-danger" v-if="$v.password.$dirty && $v.password.$invalid">A senha é obrigatória</small>
                        </div>
                        <div class="col">
                            <!-- confirme senha -->
                            <label class="label esquerda">Confirmar Senha</label>
                            <input v-model="confirmpassword" :class="{ 'is-invalid': $v.confirmpassword.$dirty && $v.confirmpassword.$invalid}" class="form-control" name="confirmpassword"  type="password"   placeholder="">
                            <small class="text-danger" v-if="$v.confirmpassword.$dirty && $v.confirmpassword.$invalid">As senhas devem ser iguais</small>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col" style="margin-left:5%">
                            <!-- Aceita termos de serviço -->
                            <input v-model="termos_de_uso" :class="{ 'is-invalid': $v.termos_de_uso.$dirty && $v.termos_de_uso.$invalid}" class="form-check-input" style="margin-top:1%" name="termos_de_uso"  type="checkbox"   placeholder="">
                            <label class="label esquerda">
                                <strong>Declaro que li e aceito os <button class="btn btn-link">Termos de Uso e Privacidade</button> </strong></label>
                            <small class="text-danger" v-if="$v.termos_de_uso.$dirty && $v.termos_de_uso.$invalid">É necessário aceitar os termos de uso e privacidade para poder se cadastrar</small>
                        </div>
                    </div>
                </tab-content>

                <!-- 2º Passo -->
                <tab-content title="Informações Pessoais" icon="" :before-change="validateSecondStep">
                    <div class="form-row">
                        <div class="col">
                            <!-- nome completo -->
                            <label class="label esquerda">Nome Completo</label>
                            <input v-model.trim="name" :class="{ 'is-invalid': $v.name.$dirty && $v.name.$invalid}" @input="$v.name.$touch()" class="form-control" name="fullname" type="text" placeholder="">
                            <small class="text-danger" v-if="$v.name.$dirty && $v.name.$invalid">O Nome é obrigatório e deve conter no mínimo 5 caracteres.</small>
                        </div>
                        <div class="col">
                            <!-- CPF -->
                            <label class="label esquerda">CPF</label>
                            <input v-model="cpf" :class="{ 'is-invalid': $v.cpf.$dirty && $v.cpf.$invalid}" @input="$v.cpf.$touch()" v-mask="['###.###.###-##']"  type="text" name="cpf" class="form-control input-lg" placeholder="" >
                            <small class="text-danger" v-if="$v.cpf.$dirty && $v.cpf.$invalid">O CPF é obrigatório.</small>
                        </div>
                        <!-- <div class="col">
                            <label class="label esquerda">Nº CRECI</label>
                            <input v-model="creci" :class="{ 'is-invalid': $v.creci.$dirty && $v.creci.$invalid}" @input="$v.creci.$touch()" type="number" name="creci" class="form-control input-lg" placeholder="" >
                            <small class="text-danger" v-if="$v.creci.$dirty && $v.creci.$invalid">O creci é obrigatório.</small>
                        </div> -->
                    </div>
                    <div class="form-row">
                        <!-- Celular -->
                    <div class="col">
                            <label class="label esquerda">Celular</label>
                            <input v-model="telephone" :class="{ 'is-invalid': $v.telephone.$dirty && $v.telephone.$invalid}" @input="$v.telephone.$touch()" v-mask="['(##) ####-####', '(##) #####-####']" class="form-control input-lg" placeholder="(xx) xxxxx-xxxx" type="text" name="telephone"  >
                            <small class="text-danger" v-if="$v.telephone.$dirty && $v.telephone.$invalid">O celuar é obrigatório.</small>
                    </div>
                        <!-- cep -->
                    <div class="col">
                            <label class="label esquerda">CEP</label>
                            <input v-model="cep" v-on:blur="enderecoPeloCep($event)" :class="{ 'is-invalid': $v.cep.$dirty && $v.cep.$invalid}" @input="$v.cep.$touch()" class="form-control input-lg" type="text" name="cep" >
                            <small class="text-danger" v-if="$v.cep.$dirty && $v.cep.$invalid">O CEP é obrigatório.</small>
                    </div>
                    </div>
                    <div class="form-row">
                        <!-- Tipo de Logradouro -->
                        <div class="col-md-2">
                            <label class="label esquerda">Tipo de Logradouro</label>
                            <treeselect v-model="public_place_type" :multiple="false" :options="options" :placeholder="'Selecione...'" />
                            <small class="text-danger" v-if="$v.public_place_type.$dirty && $v.public_place_type.$invalid">O tipo de logradouro é obrigatório.</small>
                        </div>
                        <!-- Logradouro -->
                        <div class="col">
                            <label class="label esquerda">Logradouro</label>
                            <input v-model="public_place" :class="{ 'is-invalid': $v.public_place.$dirty && $v.public_place.$invalid}" @input="$v.public_place.$touch()" class="form-control input-lg" type="text" name="public_place" >    
                            <small class="text-danger" v-if="$v.public_place.$dirty && $v.public_place.$invalid">O endereço é obrigatório.</small>
                        </div>
                        <div class="col-md-1">
                            <!-- número - não é obrigatório -->
                            <label class="label esquerda">Nº</label>
                            <input v-model="number" class="form-control input-lg" type="text" name="number" >
                        </div>
                        <div class="col">
                            <!-- complemento - não é obrigatório -->
                            <label class="label esquerda">Complemento</label>
                            <input v-model="complement" class="form-control input-lg" type="text" name="number" >
                        </div>
                        <div class="col">
                            <!-- ponto de referência - não é obrigatório -->
                            <label class="label esquerda">Ponto de referência</label>
                            <input v-model="refence" class="form-control input-lg" type="text" name="number" >
                        </div>
                        
                    </div>
                    <div class="form-row">
                        <div class="col">
                            <!-- bairro -->
                            <label class="label esquerda">Bairro</label>
                            <input v-model="neighborhood" :class="{ 'is-invalid': $v.neighborhood.$dirty && $v.neighborhood.$invalid}" @input="$v.neighborhood.$touch()" class="form-control input-lg" type="text" name="neighborhood">
                            <small class="text-danger" v-if="$v.neighborhood.$dirty && $v.neighborhood.$invalid">O bairro é obrigatório.</small>
                        </div>
                        <div class="col-md-1">
                            <!-- UF -->
                            <label class="label esquerda">UF</label>
                            <select v-model="state" :class="{'is-invalid': $v.state.$dirty && $v.state.$invalid}" @change="getCidades($event)" class="form-control input-lg" name="state">
                                <option value="">..</option>
                                <option v-for="state in estadoscidades.states" :key="state.state_id" :value="state.state_id">{{state.state_name}}</option>
                            </select>
                            <small class="text-danger" v-if="$v.state.$dirty && $v.state.$invalid">O Estado é obrigatório.</small>
                        </div>
                        <div class="col">
                            <!-- cidade -->
                            <label class="label esquerda">Cidade</label>
                            <input v-model="city" :class="{ 'is-invalid': $v.city.$dirty && $v.city.$invalid}" @input="$v.city.$touch()" class="form-control input-lg" type="text" name="city">
                            <!-- <select name="city" v-model="city" :class="{ 'is-invalid': $v.city.$dirty && $v.city.$invalid}" class="form-control">
                                <option value="">...</option>
                                <option v-for="c in cidades" :key="c.id" :value="c.name">{{ c.name }}</option>
                            </select> -->
                            <!-- <input v-model="city" :class="{ 'is-invalid': $v.city.$dirty && $v.city.$invalid}" @input="$v.city.$touch()" class="form-control input-lg" type="text" name="city"> -->
                            <small class="text-danger" v-if="$v.city.$dirty && $v.city.$invalid">A cidade é obrigatória.</small>
                        </div>
                    </div>
                    
                </tab-content>
                <tab-content title="Documentos" icon="">
                    <!-- <h5 class="realtorsubtitle">Anexar documentos para validação</h5> -->

                    <div class="form-row">
                        <div class="col">
                            <label for="exampleFormControlFile1">Foto Perfil</label>
                            <input type="file" @change="getFileFotoPerfil" class="form-control-file" required ref="fileFotoPerfil">
                            <div class="border rounded-sm text-center p-3">
                                <img v-if="srcFotoPerfil" :src="srcFotoPerfil" width="140" height="140" />
                                <img v-else src="//placehold.it/140?text=IMAGEM" class="img-fluid" id="fotoPerfil" />
                            </div>
                        </div>
                        <div class="col">
                            <label for="exampleFormControlFile1">Anexar documento pessoal com foto (RG, CNH ou similar)</label>
                            <input type="file" @change="getFileRG" class="form-control-file" required ref="fileRG">
                            <div class="border rounded-sm text-center p-3">
                                <img v-if="srcFotoRG" :src="srcFotoRG" width="140" height="140" />
                                <img v-else src="//placehold.it/140?text=IMAGE" class="img-fluid" id="fotoRG" />
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <!-- <div class="col">
                            <label for="exampleFormControlFile1">Anexar Documento do Imóvel</label>
                            <input type="file" @change="getFileImovel" class="form-control-file" required ref="fileImovel">
                            <div class="border rounded-sm text-center p-3">
                                <img v-if="srcFotoImovel" :src="srcFotoImovel" width="140" height="140" />
                                <img v-else src="//placehold.it/140?text=IMAGE" class="img-fluid" id="fotoImovel" />
                            </div>
                        </div> -->
                        <div class="col">
                            <label for="exampleFormControlFile1">Anexar comprovante de endereço (recente últimos 3 meses)</label>
                            <input type="file" @change="getFileEnd" class="form-control-file" required ref="fileEnd">
                            <div class="border rounded-sm text-center p-3">
                                <img v-if="srcFotoEnd" :src="srcFotoEnd" width="140" height="140" />
                                <img v-else src="//placehold.it/140?text=IMAGE" class="img-fluid" id="fotoEnd" />
                            </div>
                        </div>
                    </div>
                    
                </tab-content>

                <button type="button" slot="prev" class="btn btn-secondary">Anterior</button>
                <button v-bind:disabled="!termos_de_uso" type="button" slot="next" class="btn btn-primary">Próxima</button>
                <button type="button" slot="finish" class="btn btn-success">Enviar</button>
            </form-wizard>
        </div>
    </div>
</div>
</template>

<script>
    // import Vue from 'vue';
    import SiteHeader from '../../components/SiteHeader.vue';
    import {FormWizard, TabContent} from 'vue-form-wizard'
    import 'vue-form-wizard/dist/vue-form-wizard.min.css'
    import {mask} from 'vue-the-mask'
    import axios from 'axios'
    import { required, minLength, email, sameAs } from 'vuelidate/lib/validators'
    // import the component
    import Treeselect from '@riophae/vue-treeselect'
    //constantes
    import {constantes} from '../../consts'
    // import the styles
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    // Import do component de loading
    import Loading from 'vue-loading-overlay';
    // Import do stylesheet do loading
    import 'vue-loading-overlay/dist/vue-loading.css';
    //cidade e estados do brasil
    import json from './../../assets/json/estados-cidades2.json'
    //sweet alert
    import swal from 'sweetalert'
    
    
    export default {
        directives: {mask},
        name: 'SellerStepByStep',
        components: {
            SiteHeader,
            FormWizard,
            TabContent,
            Treeselect,
            Loading
        },
        data() {
            return {
                    estadoscidades: json,
                    cidades: json.cities,
                    // dados pessoais
                    name: '',
                    email: '',
                    password: '',
                    confirmpassword:'',
                    termos_de_uso: '',
                    cpf: '',
                    // creci: '',
                    telephone:'',
                    cep:'',
                    public_place_type: 'Rua',
                    public_place: '',
                    number: '',
                    neighborhood: '',
                    complement: '',
                    refence: '',
                    state: '',
                    city:'',
                    // fileImovel: '',
                    fileEnd: '',
                    fileRG: '',
                    fileFotoPerfil: '',
                    srcFotoImovel: null,
                    srcFotoRG: null,
                    srcFotoEnd: null,
                    srcFotoPerfil: null,
                    endpoint: process.env.VUE_APP_REGISTER_SELLER,
                    isLoading: false,
                    fullPage: true,
                    //options do vuetreeselect
                    options: constantes.tipo_de_logradouros,
                    ufs: constantes.estados

                }
            },
            validations: {
                name: {
                    required,
                    minLength: minLength(5)
                },
                email: {
                    required,
                    email
                },
                password: {
                    required,
                },
                confirmpassword: {
                    required,
                    sameAsPassword: sameAs('password')
                },
                termos_de_uso:{
                    required
                }, 
                cpf: { required  },
                // creci: { required  },
                telephone: { required },
                cep: { required },
                public_place_type: { required },
                public_place: { required },
                neighborhood: { required },
                state: { required },
                city: { required },
            },
        methods: {
            onComplete: function() {
                //ajuste do this
                const self = this;
                if( this.$refs.fileRG.files[0] && this.$refs.fileEnd.files[0] && this.$refs.fileFotoPerfil.files[0] ){

                        //imagens
                        // this.fileImovel = this.$refs.fileImovel.files[0]
                        this.fileRG = this.$refs.fileRG.files[0]
                        this.fileEnd = this.$refs.fileEnd.files[0]
                        this.fileFotoPerfil = this.$refs.fileFotoPerfil.files[0]

                        //pega o objeto this e coloca em uma variável
                        // let route = this.$route;

                        //envia os dados
                        var dados = new FormData()
                        //passando o nome
                        dados.append('name', this.name)
                        //passa o email
                        dados.append('email', this.email)
                        // passa a senha
                        dados.append('password', this.password)
                        // passa a role
                        dados.append('role', '["ROLE_CLIENT","ROLE_SELLER"]')
                        // passa cpf
                        dados.append('cpf', this.cpf)
                        //passa o creci
                        // dados.append('creci', this.creci)
                        // passa telefone
                        dados.append('telephone', this.telephone)
                        // passa country
                        dados.append('country', 'Brasil')
                        // passa state
                        dados.append('state', this.state)
                        // passa city
                        dados.append('city', this.city)
                        // passa public_place_type
                        dados.append('public_place_type', this.public_place_type)
                        // passa public_place
                        dados.append('public_place', this.public_place)
                        // passa number
                        dados.append('number', this.number)
                        // passa neighborhood
                        dados.append('neighborhood', this.neighborhood)
                        // passa complement
                        dados.append('complement', this.complement)
                        // passa refence
                        dados.append('refence', this.refence)
                        // passa cep
                        dados.append('cep', this.cep)
                        //passa a foto do perfil do usuário
                        dados.append('photograph', this.fileFotoPerfil)
                        //passa a foto documento imóvel
                        // dados.append('fileImovel', this.fileImovel)    
                        //passa a foto do RG
                        dados.append('fileRG', this.fileRG)
                        //passa a foto do comprovante de endereço
                        dados.append('fileEnd', this.fileEnd)
                        //exibe o loading
                        this.isLoading = true;
                        //envia os dados do form para a API para salvar o usuário
                        axios.post(
                            this.endpoint, 
                            dados,
                            { headers: {'Content-type': 'multipart/form-data'} }
                            ).then( (response) => {
                                console.log('resposta =',response.data)
                                self.isLoading = false;
                                swal('Sucesso', 'Cadastro em andamento. \n Um código de confirmação foi enviado ao seu email.', 'success').then( () => {
                                    console.log('self', self);
                                    self.confirmationCodeRedirect(response.data.user.id);
                                })
                            // }
                        }).catch( (error) => {
                            swal('Erro', error.message, 'error').then( () => {

                            })
                        })

                    }else{
                        swal('Erro', 'Você não inseriu as fotos dos documentos', 'error').then( () => {  })
                    }
                
                    
            },
            validateFirstStep() {
                // console.log('$route',this.$route)
                this.$v.email.$touch()
                this.$v.password.$touch()
                this.$v.confirmpassword.$touch()
                this.$v.termos_de_uso.$touch()
                if (this.$v.email.$invalid || 
                    this.$v.email.$invalid || 
                    this.$v.password.$invalid || 
                    this.$v.confirmpassword.$invalid ||
                    this.$v.termos_de_uso.$invalid) {
                    //exibe os erros no HTML
                } else {
                    //tudo certo pode passar para o 2º passo
                    return true;
                }
            },
            validateSecondStep() {
                console.log('this.$v.email.$invalid',this.$v.email.$invalid)
                this.$v.name.$touch()
                this.$v.cpf.$touch()
                // this.$v.creci.$touch()
                this.$v.telephone.$touch()
                this.$v.cep.$touch()
                this.$v.public_place_type.$touch()
                this.$v.public_place.$touch()
                this.$v.neighborhood.$touch()
                if (this.$v.name.$invalid || 
                    this.$v.cpf.$invalid || 
                    // this.$v.creci.$invalid || 
                    this.$v.telephone.$invalid || 
                    this.$v.cep.$invalid || 
                    this.$v.public_place.$invalid || 
                    this.$v.neighborhood.$invalid) {
                    // exibe os erros no html
                } else {
                    //tudo certo pode passar para o 3º passo
                    return true;
                }
            },

            confirmationCodeRedirect (cod_id) {
                this.$router.push({ name: 'confirmationcode', params: {id: cod_id} });
            },

            // getFileImovel () {
            //     var f = this.$refs.fileImovel.files
            //     this.srcFotoImovel = URL.createObjectURL(f[0])
            // },            
            getFileEnd () {
                var e = this.$refs.fileEnd.files
                this.srcFotoEnd = URL.createObjectURL(e[0])
            },            
            
            getFileRG () {
                var r = this.$refs.fileRG.files
                this.srcFotoRG = URL.createObjectURL(r[0])
            },
            
            getFileFotoPerfil () {
                var p = this.$refs.fileFotoPerfil.files
                this.srcFotoPerfil = URL.createObjectURL(p[0])
            },    

            //faz a busca e preenchimento do endereço pelo cep informado
            enderecoPeloCep (event) {
                //exibe o loading
                // this.exibeLoading()
                this.isLoading = true;
                var cep = event.target.value;
                //endpoint da API do ViaCEP, api gratuita.
                var urlViaCep = 'https://viacep.com.br/ws/'+cep+'/json/';
                // faz a request para a API do ViaCEP e dispara a action no vuex para armazenar os dados do endereço
                axios.get(urlViaCep)
                .then( (response) => {
                    // handle success
                    this.isLoading = false;
                    // console.log('endereço do via cep = ', response.data) 
                    this.public_place = response.data.logradouro; 
                    this.neighborhood = response.data.bairro;
                    this.switchState(response.data.uf)//seta o estado conforme o UF
                    var cidade = json.cities.filter( c => c.id == response.data.ibge);
                    // console.log('cidade=', cidade[0].name)
                    this.city = cidade[0].name;
                    // console.log(cidade[0].name)
                    // console.log(this.city)
                }).catch ((error) => {
                    console.log('tentativa de busca do endereço pelo CEP falou', error)
                })
                
            },
        
            exibeLoading () {
            let loader = this.$loading.show({
                    loader: 'dots'
                });
                // simulate AJAX
                    setTimeout(() => {
                    loader.hide()
                    },2000)
            },

            onCancel() {
              console.log('User cancelled the loader.')
            },

            getCidades (event) {
                let lstcidades = json.cities
                lstcidades.filter(el => el.states_id == event.target.value);
                let cidadesDoEstadoSelecionado = lstcidades.filter(el => el.state_id == event.target.value);
                this.cidades = cidadesDoEstadoSelecionado
            },

            switchState (uf) {
                switch (uf) {
                    case 'AC':
                    this.state = 12;
                    break;
                    case 'AL':
                    this.state = 27;
                    break;
                    case 'AP':
                    this.state = 16;
                    break;
                    case 'AM':
                    this.state = 13;
                    break;
                    case 'BA':
                    this.state = 29;
                    break;
                    case 'CE':
                    this.state = 23;
                    break;
                    case 'DF':
                    this.state = 53;
                    break;
                    case 'ES':
                    this.state = 32;
                    break;
                    case 'GO':
                    this.state = 52;
                    break;
                    case 'MA':
                    this.state = 21;
                    break;
                    case 'MT':
                    this.state = 51;
                    break;
                    case 'MS':
                    this.state = 50;
                    break;
                    case 'MG':
                    this.state = 31;
                    break;
                    case 'PA':
                    this.state = 15;
                    break;
                    case 'PB':
                    this.state = 25;
                    break;
                    case 'PR':
                    this.state = 41;
                    break;
                    case 'PE':
                    this.state = 26;
                    break;
                    case 'PI':
                    this.state = 22;
                    break;
                    case 'RJ':
                    this.state = 33;
                    break;
                    case 'RN':
                    this.state = 24;
                    break;
                    case 'RS':
                    this.state = 43;
                    break;
                    case 'RO':
                    this.state = 11;
                    break;
                    case 'RR':
                    this.state = 14;
                    break;
                    case 'SC':
                    this.state = 42;
                    break;
                    case 'SP':
                    this.state = 35;
                    break;
                    case 'SE':
                    this.state = 28;
                    break;
                    case 'TO':
                    this.state = 17;
                    break;
                
                    default:
                        break;
                }
            },
        }
    }
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Sacramento&display=swap');

    .logomarca{
        color: #000;
    }

    .realtorsubtitle{
      font-family: 'Montserrat', sans-serif;
      font-size: 2em;
      margin: auto;
      text-align: center;
    }
    
    .realtorfilestitle{
      font-family: 'Montserrat', sans-serif;
      font-size: 1em;
      margin: auto;
      text-align: center;
    }

    label{
        font-family: 'Montserrat', sans-serif;
    }
    .form-control::-webkit-input-placeholder  { color:#4C4; }
    input:-moz-placeholder { color:#4C4; }
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: red;
    opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: red;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
    color: red;
    }
    .esquerda{
        text-align: left;
    }
    .form-row{
        margin-bottom: 10px;
    }
</style>