<template>
<div>
    <site-header/>
    <div class="d-flex" id="wrapper">

    <!-- Sidebar -->
    <div class="bg-light border-right" id="sidebar-wrapper">
      <div class="sidebar-heading">Clube de Imóveis</div>
      <div class="list-group list-group-flush">
        <router-link to="/myprofile" tag="a" class="list-group-item list-group-item-action bg-light">Meu Perfil</router-link>
            <router-link to="/myfavorite" tag="a" class="list-group-item list-group-item-action bg-light">Favoritos</router-link>
            <router-link to="/myimmobile" tag="a" class="list-group-item list-group-item-action bg-light">Imóveis Cadastrados</router-link>
            <a href="#" class="list-group-item list-group-item-action bg-light">Meus Corretores</a>
            <router-link to="/confirmbroker" tag="a" class="list-group-item list-group-item-action bg-light">Corretores Cadastrados</router-link>
            <router-link to="/createimmobile" tag="a" class="list-group-item list-group-item-action bg-light">Cadastrar Imóvel</router-link>
      </div>
    </div>
    
    <div class="container-fluid">
        <ListBroker :brokers="brokers"/>
    </div>
  </div>
  <!-- /#wrapper -->
</div>
</template>

<script>
import ListBroker from '../../components/ListBroker.vue'
import SiteHeader from '../../components/SiteHeader.vue';

export default {
  name: 'ConfirmBroker',
  components: {
      ListBroker,
      SiteHeader,
  },
  props: {
  },
  data () {
    return {
        brokers: [
            {
                id: 1,
                img:    require('../../assets/images/pp.jpg'),
                name: "Jhenyfer Karolina Nascimento de Souza",
                role: "Corretor(a)",
                creci: "CRECI-MA 10002020",
                status: "ativo",
            },
            {
                id: 2,
                img:    require('../../assets/images/team_2.jpg'),
                name: "Jeferson Nascimento Barbosa",
                role: "Corretor(a)",
                creci: "CRECI-MA 12322011",
                status: "inativo",
            },
            {
                id: 3,
                img:    require('../../assets/images/team_1.jpg'),
                name: "Ana Luiza Ferreira",
                role: "Corretor(a)",
                creci: "CRECI-MA 13232016",
                status: "ativo",
            },
            {
                id: 4,
                img:    require('../../assets/images/team_4.jpg'),
                name: "Pedro da Silva Cardoso",
                role: "Corretor(a)",
                creci: "CRECI-MA 32122016",
                status: "inativo",
            },
        ]
    }
  },
  methods: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>