<template>
    <div>
        <div v-for="user in users" :key="user.id">
            <table class="table table-borderless">
            <tbody>
                <tr class="row">
                    <td class="col-md-1 pl-4 align-middle"><img :src="user.img" :alt="user.img" id="img-profile"></td>
                    <td class="col-md-4 align-middle">{{user.name}}<span class="font-italic blockquote-footer">{{user.email}}</span></td>
                    <td class="col-md-3 align-middle">{{user.creci}}</td>
                    <td v-if="user.status === 'ativo'" class="col-md-2 align-middle"><p id="situation-active">Ativo</p></td>
                    <td v-else class="col-md-2 align-middle"><p id="situation-inactive">Inativo</p></td>
                    <td class="col-md-2">
                        <div class="dropdown">
                        <a class="btn dropdown-toggle border border-primary text-primary" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Ações
                        </a>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                            <a class="dropdown-item text-primary" href="#"><span class="fas fa-user pr-2"></span>Visualizar perfil</a>
                            <a v-if="user.status == 'ativo'" class="dropdown-item text-warning" href="#"><span class="fas fa-times-circle pr-2"></span>Desativar corretor</a>
                            <a v-else class="dropdown-item text-success" href="#"><span class="fas fa-check-circle pr-2"></span>Ativar corretor</a>
                            <a class="dropdown-item text-danger" href="#"><span class="fas fa-trash-alt pr-2"></span>Deletar corretor</a>
                        </div>
                        </div>
                    </td>
                       
                    <hr width="95%" align="center">
                </tr>
            </tbody>
        </table>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            corretores: {}
        }
    },
    props: {
        users: Object
    },
    mounted () {
        this.corretores = this.users
        console.log('corretores', this.corretores);
    }
}
</script>

<style scoped>
/* Importando o bootstrap 4 */
@import '../assets/styles/bootstrap4/bootstrap.min.css';

/* Importando o estilo da pagina*/
@import '../assets/styles/simple_sidebar.css';

/* Estilo para editar a imagem de perfil do corretor */
#img-profile{
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 90%;
}

#situation-active{
    position:relative;
    margin: 2px;
    padding: 3px;
    width: 65%;
    border-radius: 5%;
    text-align: center;
    color: white;
    font-weight: bold;
    background-color: green;
}

#situation-inactive{
    position:relative;
    margin: 2px;
    padding: 3px;
    width: 65%;
    border-radius: 5%;
    text-align: center;
    color: white;
    font-weight: bold;
    background-color: red;
}
</style>