<template>
  <div>
    <loading
      :active.sync="isLoading"
      :is-full-page="fullPage"
      :can-cancel="false"
    ></loading>
    <nav class="navbar navbar-expand-lg navbar-custom w-100 col-md-12 bg-success">
      <router-link
        id="logo"
        to="/"
        tag="a"
        class="navbar-brand"
      >
        <img
          src="../assets/logo.png"
          width="30"
          height="30"
          class="d-inline-block align-top"
          alt=""
        >
        Clube de Imóveis
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon">
          <i class="fas fa-bars" style="color:#fff; font-size:28px;"></i>
        </span>
      </button>

      <div
        class="collapse navbar-collapse"
        id="navbarSupportedContent"
      >

      <!-- 
        ###### Menu Superior para usuários comuns ######
        -->
      <ul v-if="!isAdmin" class="navbar-nav mr-auto">
          <li class="nav-item">
            <a
              class="nav-link"
              href="#"
            >Arquitetura e Reforma</a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="#"
            >Serviços</a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="#"
            >Financiamento</a>
          </li>
        </ul>
      <!-- fim Menu Superior para usuários comuns -->
      
      <!-- ***** Menu Superior para Administradores *****  -->
        <ul v-if="isAdmin" class="navbar-nav mr-auto">
          <li class="nav-item">
            <router-link
                to="/dashboard"
                tag="a"
                class="nav-link"
            >Dashboard</router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/list-realtors"
              tag="a"
              class="nav-link"
            >Corretores</router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/immobile-admin"
              tag="a"
              class="nav-link"
            >Imóveis</router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/list-buyers"
              tag="a"
              class="nav-link"
            >Compradores</router-link>
          </li>
        </ul>
      <!-- fim Menu Superior para Administradores -->
        
        <!--menu direito para cliente não logado -->
        <div
          v-if="!isAuthenticated"
          class="ml-auto navbar-text"
        >
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link
                to="/ajuda"
                id="button-broker"
                tag="a"
                class="navbar"
              >Ajuda</router-link>
            </li>
            <!-- <li class="nav-item">
              <router-link
                to="/login"
                id="button-login"
                tag="a"
                class="navbar"
              >Entrar</router-link>
            </li> -->
          </ul>
        </div>
        <!--
          ####################
          menu direita 
          para cliente logado 
          ####################
        -->
        <div
          v-if="isAuthenticated"
          class="navbar-text pr-4"
          style="margin-right:10%"
        >
          <ul class="navbar-nav">
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <!-- 'http://localhost:8080/clube_imoveis_back/storage/app/public/' -->
                <img
                  v-if="isAuthenticated && isAvatar"
                  :src="endpoint_images + client.profile.photograph"
                  width="30"
                  height="30"
                  class="rounded-circle"
                >
                <img
                  v-else
                  src="https://png.pngtree.com/png-vector/20190710/ourmid/pngtree-user-vector-avatar-png-image_1541962.jpg"
                  width="30"
                  height="30"
                  class="rounded-circle"
                >
                <!-- <img src="https://png.pngtree.com/png-vector/20190710/ourmid/pngtree-user-vector-avatar-png-image_1541962.jpg" width="30" height="30" class="rounded-circle"> -->
              </a>
              <div
                class="dropdown-menu"
                aria-labelledby="navbarDropdown"
              >
                <router-link
                  to="/myprofile"
                  id="perfil"
                  tag="a"
                  class="dropdown-item"
                >Perfil</router-link>
                <router-link
                  to="/myimmobile"
                  id="meus-imoveis"
                  tag="a"
                  class="dropdown-item"
                >Meus Imóveis</router-link>
                <router-link
                  v-if="role[0] === 'ROLE_CLIENT'"
                  to="/myfavorite"
                  id="favoritos"
                  tag="a"
                  class="dropdown-item"
                >Favoritos</router-link>
                <router-link
                  to="/createimmobile"
                  id="cadastrar-imovel"
                  tag="a"
                  class="dropdown-item"
                >Cadastrar Imóvel</router-link>
                <router-link
                  to="/messageschat"
                  id="/messages-chat"
                  tag="a"
                  class="dropdown-item"
                >Mensagens <!--<span class="badge badge-success">1</span>--></router-link>
                <a
                  href="javascript:void(0)"
                  @click="handleClick"
                  class="dropdown-item"
                >Sair</a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
// Import do component de loading
import Loading from 'vue-loading-overlay'
//sweet alert
/* import swal from 'sweetalert'
//axios */
import axios from 'axios'
//vuex
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'TopoComAjuda',
  components: {
    Loading
  },
  data () {
    return {
      isLoading: false,
      isAdmin: false,
      fullPage: true,
      client: null,
      isAvatar: false,
      endpoint: process.env.VUE_APP_USER,
      urlbase: process.env.VUE_APP_URLBASE_FRONT_END,
      endpoint_images: process.env.VUE_APP_IMAGES,
      resposta: null,
      varteste: 0
    }
  },
  computed: {
    ...mapGetters(['getUser', 'isAuthenticated']),
    ...mapState(['user']),
    role () {
      return this.papeis()
    },
  },
  async created () {
    let user = JSON.parse(localStorage.getItem('user'))
    if(user){
      this.administrador(user)
      this.$store.dispatch('localStorageSetUser', user)
    }
    try {
      this.isLoading = true;
      let response = undefined
      if(localStorage.getItem('token')){
          response = await axios.get(this.endpoint, {
          headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
        })
      }
      /* console.log('resposta', response); */
      if (response) {
        this.isLoading = false;
        this.client = response.data.user;
        //se tiver avatar pega o avatar
        if (response.data.profile.photograph != undefined && response.data.profile.photograph != '') {
          this.isAvatar = true;
          // console.log(response);
        } else {
          this.isAvatar = false;
        }

      } else {
        this.isLoading = false;
        this.client = null;
      }
    } catch (error) {
      this.isLoading = false;
      // console.log('error', error);
      // console.log('visitante');
    }
  },
  methods: {
    async handleClick () {
      await localStorage.removeItem('token');
      await localStorage.removeItem('user');
      window.location.href = this.urlbase;
    },
    
    papeis() {
      const papeis = JSON.parse(this.user.user.role)
      return papeis
    },
    administrador(user){
      const papeis = JSON.parse(user.user.role)
      const isAdmin = papeis.includes("ROLE_ADMIN")
      isAdmin ? this.isAdmin = true : this.isAdmin = false
    }

  },
}
</script>

<style scoped>
/* Estilo do barra de navegacao */

/* button login */
#button-login {
  color: orange;
  font-weight: 700;
}

#button-login:hover {
  color: white;
  background-color: orange;
  text-decoration: none;
  border-radius: 3%;
  transition: 0.25s ease-out;
}

/* button broker */
#button-broker {
  color: rgba(0, 0, 255, 0.548);
  font-weight: 600;
}

#button-broker:hover {
  color: white;
  background-color: rgba(0, 0, 255, 0.603);
  text-decoration: none;
  border-radius: 3%;
  transition: 0.25s ease-out;
}

/* links */
#site-header a:hover {
  text-decoration: none;
}
/* google fonts */
@import url("https://fonts.googleapis.com/css2?family=Lobster&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lobster&family=Sacramento&display=swap");

/* logo */
#logo {
  color: black;
  font-family: Oleo Script;
  font-weight: 700;
  font-size: 14pt;
}

/** estilos customizados da navbar */
/* change the background color */
.navbar-custom {
  background-color: #000;
  height: 1%;
}
/* change the brand and text color */
.navbar-custom .navbar-brand,
.navbar-custom .navbar-text {
  color: red;
  padding: 0.8rem 1rem;
}
/* change the link color and add padding for height */
.navbar-custom .navbar-nav .nav-link {
  color: red;
  padding: 1rem 1rem;
}
/* change the color of active or hovered links */
.navbar-custom .nav-item.active .nav-link,
.navbar-custom .nav-item:hover .nav-link {
  color: #ffffff;
  background-color: rgba(
    215,
    203,
    202,
    0.1
  ); /* add background-color to active links */
}
.navbar-custom {
  background-color: #ff5500;
}

/* change the brand and text color */
.navbar-custom .navbar-brand,
.navbar-custom .navbar-text {
  color: rgba(215, 203, 202, 0.8);
}

/* change the link color */
.navbar-custom .navbar-nav .nav-link {
  color: #ffffff;
}

/* change the color of active or hovered links */
.navbar-custom .nav-item.active .nav-link,
.navbar-custom .nav-item:hover .nav-link {
  color: #ffffff;
}

/* for dropdown only - change the color of droodown */
.navbar-custom .dropdown-menu {
  background-color: #28a745 !important;
}
.navbar-custom .dropdown-item {
  color: #ffffff;
}
.navbar-custom .dropdown-item:hover,
.navbar-custom .dropdown-item:focus {
  color: #fff !important;
  background-color: rgba(3, 63, 13, 0.5);
}

/**toggle */
.navbar-toggler span {
  color: #fff;
}
/** estilos customizados da navbar */
</style>