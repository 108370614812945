<template>
        <div class="card mb-4 shadow-sm">
                <!-- Loading -->
                    <loading :active.sync="isLoading" 
                    :can-cancel="true" 
                    :on-cancel="onCancel"
                    :is-full-page="fullPage"></loading>
                <!-- fim Loading -->

            <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                <div class="carousel-inner">
                    <router-link :to="`/immobileinfo/${immobileBox.id}`">
                        <img :src="endpoint_images + immobileBox.photos[0].photo" class="bd-placeholder-img card-img-top" alt="Imagem do imóvel" height="210"/>
                    </router-link>
                    <!-- <swiper class="swiper" :options="swiperOption"> -->
                        <!-- <swiper-slide v-for="(img, index) in immobileBox.photos" :key="index">
                            <img :src="endpoint_images + img.photo" class="bd-placeholder-img card-img-top" alt="Imagem do imóvel" height="210">
                        </swiper-slide> -->
                        <!-- <div class="swiper-button-prev" slot="button-prev"></div>
                        <div class="swiper-button-next" slot="button-next"></div> -->
                    <!-- </swiper> -->
                </div>
            </div>  

            <div class="card-body row">
                <h5 class="card-text font-weight-bold col-md-10 pl-0 pt-2"><router-link :to="`/immobileinfo/${immobileBox.id}`" tag="a" class="text-success">{{immobileBox.title}}</router-link></h5>
                <!-- <button id="button-broker" role="button" aria-hidden="true" @click="mudarEstilo()" :class="{'like': favorite, 'unlike': !favorite}" class="col-md-2 p-0"><i class="fas fa-heart"></i></button> -->
                <p class="card-text mt-0 mb-2 text-muted col-12 pl-0">{{immobileBox.neighborhood}} - {{immobileBox.city}} / {{immobileBox.state}}</p>
                <h5 class="card-text col-8 pl-0 pb-1 mt-1 font-weight-bold text-success">{{immobileBox.price | currency('R$', 2, {thousandsSeparator: '.', decimalSeparator: ',', spaceBetweenAmountAndSymbol: true })}}</h5>
                <p class="card-text col-12 p-0 m-0 text-muted">
                    <span><img src="../assets/img/icons/area.png" id="icon-area" alt=""> {{immobileBox.ground_area}} m² </span>
                    <!-- <span v-if="immobileBox.number_suite === 1" class="card-text col-12 p-0 m-0 text-muted ml-2"><i class="fas fa-procedures mr-1"></i>{{immobileBox.number_suite}} </span>
                    <span v-if="immobileBox.number_suite > 1" class="card-text col-12 p-0 m-0 text-muted ml-2"><i class="fas fa-procedures mr-1"></i>{{immobileBox.number_suite}} </span>
                    <span v-if="immobileBox.number_room === 1" class="card-text col-12 p-0 m-0 text-muted ml-2"><i class="fas fa-bed mr-1"></i>{{immobileBox.number_room}} </span> -->
                    <span v-if="immobileBox.number_room > 1" class="card-text col-12 p-0 m-0 text-muted ml-2"><i class="fas fa-bed mr-1"></i>{{immobileBox.number_room}} </span>
                    <span v-if="immobileBox.number_bathroom === 1" class="card-text col-12 p-0 m-0 text-muted ml-2"><i class="fas fa-bath mr-2"></i>{{immobileBox.number_bathroom}} </span>
                    <span v-if="immobileBox.number_bathroom > 1" class="card-text col-12 p-0 m-0 text-muted ml-2"><i class="fas fa-bath mr-2"></i>{{immobileBox.number_bathroom}} </span>
                    <span v-if="immobileBox.number_space === 1" class="card-text col-12 p-0 m-0 text-muted ml-2"><i class="fas fa-car mr-2"></i>{{immobileBox.number_space}} </span>
                    <span v-if="immobileBox.number_space > 1" class="card-text col-12 p-0 m-0 text-muted ml-2"><i class="fas fa-car mr-2"></i>{{immobileBox.number_space}} </span>
                </p>
                <!-- <p v-if="immobileBox.number_suite === 1" class="card-text col-12 p-0 m-0 text-muted"><i class="fas fa-procedures mr-1"></i> {{immobileBox.number_suite}} Suíte</p>
                <p v-if="immobileBox.number_suite > 1" class="card-text col-12 p-0 m-0 text-muted"><i class="fas fa-procedures mr-1"></i> {{immobileBox.number_suite}} Suítes</p>
                <p v-if="immobileBox.number_room === 1" class="card-text col-12 p-0 m-0 text-muted"><i class="fas fa-bed mr-1"></i> {{immobileBox.number_room}} Dormitório</p>
                <p v-if="immobileBox.number_room > 1" class="card-text col-12 p-0 m-0 text-muted"><i class="fas fa-bed mr-1"></i> {{immobileBox.number_room}} Dormitórios</p>
                <p v-if="immobileBox.number_bathroom === 1" class="card-text col-12 p-0 m-0 text-muted"><i class="fas fa-bath mr-2"></i> {{immobileBox.number_bathroom}} Banheiro</p>
                <p v-if="immobileBox.number_bathroom > 1" class="card-text col-12 p-0 m-0 text-muted"><i class="fas fa-bath mr-2"></i> {{immobileBox.number_bathroom}} Banheiros</p>
                <p v-if="immobileBox.number_space === 1" class="card-text col-12 p-0 m-0 text-muted"><i class="fas fa-car mr-2"></i> {{immobileBox.number_space}} Garagem</p>
                <p v-if="immobileBox.number_space > 1" class="card-text col-12 p-0 m-0 text-muted"><i class="fas fa-car mr-2"></i> {{immobileBox.number_space}} Garagens</p> -->
                <router-link :to="`/immobileinfo/${immobileBox.id}`" class="btn btn-link mt-3 text-center">Mais detalhes</router-link>
            </div>
        </div>      
</template>

<script>
/* import axios from 'axios' */
// import { swiper, swiperSlide} from 'vue-awesome-swiper'

// import style (>= Swiper 6.x)
import 'swiper/swiper-bundle.css'

// import style (<= Swiper 5.x)
import 'swiper/swiper.min.css'

//sweet alert
import swal from 'sweetalert'

//importando axios
import axios from 'axios' 

// Import do component de loading
import Loading from 'vue-loading-overlay';

import {mapState} from 'vuex'

//filtro preços
import Vue2Filters from 'vue2-filters'


export default {
    components: {
        // swiper,
        // swiperSlide,
        Loading,
    },
    mixins: [Vue2Filters.mixin],
    props: {
        immobileBox: {}
    },
    computed: {
        ...mapState({
            user: state => state.user,
        }),
        teste () {
            console.log('immobileBox', this.immobileBox);
            return 1;
        }
    },
    data(){
        return {
            isLoading: false,
            fullPage: false,
            swiperOption: {
                slidesPerView: 1,
                spaceBetween: 30,
                effect: 'fade',
                loop: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            },
            favorite: false,
            endpointdev_user: process.env.VUE_APP_USER,
            urldev: process.env.VUE_APP_FAVORITE,
            endpoint_images: process.env.VUE_APP_IMAGES,
            user_id: null,
            money: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: '',
                precision: 2,
                masked: false
            },
        }
    },
    methods:{
        mudarEstilo: async function(){
            this.isLoading = true;

            if(localStorage.getItem('token') == null){
                swal('Ops', 'Você deve fazer o login primeiro.', 'info').then( () => {
                    this.$router.push('/login');
                })
            }else{
                /* pegando o id do usuario logado */
                this.user_id = this.user.user.id;
                
                //envia os dados
                var dados = new FormData()
                dados.append('user_id', this.user_id)
                dados.append('immobile_id', this.immobileBox.id)
                dados.append('favorited', true)   
                
                /* favoritando o imovel de acordo com id do usuario logado */
                await axios.post(
                    this.urldev + this.user_id,
                    dados, 
                    { headers: {
                    'Content-type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
                });

                this.favorite = !this.favorite;
                this.isLoading = false;
                
            }
            
        },
        onCancel () {
            console.log('User cancelled the loader.')
        },
    },
    
}
</script>

<style scoped>

/* Importando o bootstrap 4 */
@import '../assets/styles/bootstrap4/bootstrap.min.css';

/* Importando o estilo da pagina*/
@import '../assets/styles/simple_sidebar.css';



/*Estilo para editar o tamanho da imagem do usuario que postou o imovel*/
#img-profile{
    position: relative;
    width: 80%;
    height: 80%;
    border-radius: 90%;
}

/* Estilo para editar o tomanho do icone de area */
#icon-area{
    position: relative;
    width: 8%;
    height: 90%;    
}

/*Estilo para editar imagem*/
#img-immobile-text{
    position: absolute;
    top: 0%;
    margin-left: 0%;
    padding: 1%;
    color: white;
    background: green;
    border-radius: 10%;
}

#img{
height: 10%;
widows: 10%;
}

/* Estilo do botao de curtir */
#button-broker{
    border: none;
    background: none;
}

#button-broker:focus{
    outline-style: none;
}

.unlike{
    color: #00000030;
    font-size: 2em;
}
.like{
    color: #f44336;
    font-size: 2em;
}
</style>