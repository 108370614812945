<template>
    <div class="containertemplate">
        <div class="containerimovelchat">
            <div class="imovel">
                <div class="imoveltitulo">
                    
                </div>
                <div class="imovelimg">
                    <img v-if="imovel_do_chat && imovel_do_chat.length > 0" :src="endpoint_images+imovel_do_chat.photos[0].photo" :alt="imovel_do_chat.title">
                </div>
                <div class="imoveltxt">
                    <h5 style="padding-bottom: 5px">{{ imovel_do_chat.title }}</h5>
                    <p>{{ imovel_do_chat.price | currency('R$', 2, {thousandsSeparator: '.', decimalSeparator: ',', spaceBetweenAmountAndSymbol: true }) }}</p>
                    <p>{{ imovel_do_chat.city }} - {{ imovel_do_chat.state }}</p>
                </div>
                <div class="imovelbtn">
                     <router-link :to="'/immobileinfo/'+imovel_do_chat.id" tag="button" class="infobtn">Mais informações</router-link>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
//filtro preços
import Vue2Filters from 'vue2-filters'

export default {
    name: 'ImovelChat',
    mixins: [Vue2Filters.mixin],
    props: ['imovel_do_chat'],
    data() {
        return {
            endpoint_images: process.env.VUE_APP_IMAGES,
            immobileslocal: null,
        }
    },
}

    

</script>

<style lang="scss" scoped>
    .imovel {
        width: 420px;
        height: 450px;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-left: 30px;
        padding-right: 30px;
        overflow-y: auto;
        overflow-x: hidden;
        
    }

    .imovel h5 {
        word-break: break-word;
        width: 350px;
    }

    .imovelimg {
        padding: 25px;
    }

    .imovelimg img {
        width: 300px;
        height: 200px;
        border-radius: 5px;
    }

    .imovelbtn {
        display: flex;
        align-items: center;
        padding: 15px;
    }

    .infobtn {
        border: 1px solid #28a745;
        background-color: white;
        width: 250px;
        height: 40px;
        
        border-radius: 5px;
        color: #28a745;
        font-size: 22px;
    }

    .infobtn:hover {
        border: 1px solid #1f8036;
        color: #1f8036;
    }


</style>