import { render, staticRenderFns } from "./CepAddress.vue?vue&type=template&id=787c4bfc&scoped=true&"
import script from "./CepAddress.vue?vue&type=script&lang=js&"
export * from "./CepAddress.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "787c4bfc",
  null
  
)

export default component.exports