<template>
    <div>
        <!-- Header Menu Principal -->
        <site-header />
        <div class="d-flex" id="wrapper">
        <!-- Sidebar Menu Lateral -->
        <sidebar/>
        <div class="container-fluid">
            <!-- Loading -->
            <loading :active.sync="isLoading" 
            :can-cancel="true" 
            :is-full-page="false"></loading>
            <!-- fim Loading -->
            <!-- As a heading -->
            <nav class="navbar navbar-light bg-light col-md-12">
                <span class="navbar-brand mb-0 h1">Informações do Usuário</span>
            </nav>
            <div class="col-md-2 float-md-right mt-4">
                <img :src="img" class="rounded" alt="Imagem de perfil" style="width:96px; height:96px">
            </div>
            <br>
            <form class="needs-validation" novalidate>
                <br>
                <h5 class="text-secondary font-weight-bold text-uppercase">Informações pessoais</h5>
                <br>
                <div class="form-row">
                    <div class="col-md-6 mb-3">
                        <label for="name">Nome</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-user"></i></span>
                            </div>
                            <span class="form-control">{{name +' '+ surname}}</span>
                        </div>
                    </div>

                    <div class="col-md-6 mb-3">
                        <label for="email">Email</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-mail-bulk"></i></span>
                            </div>
                            <span class="form-control">{{email}}</span>
                        </div>
                    </div>

                    <div class="col-md-6 mb-3">
                        <label for="cpf">CPF</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-clipboard-list"></i></span>
                            </div>
                            <span class="form-control">{{cpf}}</span>
                        </div>
                    </div>

                    <div class="col-md-6 mb-3">
                        <label for="telephone">Telefone</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-phone"></i></span>
                            </div>
                            <span class="form-control">{{telephone}}</span>
                        </div>
                    </div>
                </div>

                <hr size="10" width="95%" align="center">
                <h5 class="text-secondary font-weight-bold text-uppercase">Endereço</h5>
                <div class="form-row">
                    <div class="col-md-3 mb-3">
                        <label for="contry">Pais</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-globe-americas"></i></span>
                            </div>
                            <span class="form-control">{{country}}</span>
                        </div>
                    </div>

                    <div class="col-md-3 mb-3">
                        <label for="state">Estado</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-map"></i></span>
                            </div>
                            <span class="form-control">{{state}}</span>
                        </div>
                    </div>

                    <div class="col-md-3 mb-3">
                        <label for="city">Cidade</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-map-marked-alt"></i></span>
                            </div>
                            <span class="form-control">{{city}}</span>
                        </div>
                    </div>

                    <div class="col-md-3     mb-3">
                        <label for="cep">Cep</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-flag"></i></span>
                            </div>
                            <span class="form-control">{{cep}}</span>
                        </div>
                    </div>

                    <div class="col-md-4 mb-3">
                        <label for="neighborhood">Bairro</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-map-marked-alt"></i></span>
                            </div>
                            <span class="form-control">{{neighborhood}}</span>
                        </div>
                    </div>

                    <div class="col-md-6 mb-3">
                        <label for="street">Logradouro</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-map-marked-alt"></i></span>
                            </div>
                            <span class="form-control">{{street}}</span>
                        </div>
                    </div>

                    <div class="col-md-2 mb-3">
                        <label for="number">Número</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-map-marked-alt"></i></span>
                            </div>
                            <span class="form-control">{{number}}</span>
                        </div>
                    </div>
                </div>

                <!--<hr size="10" width="95%" align="center">
                <h5 class="text-secondary font-weight-bold text-uppercase">Informações profissionais</h5>
                <div class="form-row">
                    <div class="col-md-6 mb-3">
                        <label for="function">Função</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-id-card-alt"></i></span>
                            </div>
                            <span class="form-control">{{role}}</span>
                        </div>
                    </div>

                    <div class="col-md-6 mb-3">
                        <label for="creci">Creci</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-id-card"></i></span>
                            </div>
                            <span class="form-control">{{creci}}</span>
                        </div>
                    </div>
                </div>
                <div class="form-row">   
                    <div class="col-md-12">
                    <hr size="10" width="95%" align="center">
                    <h5 class="text-secondary font-weight-bold text-uppercase">Documentos</h5>
                    </div> 
                    
                    <div class="col-md-4 mb-5 mt-3">
                        <img :src="imgCreci" @click="aumentarImagemRg" class="rounded" :class="{'tamanhoNormal': !aumentarImgrRg, 'tamanhoAumentado1': aumentarImgRg}" alt="Imagem de perfil">
                    </div>

                    <div class="col-md-4 mb-5 mt-3">
                        <img :src="imgRg" @click="aumentarImagemCreci" class="rounded" :class="{'tamanhoNorma2': !aumentarImgCreci, 'tamanhoAumentado2': aumentarImgCreci}" alt="Imagem de perfil">
                    </div>

                    <div class="col-md-4 mb-5 mt-3">
                        <img :src="imgComprovante" @click="aumentarImagemComprovante" class="rounded" :class="{'tamanhoNorma3': !aumentarImgComprovante, 'tamanhoAumentado3': aumentarImgComprovante}" alt="Imagem de perfil">
                    </div>
                </div>-->
            </form>
        </div>
    </div>
    <!-- /#wrapper -->
    </div>
</template>

<script>
// Import do component de loading
import Loading from 'vue-loading-overlay';
import SiteHeader from '../../components/SiteHeader.vue';
import Sidebar from '../../components/Sidebar.vue';
import axios from 'axios'

export default {
  name: 'ShowProfileUsersForAdmins',
  components: {
      SiteHeader, Sidebar,Loading,
  },
  props: {
  },
  data () {
    return {
        isLoading: false,
        endpoint_show_user: process.env.VUE_APP_USER_BY_ID,
        endpoint_images: process.env.VUE_APP_IMAGES,
        aumentarImgRg: false,
        aumentarImgCreci: false,
        aumentarImgComprovante: false,
        id: 1,
        img:    require('../../assets/images/pp.jpg'),
        imgCreci: require('../../assets/img/creci.jpg'),
        imgRg: require('../../assets/img/rg.png'),
        imgComprovante: require('../../assets/img/comprovante_endereco.jpg'),
        name: "Jhenyfer",
        surname: "Karolina Nascimento de Souza",
        role: "Corretor(a)",
        email: "jhenyferkarolina@gmail.com",
        telephone: "(99) 99113-2168",
        cpf: "002-131-123-12",
        country: "Brasil",
        state: "Maranhão",
        city: "Imperatriz",
        cep: "65.940-000",
        neighborhood: "Vila Lobão",
        street: "Rua Maranhão",
        number: "21",
        creci: "CRECI-MA 10002020",
        status: "ativo",
    }
  },
  async mounted () {
      let path = this.endpoint_show_user+this.$route.params.id
    //   console.log('$route.params.id',this.$route.params.id)
    //   console.log('path', path)
      axios.get(path, {
        headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      }).then( (result)=>{
        //   console.log(result)
          this.img = this.endpoint_images + result.data.user.profile.photograph
          this.name = result.data.user.name
          this.surname = ''
          this.role = result.data.user.role
          this.email = result.data.user.email
          this.telephone = result.data.user.profile.telephone
          this.cpf = result.data.user.profile.cpf
          this.country = result.data.user.profile.country
          this.state = result.data.user.profile.state
          this.city = result.data.user.profile.city
          this.cep = result.data.user.profile.cep
          this.neighborhood = result.data.user.profile.neighborhood
          this.street = result.data.user.profile.public_place
          this.number = result.data.user.profile.number
      }).catch( (error)=> {
          console.log('error', error)
      })
  },
  methods: {
    aumentarImagemRg(){
        this.aumentarImgRg = !this.aumentarImgRg;
    },
    aumentarImagemCreci(){
        this.aumentarImgCreci = !this.aumentarImgCreci;
    },
    aumentarImagemComprovante(){
        this.aumentarImgComprovante = !this.aumentarImgComprovante;
    },
    onCancel () {
      console.log('User cancelled the loader.')
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.tamanhoNormal, .tamanhoNorma2, .tamanhoNorma3{
    width: 100px;
    height: 100px;
}
.tamanhoAumentado1, .tamanhoAumentado2, .tamanhoAumentado3{
    width: 400px;
    height: 250px;
}
</style>