<template>
<div>
 <site-header/>
    <div class="d-flex" id="wrapper">
     
    <div class="bg-light border-right" id="sidebar-wrapper">
      <Sidebar></Sidebar> 
    </div>
      <!--<div class="sidebar-heading">Clube de Imóveis</div>
      <div class="list-group list-group-flush">
        <router-link to="/myprofile" tag="a" class="list-group-item list-group-item-action bg-light">Meu Perfil</router-link>
                <router-link to="/myfavorite" tag="a" class="list-group-item list-group-item-action bg-light">Favoritos</router-link>
                <router-link to="/myimmobile" tag="a" class="list-group-item list-group-item-action bg-light">Imóveis Cadastrados</router-link>
                <a href="#" class="list-group-item list-group-item-action bg-light">Meus Corretores</a>      
                <router-link to="/confirmbroker" tag="a" class="list-group-item list-group-item-action bg-light">Corretores Cadastrados</router-link>
                 <router-link to="/createimmobile" tag="a" class="list-group-item list-group-item-action bg-light">Cadastrar Imóvel</router-link>
       </div>
    </div>-->
    <!-- /#sidebar-wrapper -->

    <!-- Page Content -->
    <div id="page-content-wrapper">

      

      <div class="container-fluid">
        <div class="containertitulo">
          <h1>Informações do imóvel</h1>
        </div>
        <div class="containerac">
          
          <ul class="listaac">
            <li><button class="btnac"><i class="fas fa-pen"></i> Editar imóvel</button></li>
            <li><button class="btnac"><i class="fas fa-ban"></i> Suspender imóvel</button></li>
          </ul>
        </div>
        <div class="containerinfo">
          <div class="containerimg">
            <img :src="endpoint_images+Imovel.photos[0].photo" alt="">
          </div>
          <div class="infolista">
            <ul class="listainfo">
              <li><h3>{{Imovel.title}}</h3></li>
              <li><p>Preço: {{Imovel.price}}</p></li>
              <li><p>Publicado: {{format(new Date(Imovel.created_at), 'dd/MM/yyyy, hh:mm:ss')}}</p></li>
              <li><p>Estado: {{Imovel.state}}</p></li>
              <li><p>Cidade: {{Imovel.city}}</p></li>
              <li><p>Bairro: {{Imovel.neighborhood}}</p></li>
              <li><p>CEP: {{Imovel.cep}}</p></li>
            </ul>
          </div>
        </div>
        
      </div>
    </div>
    <!-- /#page-content-wrapper -->

  </div>
  <!-- /#wrapper -->
  </div>
</template>

<script>
import { constantes } from '../../consts.js'
import { format } from 'date-fns-tz'



import SiteHeader from '../../components/SiteHeader.vue'
import Sidebar from '../../components/Sidebar.vue'
export default {
  components: { SiteHeader, Sidebar },
  name: 'InfoAdmin',

  data () {
    return {
        Imovel: {},
        imovelImagem: [],
        endpoint_images: constantes.endpoint_images,
        endpoint_immobile: constantes.endpoint_immobile,
        format
    }
  },
  computed: {
    loadToken () {
      return this.$store.getters.getToken
    },
    getterImmobiles() {
      return this.$store.getters.allImmobiles
    },
    immobiles() {
      return this.$store.state.immobiles
    },

  },

  created() {
    this.$store.dispatch('getImmobiles')
  },

  mounted() {
    const axios = require('axios');
    var url = this.endpoint_immobile + '/'

    axios.get(url + this.$route.params.id)
    .then(response => {
      this.Imovel = response.data.data
      
      var photos = response.data.data.photos
      photos.forEach(img => {
        this.imovelImagem.push(this.endpoint_images + img.photo)
      });
    })

  .catch(function(error) {
    console.log(error)
  })
  .then(function() {

  })
  },

  methods: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>

  * {
    font-family: sans-serif;
    color: inherit;
  }

  .containertitulo {
    padding-top: 20px;
  }

  .containertitulo h1 {
    font-size: 1.25rem;
  }

  .containerac {
    padding-top: 25px;
    padding-left: 28px;
  }

  .listaac {
    list-style-type: none;
    padding: 0;
  }

  .listaac li {
    display: inline-block;
    padding: 5px;
    
    
  }

  .containerinfo {
    display: flex;
    padding: 25px;
  }

  .infolista h3 {
    font-size: 1.25rem;
    padding-bottom: 5px;
  }

  .listainfo {
    list-style-type: none;
  }

  .containerimg img {
    width: 500px;
    height: 400px;
  }

  .containerac {
    padding-left: 20px;
  }

  .btnac {
    background-color: white;
    border: 2px solid #343a40;
    border-radius: 5px;
    color: #343a40;
    width: 180px;
    height: 40px;
    transition: .3s;
    margin: 0;
  }

  .btnac:hover {
    background-color: rgb(230, 230, 230);
  }



  

/* Importando o bootstrap 4 */
@import '../../assets/styles/bootstrap4/bootstrap.min.css';

/* Importando o estilo da pagina*/
@import '../../assets/styles/simple_sidebar.css';
</style>