<template>
    <div>
    <site-header/>
    <div class="d-flex" id="wrapper">

    <loading 
    :active.sync="isLoading"
    :is-full-page="fullPage"
    :can-cancel="false" ></loading>

    <!-- Sidebar -->
    <!-- <div class="bg-light border-right" id="sidebar-wrapper">
      <div class="sidebar-heading">Clube de Imóveis</div>
      <div class="list-group list-group-flush">
        <router-link to="/myprofile" tag="a" class="list-group-item list-group-item-action bg-light">Meu Perfil</router-link>
        <router-link to="/myfavorite" tag="a" class="list-group-item list-group-item-action bg-light">Favoritos</router-link>
        <router-link to="/myimmobile" tag="a" class="list-group-item list-group-item-action bg-light">Imóveis Cadastrados</router-link>
        <a href="#" class="list-group-item list-group-item-action bg-light">Meus Corretores</a>
        <router-link to="/confirmbroker" tag="a" class="list-group-item list-group-item-action bg-light">Corretores Cadastrados</router-link>
        <router-link to="/createimmobile" tag="a" class="list-group-item list-group-item-action bg-light">Cadastrar Imóvel</router-link>
      </div>
    </div> -->
    <!-- Page Content -->
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <!-- About Me Box -->
            <div class="card card-primary">
              <div class="card-header text-white bg-info pb-0 m-3">
                <div class="row">
                  <h3 class="card-title col-md-10">Editar perfil</h3>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body">
              
               <!--  <form class="form-horizontal"> -->
                    <!-- <fieldset> -->
                    <div class="panel panel-primary">
                    
                    <div class="col-md-12 mt-0 p-0 mb-0">
                        <div id="box-file" style="width:150px; height:150px">   
                            <file-pond
                            name="immobile-images"
                            ref="pond"
                            maxFiles="1"
                            labelFileLoading="Carregando..."
                            stylePanelLayout="circle"
                            labelIdle='<span class="filepond--label-action"><i class="fas fa-camera-retro"></i><br>Clique aqui caso deseje mudar a foto do seu perfil.</span>'
                            v-bind:allow-multiple="false"
                            accepted-file-types="image/jpeg, image/png"
                            v-model="myFile"/>
                        </div>
                    </div>
                       
                    <div class="panel-body">
                    
                    <div class="row mt-4">
                        <div class="form-group ml-4">
                            
                            <small class="text-danger"><span>*</span>Para editar os campos nome e cpf entre em contato com o administrador.</small>
                        </div>
                        
                        <!-- Text input-->
                        <div class="form-group col-md-8">
                            <label class="col-md-12 control-label" for="name">Nome</label>  
                            <div class="col-md-12">
                                <input id="name" name="name"  v-model="name" class="form-control input-md" type="text">
                            </div>
                        </div>

                        <!-- Text input-->
                        <div class="form-group col-md-4">
                            <label class="col-md-12 control-label" for="cpf">CPF</label>  
                            <div class="col-md-12">
                                <input id="cpf" name="cpf" v-model="cpf" class="form-control input-md" readonly="readonly" type="text">
                                <small></small>
                            </div>
                        </div>
                    </div>
                    
                    <div class="row">
                        <!-- Telephone text-->
                        <div class="form-group col-md-6">
                            <label class="col-md-12 control-label" for="telephone">Telefone</label>
                            <div class="col-md-12">
                                <div class="input-group">
                                    <span class="input-group-addon"><i class="fas fa-mobile-alt"></i></span>
                                    <input id="telephone" name="telephone" v-model="telephone" v-mask="'(##) #####-####'" class="form-control" type="text" maxlength="15">
                                </div>
                                <small class="text-danger" v-if="validate_telephone">É obrigatório incluir o telefone com DDD e número com 9 dígitos</small>
                            </div>
                        </div>

                        <!-- Cep text-->
                        <div class="form-group col-md-6">
                            <label class="col-md-12 control-label" for="cep">CEP</label>
                            <div class="col-md-12">
                                <div class="input-group">
                                    <span class="input-group-addon"><i class="fas fa-map-marker-alt"></i></span>
                                    <input id="cep" name="cep" v-model="cep" v-mask="'#####-###'" class="form-control" type="text" maxlength="9">
                                </div>
                                <small class="text-danger" v-if="validate_cep">É obrigatório incluir o cep de 8 dígitos.</small>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <!-- Endereco text-->
                        <div class="form-group col-md-3">
                            <label class="col-md-12 control-label" for="tipo de logradouro">Tipo de Logradouro</label>
                            <div class="col-md-12">
                                <div class="input-group">
                                    <treeselect v-model="public_place_type" :multiple="false" :options="options" :placeholder="'Selecione...'" />
                                </div>
                                 <small class="text-danger" v-if="validate_tipo_logradouro">É obrigatório informar o tipo de logradouro.</small>
                            </div>
                        </div>
                        <div class="form-group col-md-4">
                            <label class="col-md-12 control-label" for="public_place">Logradouro</label>
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input id="public_place" name="public_place" v-model="public_place" class="form-control" type="text">
                                </div>
                                 <small class="text-danger" v-if="validate_logradouro">É obrigatório informar o logradouro.</small>
                            </div>
                        </div>
                            
                        <div class="form-group col-md-2">
                            <label class="col-md-12 control-label" for="number">Nº</label>
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input id="number" name="number" v-model="number" class="form-control"  type="text">
                                </div> 
                            </div>
                        </div>
                        
                        <div class="form-group col-md-3">
                            <label class="col-md-12 control-label" for="neighborhood">Bairro</label>
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input id="neighborhood" name="neighborhood" v-model="neighborhood" class="form-control" type="text">
                                </div>
                                <small class="text-danger" v-if="validate_neighborhood">É obrigatório informar o bairro.</small> 
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <!-- Endereco text-->
                        <div class="form-group col-md-4">
                            <label class="col-md-12 control-label" for="city">Cidade</label>
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input id="city" name="city" v-model="city" class="form-control" type="text">
                                </div>
                                 <small class="text-danger" v-if="validate_city">É obrigatório informar a cidade.</small> 
                            </div>
                        </div>
                            
                        <div class="form-group col-md-4">
                            <label class="col-md-12 control-label" for="state">Estado</label>
                            <div class="col-md-12">
                                <div class="input-group">
                                    <treeselect v-model="state" :multiple="false" :options="ufs" :placeholder="'Selecione...'" />
                                </div> 
                                <small class="text-danger" v-if="validate_state">É obrigatório informar o estado.</small>
                            </div>
                        </div>

                        <div class="form-group col-md-4">
                            <label class="col-md-12 control-label" for="state">País</label>
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input id="country" name="country" v-model="country" class="form-control"  type="text">
                                </div> 
                                <small class="text-danger" v-if="validate_country">É obrigatório informar o país.</small>
                            </div>
                        </div>
                    </div>

                    <!-- Se for corretor, bloco para enviar documentos pessoais -->
                    <h5 v-if="isRealtor || isSeller" class="text-secondary text-center mt-4 mb-4">
                        Documentos para <span v-if="isRealtor">Corretores</span> <span v-if="isSeller">Proprietários</span> de Imóveis
                    </h5>
                    <div v-if="isRealtor || isSeller" class="row">
                        <div v-if="!isSeller" class="col">
                            <label for="Creci">CRECI</label>
                            <input type="file" @change="getFileCreci" class="form-control-file" required ref="fileCreci">
                            <div class="border rounded-sm text-center p-3">
                                <img v-if="srcFotoCreci" :src="srcFotoCreci" width="140" height="140" />
                                <img v-else src="//placehold.it/140?text=IMAGE" class="img-fluid" id="fotoCreci" />
                            </div>
                            <small class="text-danger" v-if="validate_foto_creci">É obrigatório para corretores(a) informar a imagem do Creci.</small>
                        </div>
                        <div class="col">
                            <label for="Rg">Documento com foto</label>
                            <input type="file" @change="getFileRG" class="form-control-file" required ref="fileRG">
                            <div class="border rounded-sm text-center p-3">
                                <img v-if="srcFotoRG" :src="srcFotoRG" width="140" height="140" />
                                <img v-else src="//placehold.it/140?text=IMAGE" class="img-fluid" id="fotoRG" />
                            </div>
                        </div>
                        <div class="col">
                            <label for="comprovante de endereço">Comprovante de endereço</label>
                            <input type="file" @change="getFileEnd" class="form-control-file" required ref="fileEnd">
                            <div class="border rounded-sm text-center p-3">
                                <img v-if="srcFotoEnd" :src="srcFotoEnd" width="140" height="140" />
                                <img v-else src="//placehold.it/140?text=IMAGE" class="img-fluid" id="fotoEnd" />
                            </div>
                        </div>
                    </div>
                    <!-- Button (Double) -->
                    <div class="mt-5 mb-5">
                        <label class="col-md-2 control-label" for="Cadastrar"></label>
                        <div class="col-md-8">
                            <button class="btn btn-danger" @click="cancelar()">Cancelar</button>
                            <button @click="save()" class="btn btn-success ml-3">Salvar</button>
                        </div>
                    </div>

                    </div>
                    </div>


                    <!-- </fieldset> -->
                <!-- </form> -->

              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>
        </div>
        <!-- /.row -->
      </div><!-- /.container-fluid -->

    </div>
    <!-- /#page-content-wrapper -->
</div>
</template>

<script>
import SiteHeader from '../../components/SiteHeader.vue';

import axios from 'axios'

//vuex
import { mapState, mapGetters } from 'vuex'
//vue-the-mask
import {mask} from 'vue-the-mask'
// Import do component de loading
import Loading from 'vue-loading-overlay'
//sweet alert
import swal from 'sweetalert'
// Import Vue FilePond
import vueFilePond from 'vue-filepond';
// Import FilePond styles
import 'filepond/dist/filepond.css';
// Import image preview plugin styles
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
// import the component
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
//constantes
import {constantes} from '../../consts'
// Create component
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);

export default {
    name: 'ProfileEdit',
    directives: {mask},
    components: {
        SiteHeader,
        Loading,
        FilePond,
        Treeselect
    },
    data(){
        return {
            endpoint_user: process.env.VUE_APP_USER,    
            endpoint_user_by_id: process.env.VUE_APP_USER_BY_ID,    
            endpoint_edit: process.env.VUE_APP_USER_EDIT,
            endpoint_images: process.env.VUE_APP_IMAGES,
            //Variaveis para armazenar as fotos dos imoveis e uma logica de validacao
            myFile: [],
            image: [],
            erro_photo: false,

            id: null,
            name: '',
            cpf: null,
            telephone: null,
            cep: null,
            public_place_type: null,
            public_place: null,
            number: null,
            neighborhood: null,
            city: null,
            state: null,
            country: null,
            srcFotoCreci: null,
            srcFotoRG: null,
            srcFotoEnd: null,

            isLoading: false,
            isRealtor: false,
            isSeller: false,
            fullPage: true,
            arrayDeRole: [],

            validate_telephone: false,
            validate_cep: false,
            validate_logradouro: false,
            validate_tipo_logradouro: false,
            validate_neighborhood: false,
            validate_city: false,
            validate_state: false,
            validate_country: false,
            validate_foto_creci: false,

            //options do vuetreselect
            options: constantes.tipo_de_logradouros,
            ufs: constantes.estados
        }
    },
    
    async mounted() {
        try {
            this.isLoading = true
            let dados = await this.user
            console.log('dados.includes(documentos)',dados)
            if(dados && dados != null && dados != undefined){
                this.isLoading = false
                //1º verifica o tipo de usuário logado, se for corretor então traz as imagens dos documentos dele
                this.arrayDeRole = JSON.parse(dados.user.role);
                //se for corretor, pega também as imagens dos documentos pessoais 
                if(this.arrayDeRole.includes("ROLE_REALTOR")){
                    this.isRealtor = true
                    if(dados.documentos.length > 0){
                        this.srcFotoCreci = this.endpoint_images + dados.documentos[0].creci_image
                        this.srcFotoRG = this.endpoint_images + dados.documentos[0].rg_image
                        this.srcFotoEnd = this.endpoint_images + dados.documentos[0].file_end_image
                    }
                }
                //se for vemdedpr, pega também as imagens dos documentos pessoais 
                if(this.arrayDeRole.includes("ROLE_SELLER")){
                    this.isSeller = true
                    if(dados.documentos){
                        this.srcFotoRG = this.endpoint_images + dados.documentos[0].rg_image
                        this.srcFotoEnd = this.endpoint_images + dados.documentos[0].file_end_image
                    }else{
                        console.log('não tem documentos')
                    }
                }
                //2º preenche o formulário de edição com os dados do usuário logado
                //mapeia os dados do usuário 
                this.id = dados.user.id 
                this.name = dados.user.name 
                this.cpf = dados.user.profile.cpf
                this.telephone = dados.user.profile.telephone 
                this.cep = dados.user.profile.cep 
                this.public_place = dados.user.profile.public_place
                this.number = dados.user.profile.number
                this.neighborhood = dados.user.profile.neighborhood
                this.city = dados.user.profile.city
                this.state = dados.user.profile.state
                this.country = dados.user.profile.country   
                //3º salva a edição dos dados do usuário
            }
        } catch (error) {
            this.isLoading = false;
            swal('Erro', `Não foi possível localizar os dados do usuário ${error}`, 'error').then(() => {
                //encerra o loading
            })
        }            
    },
    computed: {
    ...mapGetters(['getUser']),
    ...mapState(['user']),

    },
    methods: {
        cancelar: function(){
            this.$router.push('/myprofile');
        },
        save () {
            try {
                this.isLoading = true
                if(this.telephone.length <= 14){
                this.validate_telephone = true;
                } else if(this.cep.length <= 8){
                    this.validate_cep = true;
                } else if(this.public_place_type == '' || this.public_place_type == null){
                    this.validate_tipo_logradouro = true;
                } else if(this.public_place == ''){
                    this.validate_logradouro = true;
                } else if(this.neighborhood == ''){
                    this.validate_neighborhood = true;
                } else if(this.city == ''){
                    this.validate_city = true;
                } else if(this.state == ''){
                    this.validate_state = true;
                } else if(this.country == ''){
                    this.validate_country = true;
                }            
                else{
                    //instancia objeto de dados
                    var dados = new FormData()
                    var user_edit = this.endpoint_edit + this.id

                    // se for corretor então pega as fotos de documentos RG, CRECI e Comprovante endereço
                    if(this.arrayDeRole.includes("ROLE_REALTOR")){
                        //verifica se colocou as fotos
                        if( this.$refs.fileCreci.files[0] && this.$refs.fileRG.files[0] && this.$refs.fileEnd.files[0] ){
                            //imagens
                            this.fileCreci = this.$refs.fileCreci.files[0]
                            this.fileRG = this.$refs.fileRG.files[0]
                            this.fileEnd = this.$refs.fileEnd.files[0]
                            //passa a foto da carteira CRECI
                            dados.append('fileCreci', this.fileCreci)    
                            //passa a foto do RG
                            dados.append('fileRG', this.fileRG)
                            //passa a foto do comprovante de endereço
                            dados.append('fileEnd', this.fileEnd)
                        }
                    }

                    // se for vendedor então pega as fotos de documentos RG e Comprovante endereço
                    if(this.arrayDeRole.includes("ROLE_SELLER")){
                        //verifica se colocou as fotos
                        if( this.$refs.fileRG.files[0] && this.$refs.fileEnd.files[0] ){
                            //imagens
                            this.fileRG = this.$refs.fileRG.files[0]
                            this.fileEnd = this.$refs.fileEnd.files[0]
                            //passa a foto do RG
                            dados.append('fileRG', this.fileRG)
                            //passa a foto do comprovante de endereço
                            dados.append('fileEnd', this.fileEnd)
                        }
                    }
                    console.log('this.myFile', (this.myFile != '')?true:false)
                    
                    if(this.myFile != ''){
                        this.image = this.$refs.pond.getFiles()[0].file
                        dados.append('profile[photograph]', this.image)
                    }
                    dados.append('name', this.name)
                    dados.append('profile[cpf]', this.cpf)
                    dados.append('profile[telephone]', this.telephone)
                    dados.append('profile[cep]', this.cep)
                    dados.append('profile[public_place_type]', this.public_place_type)
                    dados.append('profile[public_place]', this.public_place)
                    dados.append('profile[number]', this.number)
                    dados.append('profile[neighborhood]', this.neighborhood)
                    dados.append('profile[city]', this.city)
                    dados.append('profile[state]', this.state)
                    dados.append('profile[country]', this.country)
                    //envia os dados do form para a API para salvar o usuário
                    axios.post(
                        user_edit,
                        dados,
                        { headers: {'Content-type': 'application/x-www-form-urlencoded',
                                    'Accept': 'application/json',
                                    'Authorization': 'Bearer ' + localStorage.getItem('token')}}
                        ).then( () => {
                            this.isLoading = false;
                            swal('Sucesso', 'Perfil editado com sucesso.', 'success').then( () => {
                                    this.$router.push('/myprofile');
                                })
                        }).catch( (error) => {
                            this.isLoading = false;
                            swal('Parece que algo deu errado', error.message, 'error').then( () => {
                        })
                    })
                
                }
            } catch (error) {
                this.isLoading = false
                swal('Erro', `Não foi possível localizar os dados do usuário ${error}`, 'error').then(() => {});
            }
            
        },
        getFileCreci () {
            var f = this.$refs.fileCreci.files
            this.srcFotoCreci = URL.createObjectURL(f[0])
        },            
        getFileEnd () {
            var e = this.$refs.fileEnd.files
            this.srcFotoEnd = URL.createObjectURL(e[0])
        },            
        
        getFileRG () {
            var r = this.$refs.fileRG.files
            this.srcFotoRG = URL.createObjectURL(r[0])
        },
    },
}
</script>

<style scoped>
/* Importando o bootstrap 4 */
/* @import '../../assets/styles/bootstrap4/bootstrap.min.css'; */

</style>