<template>
  <div> 
    <site-header/>
        <div class="d-flex" id="wrapper"> 
        <!-- Sidebar -->
        <div class="bg-light border-right" id="sidebar-wrapper">
          <Sidebar></Sidebar>

          
        </div>
        <!-- /#sidebar-wrapper -->

        <!-- Page Content -->
        <div id="page-content-wrapper">
          

          <div class="containerimoveis">
            <div class="containertitulo">
              <h1>Lista de imóveis da plataforma</h1> 
            </div>
            <div class="containerselect">
              <h3>Filtros: </h3>
              <div class="selectdiv form-group">
                <select class="form-control-sm" id="selectfiltro" v-model="filter.type_id">
                  <option selected>Tipo de imóvel</option>
                  <option value="1">Todos</option>
                        <optgroup label="Residencial">
                          <option value="2">Apartamento</option>                    
                          <option value="1">Casa</option>
                          <option value="3">Chácara</option>
                          <option value="4">Cobertura</option>
                          <option value="5">Duplex</option>
                          <option value="8">Estúdio</option>          
                          <option value="10">Flat</option>
                          <option value="12">Garagem</option>
                          <option value="13">Kitnet</option>
                          <option value="14">Loft</option>
                          <option value="16">Lote</option>            
                          <option value="21">Sobrado</option>
                          <option value="22">Terreno</option>
                        </optgroup>
                        <optgroup label="Comercial">
                          <option value="1">Casa (comercial)</option>
                          <option value="6">Edifício</option>
                          <option value="7">Escritorio</option>
                          <option value="11">Galpão</option>
                          <option value="15">Loja</option>
                          <option value="17">Negócio</option>
                          <option value="19">Prédio</option>
                          <option value="18">Ponto Comercial</option>
                          <option value="16">Lote (comercial)</option>
                        </optgroup>
                        <optgroup label="Rural">
                          <option value="9">Fazenda</option>
                          <option value="20">Sítio</option>
                      </optgroup>                   
                </select>
                <select class="form-control-sm ml-2" id="selectfiltro" v-model="filter.availability">
                  <option value="" disabled selected>Compra ou Aluguel</option>
                  <option value="">Todas</option>
                  <option value="Vender">Comprar</option>
                  <option value="Alugar">Alugar</option>
                </select>
                <!-- <select class="form-control-sm ml-2" id="selectfiltro" v-model="filter.minPrice">
                  <option value="" disabled selected>Preço Mínimo</option>
                  <option value="Todos">Todos</option>
                  <option value="50k">R$ 50.000,00</option>
                  <option value="100k">R$ 100.000,00</option>
                  <option value="200k">R$ 200.000,00</option>
                  <option value="300k">R$ 300.000,00</option>
                  <option value="500k">R$ 500.000,00</option>
                  <option value="1m">R$ 1000.000,00</option>
                  <option value="10m">R$ 10.000.000,00</option>
                </select>
                <select class="ml-2" id="selectfiltro" v-model="filter.maxPrice">
                  <option value="" disabled selected>Preço Máximo</option>
                  <option value="Todos">Todos</option>
                  <option value="50k">R$ 50.000,00</option>
                  <option value="100k">R$ 100.000,00</option>
                  <option value="200k">R$ 200.000,00</option>
                  <option value="300k">R$ 300.000,00</option>
                  <option value="500k">R$ 500.000,00</option>
                  <option value="1m">R$ 1000.000,00</option>
                  <option value="10m">R$ 10.000.000,00</option>
                </select> -->
                  <button
                    class="selectbtn ml-4"
                    @click="filtrar"
                  > Filtrar </button>
                </div>
              </div>
              <div v-if="isloading" class="spinner-border text-success mb-3 mt-3" role="status" style="margin: 0 auto;">
                <p class="sr-only mb-5">Carregando...</p>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <immobile-admin-box v-for="(a, index) in listaDeImoveis.data" :immobilebox="a" :key="index"/>
                </div>
                <div class="text-center">
                  <paginate :source="pagination" @navigate="navigate"></paginate>
                </div>
              </div>
        </div>
      </div>

  </div>

  </div>
</template>

<script>
let token = localStorage.getItem('token')
/*import { format } from 'date-fns'
import VueSweetalert2 from 'vue-sweetalert2'; //import do sweet alert 2
import swal from 'sweetalert2/dist/sweetalert2.all.min.js'*/
//importa o axios
import axios from 'axios';
//importa o componente de Header
import SiteHeader from '../../components/SiteHeader.vue'
//importar o componente da Sidebar
import Sidebar from '../../components/Sidebar.vue'
import ImmobileAdminBox from '../../components/ImmobileAdminBox.vue'
import { mapActions, mapState } from 'vuex';
// paginação
import Paginate from '../../components/Paginate.vue'

export default {
  components: { SiteHeader, Sidebar, ImmobileAdminBox, Paginate },
  name: 'ImmobileAdmin',
  props: {
    msg: String
  },
  data () {
    return {
      pagination: {},
      endpoint_immobile_index: process.env.VUE_APP_IMMOBILEINDEX,
      endpoint_images: process.env.VUE_APP_IMAGES,
      usuarios: [],
      announcements: [],
      imoveisFiltrados: [],
      immobileslocal: [],
      listaDeImoveis: [],
      filter: {
        type_id: '',
        availability: '',
        minPrice: '',
        maxPrice: '',
        city: '',

      },
      cidade: undefined,
      tipoImovel: undefined,
      precoMaximo: undefined,
      precoMinimo: undefined,
      isloading: true,
      imovelid: 0,
      indeximovel: 0,
      // format,
      // VueSweetalert2,
      // swal
    }
  },
  async created () {
    this.obtemDados(this.endpoint_immobile_index).then( (retorno)=>{
      console.log('retorno', retorno)
      this.listaDeImoveis = retorno
      this.pagination = retorno
      this.isloading = false
    })
  },
  computed: {
    ...mapState(['user','immobiles']),
  },
  methods: {
    ...mapActions({
      suspendeAnuncioImovel: (dispatch, payload, options) => {
        return dispatch('suspendAnnouncementImmobile', payload, options);
      },
      ativaAnuncioImovel: (dispatch, payload, options) => {
        return dispatch('activeAnnouncementImmobile', payload, options);
      },
    }),
    async obtemDados (url) {
        let dados = {};
        
        return new Promise( (resolve, reject) => {
          axios.get(url,
          { headers: {
              'Content-type': 'multipart/form-data',
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + token
              } 
          })
          .then(response => {
              this.isLoading = false
              dados = response.data
              resolve(dados)
              })
          .catch(function (error) {
              console.log(error);
              reject(error)
          })
        }); 
    },
    // filtrar (type_id, city, minPrice, maxPrice ) {
      // this.filter = {
        //   type_id: (type_id !== undefined) ? type_id : '',
      //   availability: '',
      //   minPrice: (minPrice !== undefined) ? minPrice : '',
      //   maxPrice: (maxPrice !== undefined) ? maxPrice : '',
      //   city: (city !== undefined) ? city : '',
      // },
      async filtrar () {
      this.isloading = true
      //monta a query string para a url enviada a API
        const query = Object.keys(this.filter).map( (key) => {
          return `${encodeURIComponent(key)}=${encodeURIComponent(this.filter[key])}`
        }).join('&');
        
        var dados = await axios.get(this.endpoint_immobile_index+'?'+query, 
        { headers: {
              'Content-type': 'multipart/form-data',
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + token
              } 
          }).then(response => {          
            // this.pagination = response
            // this.isloading = false
            this.isLoading = false;
            // return { response: [...response.data.data], pagination: response.data}
            return { response: response.data, pagination: response.data}
          })
          .catch(function (error) {
            console.log(error); 
          })
        this.listaDeImoveis = dados.response
        this.pagination = dados.pagination
    },
    suspenderteste(e) {
      console.log(e.target)
    }, 

    suspender(e, id) {
      var x = 0
      console.log(e.target)
      if (e.target.localName == 'svg') {
        console.log('elemento svg')
        x = e.target.parentElement.id
        console.log(Number(x))
      } else if (e.target.localName == 'path') {
        x = e.target.parentElement.parentElement.id
        console.log('elemento path')
        console.log(Number(x))
        
      } else {
        x = e.target.id
        console.log('elemento i ou button')
        console.log(Number(x))
      } 

      
      this.imovelid = x
      this.suspenderimovel(id)
      
    },
    suspenderimovel(immobile) {
      this.$swal({
        title: `Você está prestes a suspender o imóvel "${immobile.title}"`,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        cancelButtonColor: '#dc3545',
        confirmButtonText: 'Suspender imóvel',
        confirmButtonColor: '#28a745'
      }).then((result) => {
        if (result.isConfirmed) {
          /*
          usuário confirmou a suspensao do anuncio do imovel, sistema
          dispara a action do vuex que implementa a suspensão do 
          anúncio do imóvel na API
          */
          this.suspendeAnuncioImovel(immobile.announcement.id)

        }
      })
    },
    ativarimovel(e, immobile) {
      e
      this.$swal({
        title: `Você está prestes a ativar o imóvel "${immobile.title}"`,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        cancelButtonColor: '#dc3545',
        confirmButtonText: 'Ativar imóvel',
        confirmButtonColor: '#28a745'
      }).then((result) => {
        if (result.isConfirmed) {
          /*
          usuário confirmou a ativacao do anuncio do imovel, sistema
          dispara a action do vuex que implementa a ativação do 
          anúncio do imóvel na API
          */
          this.ativaAnuncioImovel(immobile.announcement.id)

        }
      }) 
    },
    navigate (page) {
      console.log('navigate', page)
      this.loading = true
      axios.get(this.endpoint_immobile_index+'?page='+page, 
        { headers: {
                'Content-type': 'multipart/form-data',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
                } 
        }
      ).then(response => { 
          this.listaDeImoveis = response.data
          this.pagination = response.data
          this.isloading = false
        })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {

      })
    }
  }

}





</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped >
h1,
h2,
h3,
p {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
}

.containertitulo h1 {
  font-size: 1.25rem;
}

.containerselect h3 {
  font-size: 1.25rem;
}

.containerimoveis {
  padding: 25px;
}
.containerimoveis {
  padding: 25px;
}

.containerselect {
  padding: 25px;
  display: flex;
}

#selectfiltro {
  height: 45px;
  width: 150px;
  color: #495057;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: white;
  border: 0.1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.selectbtn {
  background-color: #dc3545;
  border: 1px solid transparent;
  border-color: #dc3545;
  color: white;
  font-weight: 400;
  font-size: 1rem;
  vertical-align: middle;
  line-height: 1.5;
  width: 160px;
  height: 46px;

  text-align: center;
  border-radius: 0.25rem;
}

.selectdiv {
  padding-left: 25px;
}

.containerlistaimoveis {
  padding: 25px;
}

.container {
  text-align: flex-start;
}

.container p {
  font-size: 1rem;
  padding-bottom: 0;
  padding-left: 5px;
  margin-bottom: .5rem;
}

.container h2 {
  font-size: 1.25rem;
  padding-left: 5px;
}
.container h3 {
  font-size: 18px;
  padding-left: 5px;
}

.container a {
  padding-left: 5px;
}

.container .item {
  display: flex;
  padding: 25px;
  width: 1000px;
}

.btnaclista {
  list-style-type: none;
  padding-left: 0px;
  margin-bottom: .5rem;
  margin-top: .9rem;
}

.btnaclista a {
  display: inline-block;
  
}

.btnaclista li {
  display: inline-block;
  padding-right: 5px;
}

.penbtn {
  background-color: white;
  color: #006eff;
  border: 2px solid #006eff;
  border-radius: 5px;
  transition: .3s;
  width: 180px;
  margin-bottom: .5rem;
}

.penbtn:hover {
  color: #0257c7;
  border: 2px solid #0257c7;
}

.banbtn {
  background-color: white;
  color: #dc3545;
  border: 2px solid #dc3545;
  transition: .3s;
  border-radius: 5px;
  width: 180px;
}

.banbtn:hover {
  color: #af2a38;
  border: 2px solid #af2a38;
}

.actbtn {
  background-color: white;
  border: 2px solid #04a339;
  color: #04a339;
  border-radius: 5px;
  margin-bottom: .5rem;
  margin-left: 4.5px;
  width: 180px;
  transition: .3s;
}

.actbtn:hover {
  color: #02852e;
  border: 2px solid #02852e;
}



.container .item .imagem {
  padding-right: 15px;
}

.container .item .imagem img {
  max-width: 300px;
  max-height: 300px;
  height: 220px;
  width: 300px;
  display: flex;
}

.conteudo {
  width: 400px;
}

.containerac {
  width: 450px;
  text-align: right;
  margin-right: 80px;
}

.listaac {
  list-style-type: none;
}

.listaac li {
  padding-bottom: 8px;
  padding-top: 6px;
  font-size: 18px;
}

   .listaac {
       list-style-type: none;
   }
   
   .listaac li{
       padding-bottom: 8px;
       padding-top: 6px;
       font-size: 18px;
       
   }

   .pen i {
     color: #006eff;
   }

   .pen a {
     display: inline-block;
     width: 24px;
     height: 0px;
   }

   .pen i:hover {
     color: #0257c7;
     cursor: pointer;
   }

    path {
      width: 0;
      height: 0;
    }

   .ban i {
     color: red;
     
   }

   .ban i:hover {
     color: rgb(141, 0, 0);
     cursor: pointer;
   }
   
   .activate i {
     color: green;
     
   }

   .activate i:hover {
     color: rgb(4, 163, 57);
     cursor: pointer;
   }

    

  

/* Importando o bootstrap 4 */
@import "../../assets/styles/bootstrap4/bootstrap.min.css";

/* Importando o estilo da pagina*/
@import "../../assets/styles/simple_sidebar.css";
</style>