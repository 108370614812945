<template>
  <div>
    <site-header />
    <div class="box ml-4 mr-4">
      <div class="row">
        <div class="col-md-3">
          <div class="card mt-4">
            <!-- Loading -->
            <loading
              :active.sync="isLoading"
              :is-full-page="fullPage"
              :can-cancel="false"
            ></loading>
             <!-- fim Loadign -->
            <!--
              ################# 
              ### Conversas ###
              ################# 
              -->
              <div class="list-group">
                <!-- <a
                  v-on:click="messagesUserSelected(usrChat)"
                  v-for="(usrChat,index) in listUsersChat"
                  :class="{active: userActiveStyle(usrChat)}"
                  :userFirstCurrent="setFirstCurrentUser"
                  :key="index" 
                  class="list-group-item list-group-item-action flex-column"
                  style="cursor:pointer"
                >  -->
                <a
                  v-on:click="messagesUserSelected(usrChat)"
                  v-for="(usrChat,index) in listaDeConversas"
                  :key="index" 
                  class="list-group-item list-group-item-action flex-column"
                  :class="(usrChat && userActive && userActive === usrChat.usuario.id && announcementActive && announcementActive === usrChat.objetoanuncio.anuncio.id)?'usuarioativo': ''"
                  style="cursor:pointer"
                > 
                  <!-- div se usuário logado for o responsável pelo imovel -->
                  <!-- <div v-if="(usrChat.id === userLogged.user.id)" class="d-flex flex-row">
                    <div class="p-2">
                      <img
                        class="rounded-circle"
                        :src="endpoint_images + usrChat.photograph"
                        alt=""
                        width="30" 
                        height="30"
                      >
                    </div>
                    <div class="d-flex flex-column p-1">
                      <strong>{{ usrChat.name }}</strong>
                      <span>{{ usrChat.city}} - {{ usrChat.state }}</span>
                      <small>{{ usrChat.created_at}}</small>
                    </div>
                    <div v-if="notification" class="circle-notification"> </div>
                  </div> -->
                  
                  <!-- div se usuário logado NÃO for o responsável pelo imovel -->
                  <!-- <div v-if="(usrChat.to !== userLogged.user.id)" class="d-flex flex-row"> -->
                  <div class="d-flex flex-row">
                    <div class="p-2">
                      <img
                        class="rounded-circle"
                        :src="endpoint_images + usrChat.usuario.foto"
                        alt=""
                        width="30" 
                        height="30"
                      >
                    </div>
                    <div class="d-flex flex-column p-1">
                      <span class="nome">{{ usrChat.usuario.name }}</span>
                      <!-- <span>{{ usrChat.city}} - {{ usrChat.state }}</span> -->
                      <small>{{ usrChat.datacriacao | dataFormatada}}</small>
                    </div>
                    <div class="p-1">
                      <svg class="bd-placeholder-img rounded-circle" width="10" height="10" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false" role="img" aria-label="Completely round image: 10x10"><title></title><rect width="100%" height="100%" fill="#008000"></rect><text x="50%" y="50%" fill="#dee2e6" dy=".3em"></text></svg>
                    </div>
                  </div>
                </a>        
          </div>
            <!-- ### Fim - Conversas ### -->
          </div>
        </div>
        <div class="col-md-5 mt-4">
          <!-- 
            #################
            ### Mensagens ###
            ################# 
          -->
          <div class="card mensagens">
              <div ref='messageDisplay' id="autoscroll" class="card-body d-flex flex-column overflow-auto" style="max-height:360px">
                <div
                  
                  class="d-flex flex-row"
                  v-for='(msg,index) in mensagens'
                  :key="index"
                >
                  <div 
                    v-if="mensagens !== null"
                    class="p-2 text-center" 
                    style="margin-rigth:2px"
                  >
                    <img
                      v-if="msg.photograph"
                      class="rounded-circle"
                      :src="endpoint_images + msg.photograph"
                      width="30"
                      height="30"
                    >
                    <img
                      v-else
                      class="rounded-circle"
                      src="https://png.pngtree.com/png-vector/20190710/ourmid/pngtree-user-vector-avatar-png-image_1541962.jpg"
                      width="30"
                      height="30"
                    >
                  </div>
                  <div v-else> Carregando... </div>
                  <div class="p-2">
                    <dl>
                      <dt>{{msg.name}}</dt>
                      <dd>{{ msg.message }}</dd>
                      <dd><span style="font-size: 10px">{{msg.created_at}}</span></dd>
                    </dl>
                  </div>
                </div>
            </div>
            <div class="d-flex flex-row">
                <div class="p-2 mr-2">
                  <textarea
                    v-model="mensagem"
                    @keydown.enter="enviar"
                    placeholder="Insira uma mensagem..."
                    class="form-control ml-3"
                    type="text"
                    rows="2"
                  ></textarea>
                </div>
                <div class="p-2">
                  <button
                    @click="enviar"
                    class="btn btn-success mt-2"
                  >Enviar</button>
                </div>
            </div>
          </div>
        <!-- ### Fim - Mensagens ### -->
        </div>
        <div class="col-md-4 mt-4">
          <div class="card">
            <div class="card-body">
              <!-- 
                ######################
                ### Imovel do Chat ###
                ######################
                -->
              <div v-if="immobileOfTheChat && immobileOfTheChat !== null" class="containertemplate">
                <div  class="containerimovelchat">
                    <div class="imovel">
                        <div class="imoveltitulo">
                            
                        </div>
                        <div class="imovelimg">
                            <img width="100%" height="100%" :src="endpoint_images + immobileOfTheChat.fotosanuncio[0].photo">
                        </div>
                        <div class="imoveltxt">
                            <h5 style="padding-bottom: 5px">{{ immobileOfTheChat.anuncio.immobile.description }}</h5>
                            <p>{{ immobileOfTheChat.price | currency('R$', 2, {thousandsSeparator: '.', decimalSeparator: ',', spaceBetweenAmountAndSymbol: true }) }}</p>
                            <p>{{ immobileOfTheChat.city }} - {{ immobileOfTheChat.state }}</p>
                        </div>
                        <div class="imovelbtn">
                            <router-link :to="'/immobileinfo/'+immobileOfTheChat.id" tag="button" class="infobtn">Mais informações</router-link>
                        </div>
                        
                    </div>
                </div>
            </div>
            <!-- ### Fim - Imóvel Chat ### -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SiteHeader from '../../components/SiteHeader.vue'
// Import do component de loading
import Loading from 'vue-loading-overlay'
import { mapActions, mapState } from 'vuex'
import moment from 'moment'
import { required } from 'vuelidate/lib/validators'
import Swal from 'sweetalert2'
import axios from 'axios'


export default {
  components: { SiteHeader, Loading },
  name: 'MessagesChatAdmin',
  data () {
    return {
      endpoint_images: process.env.VUE_APP_IMAGES,
      userActive: {},
      announcementActive: {},
      listaDeConversas: null,
      mensagem: null,
      mensagens: [],
      immobileOfTheChat: null,
      isLoading: false,
      fullPage: false,
    }
  },
  validations: {
    mensagem: { required }
  },
  filters: {
    dataFormatada : function(v) {
      if(v){
        return moment(String(v)).format('DD/MM/YYYY hh:mm')
      }
    }
  },
  async mounted () {
   try{
     this.isLoading = true;
     console.log('this.user.user', this.user.user)
     this.listaDeConversas = await this.carregaConversas(this.user.user)
     this.isLoading = false;

     //ouve o canal websocket de comunicação
      var that = this;
      window.Echo.channel(`chat`)
        .listen('.SendMessage', async (m) => {
          if(that.userActive && that.userActive === m.message.from){
            //o emitente é igual ao usuário da conversa, faz o push nas mensagens
            var novamensagem = {
              id: m.message.id,
              from: m.message.from,
              to: m.message.to,
              name: m.message.name,
              announcement: m.message.announcement,
              city: m.message.city,
              created_at: m.message.created_at,
              message: m.message.message,
              photograph: m.message.photograph,
              read: m.message.read,
              state: m.message.state
            }
            await that.mensagens.push(novamensagem)
            that.scrollToBottom()
          }else{
            //notificação
            const user = this.listaDeConversas.filter( (u) => {  
              if(u.id === m.message.from){
                return u
              }
            })
            if(user){
              console.log('notifica o usuario')
            }
          }
        })
   }catch(error){
     //exibe erro na tela
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Não localizou a lista de conversas do chat!',
      })
   }
  },
  computed: {
    ...mapState({
      user: state => state.user,
      // listUsersChat: state => state.listUsersChat,
      // initiallistConversationsChat: state => state.initiallistConversationsChat,
    }),
  },
  methods: {
    ...mapActions({
      obtemConversas: 'obtemConversas'
    }),
    async carregaConversas (user){
      var varToken = localStorage.getItem('token')
      return new Promise( (resolve, reject) => {
          axios({
            method: 'get',
            url: process.env.VUE_APP_CHAT_OBTEM_CONVERSAS_USUARIO + user.id,
            headers: {
              Authorization: 'Bearer ' + varToken
            }
          }).then( (response)=> {
            console.log('response', response.data)
            resolve(response.data)
          }).catch( (error)=>{ 
            reject(error) 
          })
        })
    },
    async messagesUserSelected (usrChat){
      //carrega as mensagens do usuário selecionado
      this.mensagens = await this.carregaMensagens(usrChat)
      //estilo no usuário ativo do chat
      this.userActive = await usrChat.usuario.id
      this.announcementActive = await usrChat.objetoanuncio.anuncio.id
      //rola as mensagens para baixo exibindo a última mais recente
      await this.scrollToBottom()
      //carrega o imóvel
      this.carregaImovel(usrChat)
    },
    async enviar () {
      this.$v.mensagem.$touch()
      //verifica se mensagem não está vazia
      if (!this.$v.mensagem.$invalid) {
        /*
          *obtém o tamanho da lista de usuários do chat, se a lista for maior do 
          que 1 usuário, então precisa selecionar o usuário para depois iniciar o chat
        */
        
        /*
        *aqui o usuário logado é o ANUNCIANTE
        *NÃO selecionou nenhum usuário para o chat
        *então deve por padrão selecionar o primeiro usuário da lista
        *que será o destinatário e o emissor o anunciante logado
        */     
          if(this.userActive !== undefined || this.userActive !== null){
            await this.$store.dispatch('addNewMessageChat', {
              fromUser: this.user.user.id,
              toUser: this.userActive,
              message: this.mensagem,
              announcementId: this.immobileOfTheChat.anuncio.id,
              immobileId: this.immobileOfTheChat.anuncio.immobile_id,
            })
            const usrChat = {
              objetoanuncio: {
                anuncio: {
                  id: this.immobileOfTheChat.anuncio.id
                }
              },
              usuario: {
                id: this.userActive
              }
            }
            this.mensagens = await this.carregaMensagens(usrChat)
          }else{
            //exibe erro na tela
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'É necessário preencher a mensagem!',
            })
          }
        this.mensagem = ''
        this.scrollToBottom()
      } else {
        //exibe erro na tela
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'É necessário preencher a mensagem!',
        })
      }
    },
    carregaMensagens(usrChat) {
      var varToken = localStorage.getItem('token')
      return new Promise( (resolve, reject) => {
          axios({
            method: 'get',
            url: process.env.VUE_APP_INITIAL_CHAT + 
            usrChat.objetoanuncio.anuncio.id +'/' + 
            usrChat.usuario.id +'/' +
            this.user.user.id,
            headers: {
              Authorization: 'Bearer ' + varToken
            }
          }).then( (response)=> {
            console.log('resposta mensagens do usuário com o usuário logado', response.data)
            resolve(response.data)
          }).catch( (error)=>{ 
            reject(error) 
          })
      })
    },
    carregaImovel(usrChat) {
      this.immobileOfTheChat = usrChat.objetoanuncio
    },
    scrollToBottom () {
      if(this.mensagens.length > 2){
        setTimeout( function() {
          const element = document.querySelector("#autoscroll");
          element.scrollTop = element.scrollHeight;
        },1000);
          
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='css' scoped>
.usuarioativo {
    color: #fff;
    background-color: #c5e9ce;
}
.usuarioativo:hover {
    color: #fff;
    background-color: #a0e4b1;
}
.nome {
  font-size: 10px;
  font-family: 'Roboto';
}

/** botão mais informações sobre o imóvel */
.infobtn {
  border: 1px solid #28a745;
  background-color: white;
  width: 250px;
  height: 40px;
  
  border-radius: 5px;
  color: #28a745;
  font-size: 22px;
}

.infobtn:hover {
  border: 1px solid #1f8036;
  color: #1f8036;
}

@media screen and (min-width: 1024px) {
  .mensagens {
    height: 490px;
  }
}

@media screen and (max-width: 800px) {
  .mensagens {
    height: 150px;
  }
}

@media screen and (max-width: 600px) {
  .mensagens {
    height: 100px;
  }
}
</style>