import Vue from 'vue'
import Router from 'vue-router'

import Front from './views/common/Front';
import FrontComMapa from './views/common/FrontComMapa';
import Anuncie from './views/common/Anuncie';
import CommonUserPanel from './views/common/CommomUserPanel';
import MyProfile from './views/common/MyProfile';
import Login from './views/common/Login';
import Signup from './views/common/Signup';
import Favorite from './views/common/Favorite';
import MyImmobile from './views/common/MyImmobile';
import MessagesChat from './components/chat/MessagesChatAdmin';
import ConfirmBroker from './views/admin/ConfirmBroker';
import ShowProfileRealtorForAdmins from './views/admin/ShowProfileRealtorForAdmins';
import ShowProfileUserForAdmins from './views/admin/ShowProfileUserForAdmins';
import ListAllUsers from './views/admin/ListAllUsers';
import ListBuyers from './views/admin/ListBuyers';
import ListSellers from './views/admin/ListSellers';
import ListRealtors from './views/admin/ListRealtors';
import ImmobileInfo from './views/common/ImmobileInfo';
import CreateImmobile from './views/common/CreateImmobile';
import ProfileEdit from './views/common/ProfileEdit';
import ForgotPassword from './views/common/ForgotPassword';
import ResetPassword from './views/common/ResetPassword';
// import ChatWithBuyer from './views/realtor/ChatWithBuyer';
//↓↓ Protótipo novo do chat
import Chat from './components/chat/Chat';
//↓↓ Importando a nova página de administração de imóveis
import ImmobileAdmin from './views/admin/ImmobileAdmin';
//↓↓ Importando a nova página de administração de imóveis
import InfoAdmin from './views/admin/InfoAdmin';
//↓↓ Tela de edição de imóvel
import EditImmobile from './views/admin/EditImmobile.vue';

//vendedor de imovel
import SellerStepByStep from './views/seller/SellerStepByStep';
//realtor
import RealtorStepByStep from './views/realtor/RealtorStepByStep';
// import RealtorSignup from './views/realtor/RealtorSignup';
import RealtorLogin from './views/realtor/RealtorLogin';
//confirmation code
import ConfirmationCode from './views/common/ConfirmationCode';
//teste mapa
import MapaTeste from './views/common/MapaTeste';
//error404
import Error404 from './views/utils/Error404';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL, 
    routes: [
        /**  
         * Rotas Comuns
         */ 
        { path: '*', name: 'error404', component: Error404,  meta: { requireAuth: false } } ,
        //Login
        { path:'/login', name:'Login', component:Login },
        //Home
        { path:'/', name:'Front', component:Front },
        //Home com Mapa
        { path:'/mapa', name:'FrontComMapa', component:FrontComMapa },
        //Mapa de TESTE
        { path:'/mapa-teste', name:'MapaTeste', component:MapaTeste },
        //Anunciar Imóveis
        { path:'/anuncie', name:'Anuncie', component:Anuncie },
        //Registro de usuário
        { path:'/register', name:'Signup', component:Signup },
        //rota para página de lembrete de senha
        { path:'/forgot', name:'ForgotPassword', component:ForgotPassword },
        //rota para página de reset de senha
        { path:'/reset/:token', name:'ResetPassword', component:ResetPassword },
        //Código de confirmação
        { path:'/confirmation', name:'confirmationcode', component:ConfirmationCode      },
        
        /*** 
         * ########## rotas de Vendedor e Comprador ########## *
         * */
        //dashboard
        { path:'/dashboard', name:'CommonUserPanel', component:CommonUserPanel, meta: {requiresAuth: true, grants: ['ROLE_CLIENT', 'ROLE_SELLER', 'ROLE_REALTOR', 'ROLE_ADMIN']} },        
        //cadastrar imóvel
        { path:'/createimmobile', name: 'CreateImmobile', component: CreateImmobile, meta: {requiresAuth: true, grants: ['ROLE_CLIENT', 'ROLE_SELLER', 'ROLE_REALTOR', 'ROLE_ADMIN']} },         
        //meu perfil
        { path:'/myprofile', name: 'MyProfile', component: MyProfile, meta: {requiresAuth: true, grants: ['ROLE_CLIENT', 'ROLE_SELLER', 'ROLE_REALTOR', 'ROLE_ADMIN']} },
        //editar perfil
        { path: '/profileedit/:profile_id', name: 'ProfileEdit', component: ProfileEdit, meta: {requiresAuth: true, grants: ['ROLE_CLIENT', 'ROLE_SELLER', 'ROLE_REALTOR', 'ROLE_ADMIN']} },
        //favoritos
        { path:'/myfavorite', name: 'Favorite', component: Favorite, meta: {requiresAuth: true, grants: ['ROLE_CLIENT', 'ROLE_SELLER', 'ROLE_REALTOR', 'ROLE_ADMIN']}},
        //meus imóveis
        { path:'/myimmobile', name: 'MyImmobile', component: MyImmobile, meta: {requiresAuth: true, grants: ['ROLE_CLIENT', 'ROLE_SELLER', 'ROLE_REALTOR', 'ROLE_ADMIN']}},
        //detalhes do imóvel
        { path:'/immobileinfo/:id', name: 'ImmobileInfo', component: ImmobileInfo },
        //confirma corretor
        { path:'/confirmbroker', name: 'ConfirmBroker', component: ConfirmBroker, meta: {requiresAuth: true, grants: ['ROLE_ADMIN']} },
        //Registro de vendedor     
        { path:'/cadastro-proprietario', name:'SellerStepByStep', component:SellerStepByStep},
        //mensagens do chat
        { path:'/messageschat', name: 'MessagesChat', component: MessagesChat, meta: {requiresAuth: true, grants: ['ROLE_CLIENT', 'ROLE_SELLER',  'ROLE_REALTOR', 'ROLE_ADMIN']}  },
        
        

        /**
         * @@@@@@  Rotas de Corretores de imóveis @@@@@@@@
         */
        //Login corretor
        { path:'/realtor-login', name:'RealtorLogin',  component:RealtorLogin },
        //Registrar-se corretor
        { path:'/realtor-registration', name:'RealtorStepByStep', component:RealtorStepByStep      },
        //chat com o comprador
        // { path:'/chat', name:'Chat', component:ChatWithBuyer, meta: {requiresAuth: true, grants: ['ROLE_CLIENT', 'ROLE_SELLER', 'ROLE_REALTOR', 'ROLE_ADMIN']} },
        //↓↓ novo componente do chat
        { path:'/chat/:announcement_id', name:'Chat', component:Chat, meta: {requiresAuth: true, grants: ['ROLE_CLIENT', 'ROLE_SELLER', 'ROLE_REALTOR', 'ROLE_ADMIN']} },

        /**
         * %%%%%%%  Rotas Telas de Administradores da plataforma %%%%%%%%%%%%%
         */
        //lista de usuários da plataforma
        { path:'/list-all-users', name:'ListAllUsers', component:ListAllUsers, meta: {requiresAuth: true, grants: ['ROLE_ADMIN']} },
        //lista de compradores da plataforma
        { path:'/list-buyers', name:'ListBuyers', component:ListBuyers, meta: {requiresAuth: true, grants: ['ROLE_ADMIN']} },
        //lista de vendedores da plataforma
        { path:'/lista-vendedores', name:'ListSellers', component:ListSellers, meta: {requiresAuth: true, grants: ['ROLE_ADMIN']} },
        //lista de corretores de imóveis da plataforma
        { path:'/list-realtors', name:'ListRealtors', component:ListRealtors, meta: {requiresAuth: true, grants: ['ROLE_ADMIN']} },
        //visualizar perfil do usuário
        { path:'/show-profile-user/:id', name:'ShowProfileUserForAdmins', component:ShowProfileUserForAdmins, meta: {requiresAuth: true, grants: ['ROLE_ADMIN']} },
        //visualizar perfil do corretor
        { path:'/show-profile-realtor/:id', name:'ShowProfileRealtorForAdmins', component:ShowProfileRealtorForAdmins, meta: {requiresAuth: true, grants: ['ROLE_ADMIN']} },
        //↓↓ Nova página de administração de imóveis. Deve ser alterada para a pasta admin
        { path:'/immobile-admin', name:'ImmobileAdmin', component:ImmobileAdmin, meta: {requiresAuth: true, grants: ['ROLE_ADMIN']} },
        { path:'/info-admin/:id', name:'InfoAdmin', component:InfoAdmin, meta: {requiresAuth: true, grants: ['ROLE_ADMIN']} },
        { path:'/edit-immobile/:id', name:'editimmobile', component:EditImmobile, meta: {requiresAuth: true, grants: ['ROLE_REALTOR','ROLE_ADMIN']} },
    ]
})

router.beforeEach( async (to, from, next) => {
    // console.log('navegando de:',from);
    // console.log('navegando para:',to);
    // console.log('to.mateched', to.matched);
    if(to.matched.some( record => record.meta.requiresAuth )){
        const token = localStorage.getItem('token')
        const getUserAuth = localStorage.getItem('user')
        const userAuth = JSON.parse(getUserAuth)
        const userLogged = userAuth.user
        const loginRoute = {
            path: '/login',
            query: { returnUrl: to.path}
        }
        const arrayOfRoles = to.meta.grants
        const arrayDePermissoes = JSON.parse(userLogged.role)
        // verifica se esta logado /** se tem o token, tá logado */
        // if(token && arrayOfRoles.includes(papel)){
        if( token && inArray(arrayDePermissoes, arrayOfRoles) ){
            try {
                //testa se o token ainda é válido
                return next()
            } catch (error) {
                //não conseguiu logar, vai ser redirecionado
                console.log('erro no login', error);
                return next(loginRoute)
            }
        }
        return next(loginRoute)   
    }
    next()
})

function inArray(needle, haystack) {
    for (var i = 0; i < haystack.length; i++) {
        for (var j =0; j < needle.length; j++){
            if (haystack[i] == needle[j]) return true;
        }
    }
    return false;
   }


export default router