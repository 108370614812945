<template v-if="infoImmobile">
<div>
    <site-header/>
    <div class="ml-5 mr-5">
        <h3 class="pt-4">{{infoImmobile.title}}</h3>
        <p>{{infoImmobile.city}}, {{infoImmobile.state}}, {{infoImmobile.country}} <i class="fas fa-map-marker-alt"></i></p>
        
        <lightbox css="h-200 h-lg-450" :items="immobileImages"></lightbox>

        <div class="row mt-3">
            <div class="col-md-7">
                <div class="col-md-12">
                    <p class="m-0 p-0">{{infoImmobile.description}}</p>
                    <p class="text-success font-weight-bold" style="font-size:20pt;">R$ {{infoImmobile.price}}</p>
                    <p v-if="infoImmobile.value_iptu != 0" class="m-0 p-0"><b>IPTU</b> R${{infoImmobile.value_iptu}}</p>
                    <p v-if="infoImmobile.value_condominium != 0"><b>Condomínio</b> R${{infoImmobile.value_condominium}}</p>
                </div>
                <div class="col-md-12">
                    <p class="m-0 p-0">Situação</p>
                    <p class="font-weight-bold" style="font-size:14pt;"><span class="fas fa-tag mr-2"></span>{{infoImmobile.availability}}</p>
                </div>
                <hr class="mt-0 mb-0" width="96%" align="center">
                <div class="col-md-12 mt-3 mb-4">
                    <h4>Descrição do Imóvel</h4>
                    <p>{{infoImmobile.description}}</p>
                    <p class="col-12 p-0 m-0 text-muted"><img src="../../assets/img/icons/area.png" id="icon-area" alt=""> {{infoImmobile.ground_area}} m²</p>
                    <p v-if="infoImmobile.number_room === 1" class="card-text col-12 p-0 m-0 text-muted"><i class="fas fa-bed mr-1"></i> {{infoImmobile.number_room}} Dormitório</p>
                    <p v-if="infoImmobile.number_room > 1" class="card-text col-12 p-0 m-0 text-muted"><i class="fas fa-bed mr-1"></i> {{infoImmobile.number_room}} Dormitórios</p>
                    <p v-if="infoImmobile.number_bathroom === 1" class="card-text col-12 p-0 m-0 text-muted"><i class="fas fa-bath mr-2"></i> {{infoImmobile.number_bathroom}} Banheiro</p>
                    <p v-if="infoImmobile.number_bathroom > 1" class="card-text col-12 p-0 m-0 text-muted"><i class="fas fa-bath mr-2"></i> {{infoImmobile.number_bathroom}} Banheiros</p>
                    <p v-if="infoImmobile.number_space === 1" class="card-text col-12 p-0 m-0 text-muted"><i class="fas fa-car mr-2"></i> {{infoImmobile.number_space}} Garagem</p>
                    <p v-if="infoImmobile.number_space > 1" class="card-text col-12 p-0 m-0 text-muted"><i class="fas fa-car mr-2"></i> {{infoImmobile.number_space}} Garagens</p>
                </div>
                <hr class="mt-0 mb-0" width="96%" align="center">
                <div class="col-md-12 mt-3 mb-5">
                    <h4>Localização</h4>
                    <p> <i class="fas fa-map-marker-alt"></i> {{infoImmobile.public_place_type}} {{infoImmobile.public_place}}, nº {{infoImmobile.number}}, {{infoImmobile.neighborhood}}, {{infoImmobile.city}} - {{infoImmobile.state}}</p>
                    <p> Coordenadas: lat: {{parseFloat(infoImmobile.latitude)}}, lng: {{parseFloat(infoImmobile.longitude)}}</p>
                    <div class="card">
                        <div class="card-body">
                            <!-- Google Maps -->
                            <GmapMap
                                :center="{lat: parseFloat(infoImmobile.latitude) , lng: parseFloat(infoImmobile.longitude)}"
                                :zoom="16"
                                map-type-id="terrain"
                                style="width: 100%; height: 360px"
                                >
                                <GmapMarker
                                    :position="{lat: parseFloat(infoImmobile.latitude) , lng: parseFloat(infoImmobile.longitude)}"
                                    :clickable="true"
                                    :draggable="false"
                                    @click="center={lat: parseFloat(infoImmobile.latitude) , lng: parseFloat(infoImmobile.longitude)}"
                                /> 
                            </GmapMap>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-body">
                            <h5>Informações da região</h5>
                            <h6>São os pontos mais próximos do imóvel em um raio de 3 km.</h6>
                            <h6>São os pontos mais próximos do imóvel em um raio de 3 km.</h6>
                            <div v-if="isLoading">
                                <loading
                                        :active.sync="isLoading"
                                        :is-full-page="fullPage"
                                        :can-cancel="false"
                                        />
                            </div>
                            <div v-else id="tabs" class="container">
  
                                <div class="tabs">
                                    <a v-on:click="locaisProximos(transport, 1, lat = parseFloat(infoImmobile.latitude), lng = parseFloat(infoImmobile.longitude))" v-bind:class="[ activetab === 1 ? 'active' : '' ]">Transporte</a>
                                    <a v-on:click="locaisProximos(education, 2, lat = parseFloat(infoImmobile.latitude), lng = parseFloat(infoImmobile.longitude))" v-bind:class="[ activetab === 2 ? 'active' : '' ]">Educação</a>
                                    <a v-on:click="locaisProximos('hospital', 3, lat = parseFloat(infoImmobile.latitude), lng = parseFloat(infoImmobile.longitude))" v-bind:class="[ activetab === 3 ? 'active' : '' ]">Saúde</a>
                                </div>

                                <div class="content">
                                    <div v-if="activetab === 1" class="tabcontent">
                                        <div class="d-flex flex-column">
                                            <h4>Ponto de ônibus</h4>
                                            <div v-for="(t, index) in transportPlaces" :key="index"  class="p-2 border-bottom">
                                                <p>{{t.name}}</p>
                                                <p><i class="fas fa-running"></i> {{t.time}} - {{t.distance}} metros</p>
                                            </div>
                                            <hr>
                                        </div>
                                    </div>
                                    <div v-if="activetab === 2" class="tabcontent">
                                        <div class="d-flex flex-column">
                                            <h4>Escolas e Afins</h4>
                                            <div v-for="(e, index) in educationPlaces" :key="index"  class="p-2">
                                                <p>{{e.name}}</p>
                                                <p><i class="fas fa-running"></i> {{e.time}} - {{e.distance}} metros </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="activetab === 3" class="tabcontent">
                                        <div class="d-flex flex-column">
                                            <h4>Hospitais e clínicas</h4>
                                            <div v-for="(h, index) in healthPlaces" :key="index"  class="p-2">
                                                <p>{{h.name}}</p>
                                                <p><i class="fas fa-running"></i> {{h.time}} - {{h.distance}} metros </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-5">
                <div class="row position-static ml-5">
                    <div class="card mb-3 shadow-lg p-2 bg-white rounded" style="max-width: 30rem;">
                        <div class="card-body text-dark mt-0">
                            <div class="row mb-2">
                                <h5 class="col-md-8 mt-2">Gostou deste imóvel?</h5>
                                <button v-if="infoImmobile && getUser" id="button-broker" role="button" aria-hidden="true" @click="favoritarImovel(infoImmobile.id)" :class="{'like': favorite, 'unlike': !favorite}" class="col-md-2 p-0"><i class="fas fa-heart"></i></button>
                            </div>  
                            <h6 class="text-success"><i class="fas fa-eye text-success"></i> {{quantidadeFavoritados}} pessoas gostaram deste imóvel.</h6>
                            <hr class="mt-0 mb-0" width="10%" align="left">
                            <h6 class="mt-3">Que tal conversar com um corretor e marcar uma visita?</h6>
                            <button @click="levaAoChat(infoImmobile)" type="button" class="btn btn-danger btn-lg btn-block col-md-12"> <i class="fas fa-comments mr-3"></i>Quero conversar com um corretor</button>
                            <!-- <router-link :to="{ name: 'Chat', params: { immobile_id: immobileId, announcement_id: annoucementId }}" tag="button" class="btn btn-danger btn-lg btn-block col-md-12"> <i class="fas fa-comments mr-3"></i>Quero conversar com um corretor</router-link> -->
                        </div>
                    </div>
                </div>
                <!-- #### Bloco Comentários #### -->
                <Comments/>
                
                <!-- #### Bloco Listagem de Comentários #### -->
                <ListComments :dados_anuncio="infoImmobile.announcement"/>
                
            </div>
            
        </div>
    </div> 
</div>
</template>

<script>
/* importando barra de navegacao superior */
import SiteHeader from '../../components/SiteHeader.vue';
//importa o componente de comentário
import Comments from '../../components/comments/Comments.vue';
//importa o componente Listagem de comentários
import ListComments from '../../components/comments/ListComments.vue';
//importa os getters do vuex
import { mapGetters, mapState } from 'vuex';
/* importando componente de galeria de imagens */
import lightbox from "../../components/lightbox.vue";
import "../../assets/styles/lightbox.css";
import axios from 'axios'
// import {debounce} from 'lodash'
//sweet alert
 import Swal from 'sweetalert2'
 import swal from 'sweetalert'
 // Import do component de loading
import Loading from 'vue-loading-overlay'

export default {
    name: 'ImmobileInfo',
    components: {
        SiteHeader,
        lightbox,
        Loading,
        Comments,
        ListComments
    },
    data(){
        return {
            isLoading: true,
            fullPage: false,
            infoImmobile: {},
            lat: '',
            lgn: '',
            transport: 'bus_station|subway_station|transit_station|train_station',
            transportPlaces: [],
            educationPlaces: [],
            healthPlaces: [],
            education: 'school|secondary_school|university|primary_shool',
            annoucementId: null,
            immobileId: null,
            immobileImages: [],
            favorite: false,
            favoritadoPeloUsuarioLogado: false,
            quantidadeFavoritados: 0,
            activetab: 1,
            endpoint_immobile: process.env.VUE_APP_IMMOBILE,
            endpoint_announcement: process.env.VUE_APP_ANNOUNCEMENT_FOR_ID,
            endpoint_favorite: process.env.VUE_APP_FAVORITE,
            endpoint_quant_favoritos: process.env.VUE_APP_QUANT_FAVORITADO_IMOVEL,
            endpoint_images: process.env.VUE_APP_IMAGES,
            googleMapApiKey: process.env.VUE_APP_GOOGLE_MAP_API_KEY    

        }
    },
    
    computed: {
        ...mapState(['user']),
        ...mapGetters(['getUser', 'getToken', 'getAnnouncement']) // aqui os getters recuperam o token e os dados do cliente logado
    },
    async mounted (){
       await this.getDataImmobile();
       this.locaisProximos(this.transport, 1, this.infoImmobile.latitude, this.infoImmobile.longitude)
       await this.obterQuantidadeFavoritosImovel(this.infoImmobile.id).then(result => this.quantidadeFavoritados = result.data)
    //    console.log('quantidade favoritos', this.quantidadeFavoritados)
       setTimeout(() => {
        this.infoImmobile.favorite.forEach( (f)=>{
            console.log('favoritado pelos usuarios:', f.user_id)
            console.log('usuario logado:', this.getUser.user.id)
           if(f.user_id === this.getUser.user.id){
               this.favorite = true
           }
        })
       }, 500); 
    },
    methods:{
        mapCenter () {
            // console.log(this.infoImmobile)
            //se não tiver coordenadas do imóvel centraliza em um ponto de SP
            if(!this.infoImmobile.length){
                return {  
                    lat: -23.535469, 
                    lng: -46.611114
                }
            }
            //se tiver coordenadas usa para centralizar
            return {
                lat: parseFloat(this.infoImmobile.latitude),
                lng: parseFloat(this.infoImmobile.longitude)
            }
        },
        levaAoChat (dadosImovel) {
            /**
             * verificar se existe os dados do imóvel e se o user logado é o responsável pelo anúncio
             * caso SIM, então não deixa o anunciante iniciar um chat com ele mesmo
             * */
            console.log('dados do imovel', dadosImovel)
            console.log('objeto getUser', Object.keys(this.getUser).length === 0)
            if(Object.keys(this.getUser).length === 0){
                //exibe erro na tela
                Swal.fire({ 
                icon: 'warning',
                title: 'É preciso fazer login no clube',
                text: 'O Chat com corretores é possível somente para usuários cadastrados no clube de imóveis.',
                })
            }else{
                this.$router.push({ name: 'Chat', params: { announcement_id: this.annoucementId } })
            } 
        },
       async getDataImmobile () {
            var url = this.endpoint_immobile + '/';
            // Make a request for a user with a given ID
            await axios.get(url + this.$route.params.id)
            .then(response => {
                this.infoImmobile = response.data.data
                let retorno = response.data.data
                this.immobileId = retorno['id']
                this.annoucementId = retorno['announcement'].id
                var photos = response.data.data.photos
                photos.forEach( img => { this.immobileImages.push(this.endpoint_images + img.photo) });
                this.lat = this.infoImmobile.latitude
                this.lng = this.infoImmobile.longitude
            })
            .catch(function (error) {
                console.log(error);
            })
            
            //chama a função seta anúncio para setar o state de announcement
            this.setaAnuncio(this.annoucementId);
            return this.infoImmobile
        },
        setaAnuncio (id) {
            const url = this.endpoint_announcement + id
            this.$store.dispatch('announcementData', url);
        },
        /**Favoritar imóvel*/
        async favoritarImovel (idImovel){
            if(!localStorage.getItem('token')||!idImovel){
                swal('Ops', 'Você deve fazer o login primeiro.', 'info').then( () => {
                    // this.$router.push('/login');
                })
            }else{
                let idUser = this.getUser.user.id
                console.log('idUser', idUser)
                this.isLoading = true
                var dados = new FormData();
                dados.append('user_id', idUser)
                dados.append('immobile_id', idImovel)
                await axios.post(this.endpoint_favorite, dados, 
                    {
                        headers:{
                            'Content-type': 'multipart/form-data',
                            'Accept': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('token')                
                    }
                }).then( (retorno)=>{
                    console.log('retorno', retorno)
                    this.isLoading = false;
                    this.favorite = !this.favorite;
                }).catch( (error)=> {
                    this.isLoading = false;
                    swal('Ops', 'Algo deu errado não foi possível favoritar o imóvel. Erro'+error, 'error').then( () => {
                    
                    })
                })
            }        
        },
        obterQuantidadeFavoritosImovel(idImovel){
            return new Promise( (resolve, reject)=>{
                axios.get(this.endpoint_quant_favoritos+idImovel).then( (response)=>{
                    resolve(response.data)
                }).catch( (error)=>{
                    console.log('erro', error)
                    reject(error)
                })
            })
        },
        verificaUsuarioFavoritouImovel (objetoImovel){
            //percorre o array de usuários que favoritaram o imóvel e verifica se
            //o usuário logado já favoritou o imóvel, se sim, então muda o valor 
            //de favorite para true.
            if(Object.keys(objetoImovel.favorite).length === 0){
                objetoImovel.favorite.forEach( (f)=>{
                    console.log('array de user que favoritaram o imovel', f)
                    this.favoritadoPeloUsuarioLogado = true;
                })
            }
        },
        /** */
        // busca locais próximos num raio de 2km
        async locaisProximos(keyword,activetab, lat, lgn){
            this.lat = lat;
            this.lng = lgn;

            //variável que conterá o retorno da API
            // var retornoDaAPI = [];
            var objetoRetornado;
            //seta a tab active
            this.activetab = activetab
            //enquanto carrega exibe o loading
            this.isLoading = true;
            //chamada axios a API nearby
            //verifico se tem latitude e longitude setada
            if(this.lat && this.lng){
                    await axios(`${process.env.VUE_APP_URL_PROXY_NEARBY}`, { params: { lat: this.lat, lng: this.lng, types: keyword, googleMapApiKey: this.googleMapApiKey  } })
                    .then(function (response) {
                        //após ter o retorno fecha o loading
                        objetoRetornado = response.data
                        // console.log('objetoRetornado', objetoRetornado)
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                    // //requisita a API de calculo de distancia  e tempo para calcular 
                    //verifica e preenche a variável conforme o retorno da API do google maps
                    switch (activetab) {
                            case 1:
                                //transportes
                                this.transportPlaces = objetoRetornado
                                //seta o loading
                                this.isLoading = false;
                                break;
                            case 2:
                                //educação
                                this.educationPlaces = objetoRetornado
                                this.isLoading = false;
                                break;
                            case 3:
                                //saude
                                this.healthPlaces = objetoRetornado
                                this.isLoading = false;
                                break;
                    }

            }else{
                console.log('não tem as coordenadas completas')
                console.log('latitude', this.lat)
                console.log('longitude', this.lng)
            }
           
        }
    },
}
</script>

<style scoped>

/* Importando o bootstrap 4 */
@import '../../assets/styles/bootstrap4/bootstrap.min.css';

/* Estilo para editar o tomanho do icone de area */
#icon-area{
    position: relative;
    width: 3%;
    height: 90%;    
}

/* Estilo do botao de curtir */
#button-broker{
    border: none;
    background: none;
}

#button-broker:focus{
    outline-style: none;
}

.unlike{
    color: #00000030;
    font-size: 2em;
}
.like{
    color: #f44336;
    font-size: 2em;
}

/** estilo das tabs */
.container {  
    /* max-width: 620px; 
    min-width: 420px;
    margin: 40px auto; */
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.9em;
    color: #888;
}

/* Style the tabs */
.tabs {
    overflow: hidden;
  /* margin-left: 20px; */
    margin-bottom: -2px; 
}

.tabs ul {
    list-style-type: none;
    /* margin-left: 20px; */
}

.tabs a{
    float: left;
    cursor: pointer;
    padding: 12px 24px;
    transition: background-color 0.2s;
    border: 1px solid #ccc;
    border-right: none;
    /* background-color: #f1f1f1; */
    border-radius: 10px 10px 0 0;
    font-weight: bold;
}
.tabs a:last-child { 
    border-right: 1px solid #f2f2f2;
}

/* Change background color of tabs on hover */
.tabs a:hover {
    background-color: #f1f1f1;
    color: #fff;
}

/* Styling for active tab */
.tabs a.active {
    background-color: #fff;
    color: #008612;
    border-bottom: 2px solid #fff;
    cursor: default;
}

/* Style the tab content */
.tabcontent {
    padding: 30px;
    border: 1px solid #ccc;
    /* border-radius: 10px; */
  /* box-shadow: 3px 3px 6px #e1e1e1 */
}
/** Fim estilo das tabs */

</style>