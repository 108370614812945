//constantes globais
export const constantes = {
    //tipos de logradouros       
    tipo_de_logradouros: [ 
        { id: 'Área', label:'Área' },
        { id: 'Acesso', label:'Acesso' },
        { id: 'Acampamento', label:'Acampamento' },
        { id: 'Acesso Local', label:'Acesso Local' },
        { id: 'Adro', label:'Adro' },
        { id: 'Área Especial', label:'Área Especial' },
        { id: 'Aeroporto', label:'Aeroporto' },
        { id: 'Alameda', label:'Alameda' },
        { id: 'Avenida Marginal Direita', label:'Avenida Marginal Direita' },
        { id: 'Avenida Marginal Esquerda', label:'Avenida Marginal Esquerda' },
        { id: 'Anel Viário', label:'Anel Viário' },
        { id: 'Antiga Estrada', label:'Antiga Estrada' },
        { id: 'Artéria', label:'Artéria' },
        { id: 'Alto', label:'Alto' },
        { id: 'Atalho', label:'Atalho' },
        { id: 'Área Verde', label:'Área Verde' },
        { id: 'Avenida', label:'Avenida' },
        { id: 'Avenida Contorno', label:'Avenida Contorno' },
        { id: 'Avenida Marginal', label:'Avenida Marginal' },
        { id: 'Avenida Velha', label:'Avenida Velha' },
        { id: 'Balneário', label:'Balneário' },
        { id: 'Beco', label:'Beco' },
        { id: 'Buraco', label:'Buraco' },
        { id: 'Belvedere', label:'Belvedere' },
        { id: 'Bloco', label:'Bloco' },
        { id: 'Balão', label:'Balão' },
        { id: 'Blocos', label:'Blocos' },
        { id: 'Bulevar', label:'Bulevar' },
        { id: 'Bosque', label:'Bosque' },
        { id: 'Boulevard', label:'Boulevard' },
        { id: 'Baixa', label:'Baixa' },
        { id: 'Cais', label:'Cais' },
        { id: 'Calçada', label:'Calçada' },
        { id: 'Caminho', label:'Caminho' },
        { id: 'Canal', label:'Canal' },
        { id: 'Chácara', label:'Chácara' },
        { id: 'Chapadão', label:'Chapadão' },
        { id: 'Ciclovia', label:'Ciclovia' },
        { id: 'Circular', label:'Circular' },
        { id: 'Conjunto', label:'Conjunto' },
        { id: 'Conjunto Mutirão', label:'Conjunto Mutirão' },
        { id: 'Complexo Viário', label:'Complexo Viário' },
        { id: 'Colônia', label:'Colônia' },
        { id: 'Comunidade', label:'Comunidade' },
        { id: 'Condomínio', label:'Condomínio' },
        { id: 'Corredor', label:'Corredor' },
        { id: 'Campo', label:'Campo' },
        { id: 'Córrego', label:'Córrego' },
        { id: 'Contorno', label:'Contorno' },
        { id: 'Descida', label:'Descida' },
        { id: 'Desvio', label:'Desvio' },
        { id: 'Distrito', label:'Distrito' },
        { id: 'Entre Bloco', label:'Entre Bloco' },
        { id: 'Estrada Intermunicipal', label:'Estrada Intermunicipal' },
        { id: 'Enseada', label:'Enseada' },
        { id: 'Entrada Particular', label:'Entrada Particular' },
        { id: 'Entre Quadra', label:'Entre Quadra' },
        { id: 'Escada', label:'Escada' },
        { id: 'Escadaria', label:'Escadaria' },
        { id: 'Estrada Estadual', label:'Estrada Estadual' },
        { id: 'Estrada Vicinal', label:'Estrada Vicinal' },
        { id: 'Estrada de Ligação', label:'Estrada de Ligação' },
        { id: 'Estrada Municipal', label:'Estrada Municipal' },
        { id: 'Esplanada', label:'Esplanada' },
        { id: 'Estrada de Servidão', label:'Estrada de Servidão' },
        { id: 'Estrada', label:'Estrada' },
        { id: 'Estrada Velha', label:'Estrada Velha' },
        { id: 'Estrada Antiga', label:'Estrada Antiga' },
        { id: 'Estação', label:'Estação' },
        { id: 'Estádio', label:'Estádio' },
        { id: 'Estância', label:'Estância' },
        { id: 'Estrada Particular', label:'Estrada Particular' },
        { id: 'Estacionamento', label:'Estacionamento' },
        { id: 'Evangélica', label:'Evangélica' },
        { id: 'Elevada', label:'Elevada' },
        { id: 'Eixo Industrial', label:'Eixo Industrial' },
        { id: 'Favela', label:'Favela' },
        { id: 'Fazenda', label:'Fazenda' },
        { id: 'Ferrovia', label:'Ferrovia' },
        { id: 'Fonte', label:'Fonte' },
        { id: 'Feira', label:'Feira' },
        { id: 'Forte', label:'Forte' },
        { id: 'Galeria', label:'Galeria' },
        { id: 'Granja', label:'Granja' },
        { id: 'Núcleo Habitacional', label:'Núcleo Habitacional' },
        { id: 'Ilha', label:'Ilha' },
        { id: 'Indeterminado', label:'Indeterminado' },
        { id: 'Ilhota', label:'Ilhota' },
        { id: 'Jardim', label:'Jardim' },
        { id: 'Jardinete', label:'Jardinete' },
        { id: 'Ladeira', label:'Ladeira' },
        { id: 'Lagoa', label:'Lagoa' },
        { id: 'Lago', label:'Lago' },
        { id: 'Loteamento', label:'Loteamento' },
        { id: 'Largo', label:'Largo' },
        { id: 'Lote', label:'Lote' },
        { id: 'Mercado', label:'Mercado' },
        { id: 'Marina', label:'Marina' },
        { id: 'Modulo', label:'Modulo' },
        { id: 'Projeção', label:'Projeção' },
        { id: 'Morro', label:'Morro' },
        { id: 'Monte', label:'Monte' },
        { id: 'Núcleo', label:'Núcleo' },
        { id: 'Núcleo Rural', label:'Núcleo Rural' },
        { id: 'Outeiro', label:'Outeiro' },
        { id: 'Paralela', label:'Paralela' },
        { id: 'Passeio', label:'Passeio' },
        { id: 'Pátio', label:'Pátio' },
        { id: 'Praça', label:'Praça' },
        { id: 'Praça de Esportes', label:'Praça de Esportes' },
        { id: 'Parada', label:'Parada' },
        { id: 'Paradouro', label:'Paradouro' },
        { id: 'Ponta', label:'Ponta' },
        { id: 'Praia', label:'Praia' },
        { id: 'Prolongamento', label:'Prolongamento' },
        { id: 'Parque Municipal', label:'Parque Municipal' },
        { id: 'Parque', label:'Parque' },
        { id: 'Parque Residencial', label:'Parque Residencial' },
        { id: 'Passarela', label:'Passarela' },
        { id: 'Passagem', label:'Passagem' },
        { id: 'Passagem de Pedestre', label:'Passagem de Pedestre' },
        { id: 'Passagem Subterrânea', label:'Passagem Subterrânea' },
        { id: 'Ponte', label:'Ponte' },
        { id: 'Porto', label:'Porto' },
        { id: 'Quadra', label:'Quadra' },
        { id: 'Quinta', label:'Quinta' },
        { id: 'Quintas', label:'Quintas' },
        { id: 'Rua', label:'Rua' },
        { id: 'Rua Integração', label:'Rua Integração' },
        { id: 'Rua de Ligação', label:'Rua de Ligação' },
        { id: 'Rua Particular', label:'Rua Particular' },
        { id: 'Rua Velha', label:'Rua Velha' },
        { id: 'Ramal', label:'Ramal' },
        { id: 'Recreio', label:'Recreio' },
        { id: 'Recanto', label:'Recanto' },
        { id: 'Retiro', label:'Retiro' },
        { id: 'Residencial', label:'Residencial' },
        { id: 'Reta', label:'Reta' },
        { id: 'Ruela', label:'Ruela' },
        { id: 'Rampa', label:'Rampa' },
        { id: 'Rodo Anel', label:'Rodo Anel' },
        { id: 'Rodovia', label:'Rodovia' },
        { id: 'Rotula', label:'Rotula' },
        { id: 'Rua de Pedestre', label:'Rua de Pedestre' },
        { id: 'Margem', label:'Margem' },
        { id: 'Retorno', label:'Retorno' },
        { id: 'Rotatória', label:'Rotatória' },
        { id: 'Segunda Avenida', label:'Segunda Avenida' },
        { id: 'Sitio', label:'Sitio' },
        { id: 'Servidão', label:'Servidão' },
        { id: 'Setor', label:'Setor' },
        { id: 'Subida', label:'Subida' },
        { id: 'Trincheira', label:'Trincheira' },
        { id: 'Terminal', label:'Terminal' },
        { id: 'Trecho', label:'Trecho' },
        { id: 'Trevo', label:'Trevo' },
        { id: 'Túnel', label:'Túnel' },
        { id: 'Travessa', label:'Travessa' },
        { id: 'Travessa Particular', label:'Travessa Particular' },
        { id: 'Travessa Velha', label:'Travessa Velha' },
        { id: 'Unidade', label:'Unidade' },
        { id: 'Via', label:'Via' },
        { id: 'Via Coletora', label:'Via Coletora' },
        { id: 'Via Local', label:'Via Local' },
        { id: 'Via de Acesso', label:'Via de Acesso' },
        { id: 'Vala', label:'Vala' },
        { id: 'Via Costeira', label:'Via Costeira' },
        { id: 'Viaduto', label:'Viaduto' },
        { id: 'Via Expressa', label:'Via Expressa' },
        { id: 'Vereda', label:'Vereda' },
        { id: 'Via Elevado', label:'Via Elevado' },
        { id: 'Vila', label:'Vila' },
        { id: 'Viela', label:'Viela' },
        { id: 'Vale', label:'Vale' },
        { id: 'Via Litorânea', label:'Via Litorânea' },
        { id: 'Via de Pedestre', label:'Via de Pedestre' },
        { id: 'Variante', label:'Variante' },
        { id: 'Zigue-Zague', label:'Zigue-Zague' },
    ],
    //estados brasileiros
    estados: [
        {id:'AC', label:'Acre'},
        {id:'AL', label:'Alagoas'},
        {id:'AP', label:'Amapá'},
        {id:'AM', label:'Amazonas'},
        {id:'BA', label:'Bahia'},
        {id:'CE', label:'Ceara'},
        {id:'DF', label:'Distrito Federal'},
        {id:'ES', label:'Espírito Santo'},
        {id:'GO', label:'Goiás'},
        {id:'MA', label:'Maranhão'},
        {id:'MT', label:'Mato Grosso'},
        {id:'MS', label:'Mato Grosso do Sul'},
        {id:'MG', label:'Minas Gerais'},
        {id:'PA', label:'Pará'},
        {id:'PB', label:'Paraíba'},
        {id:'PR', label:'Paraná'},
        {id:'PE', label:'Pernambuco'},
        {id:'PI', label:'Piauí'},
        {id:'RJ', label:'Rio de Janeiro'},
        {id:'RN', label:'Rio Grande do Norte'},
        {id:'RS', label:'Rio Grande do Sul'},
        {id:'RO', label:'Rondônia'},
        {id:'RR', label:'Roraima'},
        {id:'SC', label:'Santa Catarina'},
        {id:'SP', label:'São Paulo'},
        {id:'SE', label:'Sergipe'},
        {id:'TO', label:'Tocantins'}
    ]   
    
}