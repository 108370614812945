import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
//import consts from '@/consts'

Vue.use(Vuex)

//constante token
const token = ( localStorage.getItem('token') ) ? localStorage.getItem('token') : undefined;

export default new Vuex.Store({
  state:{
    announcement: {},
    immobile: {},
    immobiles: [],
    immobileType: 0,
    enderecoCep:{},
    user:{},
    token: localStorage.getItem('token') || false,
    listUsersChat: [],
    listConversationsChat: [],
    initialListConversationsChat: [],
    immobileOfTheChat: {},
    currentUser: null,
    notification: false,
    comments: [],
  },

  getters: {
    isAuthenticated: state => !!state.token,
    geAlltImmobiles: (state) => {
      return state.immobile
    },
    getEnderecoCep: (state) => {
        return state.enderecoCep
      },
    getLogradouro: (state) => {
      return state.enderecoCep.logradouro
    },
    getUser: (state) => {
        return state.user
    },
    getToken: (state) => {
        return state.token
    },
    getAllImmobiles: (state) => {
      return state.immobiles
    },
    getAnnouncement: (state) => {
      return state.announcement
    }
  },

  /** Mutations */
  mutations:{
    loadImmobile(state, obj) {
      state.immobile = obj;
    },
    loadEnderecoCep(state, obj){
        state.enderecoCep = obj;
    },
    loadUser (state, payload) {
      // console.log('obj user', payload);
      state.user = payload
    },
    loadToken (state, payload) {
      state.token = payload
    },
    SET_ANNOUNCEMENT (state, objAnnouncement) {
      state.announcement = objAnnouncement
    },
    SET_COMMENTS (state, objComment) {
      state.comments = objComment
    },
    SET_IMMOBILES (state, immobiles) {
      state.immobiles = immobiles
    },
    //seta a lista de usuários do chat
    SET_LIST_USERS_CHAT (state, payload) {
      state.listUsersChat = payload
    },
    //seta a lista de conversas do chat
    SET_LIST_CONVERSATIONS_CHAT (state, payload) {
      state.listConversationsChat = payload
    },
    //seta a lista inicial de conversas POR USUÁRIOS do chat
    SET_INITIAL_LIST_CONVERSATIONS_CHAT (state, payload) {
      state.initialListConversationsChat = payload
    },
    //seta o imóvel relacionado ao chat
    SET_IMMOBILE_OF_THE_CHAT (state, payload) {
      state.immobileOfTheChat = payload
    },
    //adiciona mensagem à conversa do chat
    ADD_CHAT_TO_CONVERSATION (state, payload) {
      state.immobileOfTheChat = payload
    },
    //adiciona mensagem à conversa do chat
    SET_CURRENT_USER (state, payload) {
      state.currentUser = payload
    },
    //seta o boolean notification
    SET_NOTIFICATION (state, payload) {
      state.notification = payload
    }
  },

  /** Actions */
  actions: {
    //obtem os dados do anúncio
    announcementData ( {commit}, path){
      return new Promise ( (resolve, reject) => {
        axios({
          method: 'get',
          url: path,
          headers: {
            Authorization: 'Bearer ' + token
          }
        }).then( (response)=> {
          commit('SET_ANNOUNCEMENT', response.data)
          resolve(response.data)
        } ).catch( (error) => {
          reject(error)
        } )
      })
    },
    //obtém dados de um imóvel
    fetchImmobile({commit}, url){
      return new Promise( (resolve, reject) => {
        var urlImmobiles = url.url
        axios.get(urlImmobiles)
          .then( (response) => {
              // handle success
              commit('loadImmobile', response.data) 
              resolve(response.data)
          }).catch ((error) => {
              reject(error)
          })
        })
    },
    //obtém dados de um endereço
    fetchEnderecoCep({ commit }, url) {
        return new Promise( (resolve, reject) => {
            var urlCepViaCep = url.url
            axios.get(urlCepViaCep)
            .then( (response) => {
                // handle success
                commit('loadEnderecoCep', response.data) 
                resolve(response.data)
            }).catch ((error) => {
                reject(error)
            })
        })
      },
      //obtém dados de um imóvel
      getImmobiles({ commit }) {
        return new Promise( (resolve, reject) => {
          axios.get(process.env.VUE_APP_IMMOBILEINDEX)
          .then(response => {
            commit('SET_IMMOBILES', response.data)
            resolve(response.data)
          }).catch( (error)=> {
            reject(error)
          })
        })
      },

      //objeto  userCredentials => {user.email:email, user.password:password, url:urlPath}
      loginUser ({dispatch}, userCredentials) {
        return new Promise( (resolve, reject) => {
          axios.post(userCredentials.url, {email:userCredentials.email, password:userCredentials.password})
          .then( (response) => {
              //salva o token no localStorage
              localStorage.setItem('token', response.data.token)
              // console.log('dados retornados', response);
              //seta o cliente 
              dispatch('setUser', response.data.user)
              //salva o user no localStorage
              let user = JSON.stringify(response.data.user);
              localStorage.setItem('user', user)
              //chama a action para setar o token
              resolve( dispatch('setToken', response.data.token) )
          }).catch ((error) => {
              if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              }
              console.log('erro -> ',error);
              reject(error)
          })
        })
      },
      setToken ({commit}, objToken) {
        commit('loadToken', objToken)
      },
      setUser ({commit}, objUser) {
        
        commit('loadUser',objUser)
      },
      localStorageSetUser ({commit}, objUser) {
        commit('loadUser',objUser)
      },
      fetchToken ({commit}) {
        commit('loadToken')
      },
      suspendAnnouncementImmobile( {dispatch}, payload){
        // var varToken = localStorage.getItem('token')
        return new Promise( (resolve, reject) => {
          axios({
            method: 'put', //you can set what request you want to be
            url: process.env.VUE_APP_ANNOUNCEMENTSUSPEND+`${payload}`,
            data: {id: payload},
            headers: {
              // Authorization: 'Bearer ' + varToken
              Authorization: 'Bearer ' + token
            }
          })
            .then( (response) => {
                // handle success
                dispatch('getImmobiles')
                resolve(response.data)
            }).catch ((error) => {
                reject(error)
            })
          })
      },
      activeAnnouncementImmobile( {dispatch}, payload){
        // var varToken = localStorage.getItem('token')
        return new Promise( (resolve, reject) => {
          axios({
            method: 'put', //you can set what request you want to be
            url: process.env.VUE_APP_ANNOUNCEMENTACTIVE+`${payload}`,
            data: {id: payload},
            headers: {
              // Authorization: 'Bearer ' + varToken
              Authorization: 'Bearer ' + token
            }
          })
            .then( (response) => {
                // handle success
                dispatch('getImmobiles')
                resolve(response.data)
            }).catch ((error) => {
                reject(error)
            })
          })
      },

      /**
       * ===============
       * métodos do CHAT 
       *  ============== 
       */
      //obtem conversas quando o usuário dono do imóvel logar
      obtemConversasUsuarioLogado ( {commit}, payload){
        // var varToken = localStorage.getItem('token')
        // console.log('payload', payload)
        // console.log('url', process.env.VUE_APP_CHAT_USERS_LIST + payload.announcementId)
        return new Promise( (resolve, reject) => {
          axios({
            method: 'get',
            url: process.env.VUE_APP_CHAT_OBTEM_CONVERSAS_USUARIO + payload.user.id,
            headers: {
              // Authorization: 'Bearer ' + varToken
              Authorization: 'Bearer ' + token
            }
          }).then( (response)=> {
            console.log('payload inicial', payload)
            commit('SET_LIST_USERS_CHAT', response.data)
            resolve(response.data)
          }).catch( (error)=>{ 
            reject(error) 
          })
        })
      }, 
      //obtem usuários do chat
      setListUsersChat ( {commit}, payload){
        var varToken = localStorage.getItem('token')
        console.log('payload', payload)
        // console.log('url', process.env.VUE_APP_CHAT_USERS_LIST + payload.announcementId)
        return new Promise( (resolve, reject) => {
          axios({
            method: 'get',
            url: process.env.VUE_APP_CHAT_USERS_LIST + payload.announcementId,
            headers: {
              Authorization: 'Bearer ' + varToken
            }
          }).then( (response)=> {
            commit('SET_LIST_USERS_CHAT', response.data)
            resolve(response.data)
          }).catch( (error)=>{ 
            reject(error) 
          })
        })
      }, 
      
      //obtém mensagens do chat
      setListConversationsChat ( {commit}, payload){
        // var varToken = localStorage.getItem('token')
        /**
         * verifica se o anunciante é quem está enviando a mensagem 
         * se sim, ele clicou em um cliente (payload.usersOfTheChat) para ver as mensagens do cliente
         * se não, então é o cliente quem está enviando a mensagem
        */ 
         return new Promise( (resolve, reject) => {
          axios({
            method: 'get',
            url: process.env.VUE_APP_INITIAL_CHAT + 
            payload.announcementId +'/' + 
            payload.userId +'/' +
            payload.loggedUserId,
            headers: {
              // Authorization: 'Bearer ' + varToken
              Authorization: 'Bearer ' + token
            }
          }).then( (response)=> {
            console.log('resposta mensagens iniciais', response.data)
            commit('SET_INITIAL_LIST_CONVERSATIONS_CHAT', response.data)
            resolve(response.data)
          }).catch( (error)=>{ 
            reject(error) 
          })
        })
      }, 
      
      
      //obtém mensagens do chat
      async setInitialConversationsForUsersChat ( {commit}, payload){
        const dados = await axios({
          method: 'get',
          url: process.env.VUE_APP_INITIAL_CHAT + 
          payload.announcementId +'/' + 
          payload.userId +'/' +
          payload.loggedUserId,
          headers: {
            Authorization: 'Bearer ' + token
          }
        })  
        // console.log('dados do await get axios =', dados)      
        commit('SET_INITIAL_LIST_CONVERSATIONS_CHAT', dados.data)
      }, 
      
      //adiciona nova mensagem ao chat
      addNewMessageChat ( {commit, dispatch}, payload){
        var varToken = localStorage.getItem('token')
        var immobileId = payload.immobileId;
        var announcementId = payload.announcementId;
        return new Promise( (resolve, reject) => {
          axios({
            method: 'post',
            data: {
              fromUser: payload.fromUser,
              toUser: payload.toUser,
              message: payload.message,
              announcementId: payload.announcementId,
            },
            url: process.env.VUE_APP_CHAT_ADD_MESSAGE,
            headers: {
              Authorization: 'Bearer ' + varToken
            }
          }).then( (response)=> {
              dispatch('setInitialConversationsForUsersChat', { 
                immobileId: immobileId, 
                announcementId: announcementId,
                userId: payload.toUser,
                loggedUserId: payload.fromUser,
              })
            resolve(response.data)
            commit()
          }).catch( (error)=>{ 
            reject(error) 
          })
         })
      },

      //obtém o imóvel relacionado ao chat
      setImmobileOfTheChat ( {commit}, payload){
        // var varToken = localStorage.getItem('token')
        return new Promise( (resolve, reject) => {
          axios({
            method: 'get',
            url: process.env.VUE_APP_IMMOBILE_OF_THE_CHAT + payload.announcementId,
            headers: {
              // Authorization: 'Bearer ' + varToken
              Authorization: 'Bearer ' + token
            }
          }).then( (response)=> {
            commit('SET_IMMOBILE_OF_THE_CHAT', response.data)
            resolve(response.data)
          }).catch( (error)=>{ 
            reject(error) 
          })
        })
      }, 
      
      //grava o comentário em um imóvel
      setComment({commit}, payload){
        return new Promise ( (resolve, reject) => {
          axios(
            {
                method: 'post',
                url: process.env.VUE_APP_COMMENT_IN_ANNOUNCEMENT,
                headers: {
                  Authorization: 'Bearer ' + token
                },
                data: {
                  comment: payload.comment,
                  user_id: payload.user_id,
                  advertiser_id: payload.advertiser_id,
                  announcement_id: payload.announcement_id
                  }
              }
          ).then( (response) => {
            console.log('response comment', response.data)
            commit('SET_COMMENTS', response.data);
            resolve(response.data)
          }).catch( (e)=>{
            console.log('erro ao fazer comentário', e.message)
            reject(e)
          })
        }) 
      },
      
      //obtém todos os comentários de um anúncio de imóvel
      allCommentsOfTheAnnouncement({commit}, payload){
        console.log('payload', payload)
          return new Promise( (resolve, reject)=> {
            axios(
              {
                method: 'get',
                url: process.env.VUE_APP_COMMENTS_OF_THE_ANNOUNCEMENT + payload.id, 
                headers: {
                  Authorization: 'Bearer ' + localStorage.getItem('token')
                }
              }
            ).then( (response)=> {
              console.log('response.data', response.data)
              commit('SET_COMMENTS', response.data)
              resolve(response.data)
            }).catch( (error)=> {
              reject(error)
            });
          })
      },

      //obtém o usuário a qual vai ser enviada a mensagem
      setCurrentUser({commit}, payload){
        // console.log('usuario selecionado',payload)
        commit('SET_CURRENT_USER', payload)
      },
      
      //seta a notificação conforme a necessidade
      setNotification({commit}, payload){
        commit('SET_NOTIFICATION', payload)
      }
    }
})
