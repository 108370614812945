<template>
  <div>
    <site-header />
    <div class="box ml-4 mr-4">
      <div class="row">
        <div class="col-md-3">
            <p class="text-center text-success mt-3" style="margin-bottom:-1rem;">Conversas</p>
          <div class="card mt-4">
            <div v-if="isloading" class="spinner-border text-success mb-3 mt-3" role="status" style="margin: 0 auto;">
              <p class="sr-only mb-5">Carregando...</p>
            </div>
            <!-- <div class="card-body"> -->
              <!-- <Conversas v-if="listUsersChat.length > 0" /> -->
              <Conversas v-else :usuarios_da_conversa="listaDeConversas" />
            <!-- </div> -->
          </div>
        </div>
        <div class="col-md-5 mt-2">
          <p class="text-center text-success mt-2" style="margin-bottom:0.5rem">Mensagens</p>
          <div class="card mensagens">
              <div v-if="isloading" class="spinner-border text-success mb-3 mt-3" role="status" style="margin: 0 auto;">
                <p class="sr-only mb-5">Carregando...</p>
              </div>
              <Mensagens v-else :usuarios_da_conversa="listaDeConversas"/>
          </div>

        </div>
        <div class="col-md-4 mt-2">
          <p class="text-center mt-2 text-success" style="margin-bottom:0.5rem">Imóvel</p>
          <div class="card">
            <div class="card-body">
              <div v-if="isloading" class="spinner-border text-success mb-3 mt-3" role="status" style="margin: 0 auto;">
                <p class="sr-only mb-5">Carregando...</p>
              </div>
              <ImovelChat :imovel_do_chat="imovelDoChat" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SiteHeader from '../../components/SiteHeader.vue'
import Conversas from '../../components/chat/Conversas.vue'
import Mensagens from '../../components/chat/Mensagens.vue'
import ImovelChat from '../../components/chat/ImovelChat.vue'
import { mapState } from 'vuex'
// import axios from 'axios'


export default {
  components: { SiteHeader, Conversas, Mensagens, ImovelChat },
  name: 'Chat',
  data () {
    return {
      isloading: true,
      listaDeConversas: [],
      imovelDoChat: {}
    }
  },
  async mounted () {
    //pega e seta a usuários do chat
    this.$store.dispatch('setListUsersChat', {announcementId: this.$route.params.announcement_id}).then( (result) => {
      console.log('retorno da chamada a setListUsersChat em chat.vue', result)  
      this.listaDeConversas = result
      this.isloading = false
    })
    this.$store.dispatch('setImmobileOfTheChat', {
      announcementId: this.$route.params.announcement_id
    }).then( (result) => {
      this.isloading = false
      this.imovelDoChat = result
    })
    //teste chama o metodo teste
  },
  computed: {
    ...mapState({
      user: state => state.user,
      listUsersChat: state => state.listUsersChat,
      // listConversationsChat: state => state.listConversationsChat,
    }),
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='css' scoped>
@media screen and (min-width: 1024px) {
  .mensagens {
    height: 490px;
  }
}

@media screen and (max-width: 800px) {
  .mensagens {
    height: 150px;
  }
}

@media screen and (max-width: 600px) {
  .mensagens {
    height: 100px;
  }
}
</style>