<template>
  <nav aria-label="Page navigation example">
    <ul class="pagination">
      <li class="page-item">
        <a
          @click="prevNext($event, source.current_page-1)"
          :class="{disable: source.current_page === 1}"
          class="page-link"
          href="#"
          aria-label="Previous"
        >
          <span aria-hidden="true">&laquo;</span>
          <span class="sr-only">Anterior</span>
        </a>
      </li>
      <li
        v-for="(page,index) in pages"
        track-by="$index"
        :key="index"
        :class="{active: source.current_page === page}"
        class="page-item"
      >
        <a
          @click="navigate($event, page)"
          class="page-link"
          href="#"
        >{{ page }}</a>
      </li>
      <li class="page-item">
        <a
          @click="prevNext($event, source.current_page+1)"
          :class="{disable: source.current_page === source.last_page}"
          class="page-link"
          href="#"
          aria-label="Next"
        >
          <span aria-hidden="true">&raquo;</span>
          <span class="sr-only">Próxima</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
//range de opções do pagination
// import {range} from 'lodash'
export default {
  props: ['source'],
  data () {
    return {
      pages: {}
    }
  },
  watch: {
    source () {
      // this.pages = range(1, this.source.last_page+1)
      /*this.pages = range(1, this.source.per_page)
      console.log('pages', this.pages)*/
      let s = this.source
      this.pages = this.generatePagesArray(s.currentPage, s.total, s.per_page, 16)
    }
  },
  created () {
    let s = this.source
    this.pages = this.generatePagesArray(s.currentPage, s.total, s.per_page, 16)
  },
  methods: {
    navigate (event, page) {
      event.preventDefault()
      this.$emit('navigate', page)
    },
    prevNext (event, page) {
      if (page == 0 || page == this.source.last_page + 1) {
        return;
      }
      this.navigate(event, page)
    },
    generatePagesArray: function (currentPage, collectionLength, rowsPerPage, paginationRange) {
      var pages = [];
      var totalPages = Math.ceil(collectionLength / rowsPerPage);
      var halfWay = Math.ceil(paginationRange / 2);
      var position;

      if (currentPage <= halfWay) {
        position = 'start';
      } else if (totalPages - halfWay < currentPage) {
        position = 'end';
      } else {
        position = 'middle';
      }

      var ellipsesNeeded = paginationRange < totalPages;
      var i = 1;
      while (i <= totalPages && i <= paginationRange) {
        var pageNumber = this.calculatePageNumber(i, currentPage, paginationRange, totalPages);
        var openingEllipsesNeeded = (i === 2 && (position === 'middle' || position === 'end'));
        var closingEllipsesNeeded = (i === paginationRange - 1 && (position === 'middle' || position === 'start'));
        if (ellipsesNeeded && (openingEllipsesNeeded || closingEllipsesNeeded)) {
          pages.push('...');
        } else {
          pages.push(pageNumber);
        }
        i++;
      }
      return pages;
    },

    calculatePageNumber: function (i, currentPage, paginationRange, totalPages) {
      var halfWay = Math.ceil(paginationRange / 2);
      if (i === paginationRange) {
        return totalPages;
      } else if (i === 1) {
        return i;
      } else if (paginationRange < totalPages) {
        if (totalPages - halfWay < currentPage) {
          return totalPages - paginationRange + i;
        } else if (halfWay < currentPage) {
          return currentPage - halfWay + i;
        } else {
          return i;
        }
      } else {
        return i;
      }
    }
  }
}
</script>

<style>
</style>