<template>
  <div>
    <site-header />
    <div
      class="d-flex"
      id="wrapper"
    >
      <Sidebar></Sidebar>
      <!-- Page Content -->
      <div class="container-fluid">
        <!-- Loading -->
          <loading
            :active.sync="isLoading"
            :is-full-page="false"
            :can-cancel="false"
          ></loading>
          <!-- fim Loading -->
        <div v-if="userData" class="row">
          <div class="col-md-12">
            <!-- Profile Image -->
            <div class="card card-primary card-outline">
              <div class="card-body box-profile">
                <div class="text-center">
                  <img
                    :src="endpoint_images + userData.profile.photograph"
                    class="border rounded-circle border-0"
                    style="height: 200px; width:200px"
                    alt="Foto do perfil"
                  />
                  <!-- <img
                    v-else
                    src="https://png.pngtree.com/png-vector/20190710/ourmid/pngtree-user-vector-avatar-png-image_1541962.jpg"
                    class="border rounded-circle border-0"
                    style="height: 200px; width:200px"
                    alt="Foto do perfil"
                  /> -->
                </div>
                <h3
                  v-if="userData && userData.name != null"
                  class="profile-username text-center"
                >{{userData.name}}</h3>
                <!-- <p class="text-muted text-center">{{userData.role}}</p> -->
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <!-- About Me Box -->
            <div class="card card-primary">
              <div class="card-header pt-4">
                <div class="row">
                  <h3 class="card-title col-md-10">Meu Perfil</h3>
                  <router-link
                    :to="`/profileedit/${userData.id}`"
                    tag="a"
                  ><span class="fa fa-pencil-alt col-md-2 pr-1"></span>Editar</router-link>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body">

                <div>
                  <strong><i class="fas fa-user mr-1"></i>Nome completo</strong>
                  <p class="text-muted">{{userData.name}}</p>
                </div>

                <hr>

                <div>
                  <strong><i class="fas fa-sort-numeric-down   mr-1"></i>CPF</strong>
                  <p class="text-muted"
                  >{{userData.profile.cpf}}</p>
                  
                </div>

                <hr>

                <div>
                  <strong><i class="fas fa-mail-bulk mr-1"></i>E-mail</strong>
                  <p class="text-muted">{{userData.email}}</p>
                </div>

                <hr>

                <div>
                  <strong><i class="fas fa-mobile-alt mr-1"></i>Telefone</strong>
                  <p
                    class="text-muted"
                  >{{userData.profile.telephone}}</p>
                  
                </div>

                <hr>

                <div>
                  <strong><i class="fas fa-map-marker-alt mr-1"></i>Endereço</strong>
                  <p
                    class="text-muted"
                  >{{userData.profile.public_place}}, {{userData.profile.number}}, {{userData.profile.neighborhood}}, {{userData.profile.city}} - {{userData.profile.state}} - {{userData.profile.country}}</p>
                </div>

                <hr>

                <div>
                  <strong><i class="fas fa-map-marked-alt mr-1"></i>CEP</strong>
                  <p class="text-muted" >{{userData.profile.cep}}</p>
                </div>

                <hr>

                <div v-if="isRealtor">
                  <strong><i class="fas fa-id-badge  mr-1"></i>Creci</strong>
                  <p class="text-muted">{{userData.profile.registration_creci}}</p>
                </div>

                <hr>

              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>
        </div>
        <!-- /.row -->
      </div><!-- /.container-fluid -->

    </div>
    <!-- /#page-content-wrapper -->
  </div>
</template>

<script>
// Import do component de loading
import Loading from 'vue-loading-overlay'
import SiteHeader from '../../components/SiteHeader.vue';
//sidebar
import Sidebar from '../../components/Sidebar.vue';
//vuex
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'MyProfile',
  props: {
    msg: String
  },
  components: {
    SiteHeader,
    Sidebar,
    Loading
  },
  data () {
    return {
      isLoading: false,
      isLoadUser: false,
      isRealtor: false,
      endpointdev_user: process.env.VUE_APP_USER,
      urlbase: process.env.VUE_APP_URLBASE,
      endpoint_images: process.env.VUE_APP_IMAGES
    }
  },
  async mounted () {
    try {
      //loading
      if(!this.userData && this.userData.length < 0){
        this.isLoading = true
        // console.log('this.userData', this.userData)
      }else{
        this.isLoading = false
        console.log('this.user', this.user.user.role)
        let arrayDeRole = JSON.parse(this.user.user.role)
        //verifica se é corretor, se for, exibe a parte do cadastro de corretores
        if(arrayDeRole.includes("ROLE_REALTOR")){
          this.isRealtor = true
        }
      }
      
    } catch (error) {
      console.log('error', error);
      console.log('visitante');
    }
  },
  computed: {
    ...mapGetters(['getUser', 'geAlltImmobiles']),
    ...mapState(['user']),
    userData () {
      return this.user.user
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import "../../assets/styles/bootstrap4/bootstrap.min.css";
@import "../../assets/styles/simple_sidebar.css";
</style>
