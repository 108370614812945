<template>
  <div>
      
      <site-header/>

      <loading :active.sync="isLoading" 
        :can-cancel="true" 
        :on-cancel="onCancel"
        :is-full-page="fullPage"></loading>

	<div class="row h-100 justify-content-center align-items-center mb-5" style="margin-top:20px">
		<div class="card">
			
		</div>
		<div class="col-xs-12 col-sm-8 col-md-6"><!-- col-sm-offset-2 col-md-offset-3 -->
			<form class="">
				<fieldset>
					<h2>Redefinir Senha</h2>
					<hr class="colorgraph">
                    <!-- Senha -->
					<div class="form-group">
						<input v-model="password" id="password" class="form-control input-lg" :class="{ 'is-invalid': $v.password.$dirty && $v.password.$invalid}" type="password" placeholder="Senha">
                        <small class="text-danger" v-if="$v.password.$dirty && $v.password.$invalid">A senha é obrigatória.</small>
					</div>
                    <!-- Confirme senha -->
                    <div class="form-group">
						<input v-model="password_confirm" class="form-control input-lg" :class="{ 'is-invalid': $v.password_confirm.$dirty && $v.password_confirm.$invalid}" placeholder="Repetir Senha" type="password" >
                        <small class="text-danger" v-if="$v.password_confirm.$dirty && $v.password_confirm.$invalid">A confirmação de senha é obrigatória. E deve ser igual a senha.</small>
					</div>
					<hr class="colorgraph">
					<div class="row">
						<div class="col-xs-12 col-sm-12 col-md-12">
							<button @click.prevent="enviar" type="button" class="btn btn-lg btn-success btn-block botao_salvar">Enviar</button>
						</div>
					</div>
				</fieldset>
			</form>
		</div>
	</div>

</div>
</template>
   
<script>
    
    import { required, sameAs } from 'vuelidate/lib/validators'
    import axios from 'axios'
    import swal from 'sweetalert'
    // Import do component de loading
    import Loading from 'vue-loading-overlay';
    // Import do stylesheet do loading
    import 'vue-loading-overlay/dist/vue-loading.css';
    import SiteHeader from '../../components/SiteHeader.vue';

    //importando variaveis de endpoints
    import {constantes} from '../../consts.js'

export default {
    name: 'ResetPassword',
    components: {
        Loading,
        SiteHeader
    },
    props: {
        msg: String
    },
    data () {
        return {
            password: '',
            password_confirm:'',
            isLoading: false,
            fullPage: true,
            endpoint: constantes.endpoint_reset,
        }
    },
    validations: {
        password: {
            required,
        },
        password_confirm: {
            required,
            sameAsPassword: sameAs('password')
        }
    },
    methods: {
        enviar () {
            this.$v.password.$touch()
            this.$v.password_confirm.$touch()
            if ( this.$v.password.$invalid || this.$v.password_confirm.$invalid ) {
                    //exibe os erros no HTML
            } else {
                //tudo certo pode prosseguir
                var formData = new FormData()
                // passa a senha
                formData.append('password', this.password)
                // passa a confirmação da senha
                formData.append('password_confirm', this.password_confirm)
                // passa o token
                formData.append('token', this.$route.params.token)
                //loading
                this.isLoading = true
                //envia os dados do form para a API para enviar email ao clienet
                axios.post(this.endpoint, formData).then( (response) => {
                    console.log(response.data);
                    this.isLoading = false
                    swal('Sucesso', 'Sua senha foi alterada.', 'success').then( () => {
                        this.$router.push({ path: '/login' })
                    })
                }).catch( (error) => {
                    swal('Houve um Erro', error.message, 'error').then( () => {

                    })
                })
            }
        },
        onCancel() {
            console.log('User cancelled the loader.')
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/css?family=Italianno');

.botao_salvar {padding-left:10px; padding-right:10px; border-radius: 32px !important;}
.termos-de-uso{
    text-align: left;
    font-family: 'Roboto', sans-serif;
    line-height: 1em;
}
h2{
    font-family: 'Italianno', sans-serif;
    font-size: 68px;
    text-shadow: 4px 4px 4px rgb(230, 220, 220);
    /* color:#047204; */
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
