<template>
<div>
    <site-header/>
    <div class="d-flex" id="wrapper">
        <!-- Sidebar -->
        <sidebar/>
        <!-- Page Content -->
        <div class="container-fluid">
            <div class="row ml-2 mr-2" >
                <div class="page-header col-md-2" align="center">
                    <h2>Favoritos</h2>
                </div>
                <!-- Immobile Box -->
                <div class="container">
                    <div class="row">  
                        <div class="col-md-3 m-4" v-for="(immobile, index) in infoImmobile" :key="index">
                            <FavoriteBox :immobileBox="immobile"/>
                        </div>
                    </div>
                </div>
                
                <!-- End Immobile Box -->
                
            </div>
        </div>
            

    <!-- End Page Content-->
        
    </div>

    
    
</div>  
</template>

<script>
import FavoriteBox from '../../components/FavoriteBox.vue';
import SiteHeader from '../../components/SiteHeader.vue';
//importando axios
import axios from 'axios' 
//importando variaveis de endpoints
import Sidebar from '../../components/Sidebar.vue';
import {mapState} from 'vuex'

export default {
  name: 'Favorite',
  components: {
      FavoriteBox,
      SiteHeader,
      Sidebar
  },
  data () {
    return {
        infoImmobile: [],
        endpoint_user: process.env.VUE_APP_USER,
        endpoint_favorite: process.env.VUE_APP_FAVORITE,
        user_id: null,
    }
  },
  async mounted(){
    // pegando o id do usuario logado
    const usuario = await this.user
    this.user_id = await usuario.user.id
    
    /* pegando os favoritos do usuario logado */
    const response_favorite = await axios.get(this.endpoint_favorite + this.user_id, 
        { headers: {
        'Content-type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
    this.infoImmobile = response_favorite.data.data;

    },
    computed: {
        ...mapState(['user']),
    },
    methods: {
    },  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* Importando o bootstrap 4 */
@import '../../assets/styles/bootstrap4/bootstrap.min.css';

/* Importando o estilo da pagina*/
@import '../../assets/styles/simple_sidebar.css';

/*Estilo para editar o tamanho da imagem do usuario que postou o imovel*/
#img-profile{
    position: relative;
    width: 80%;
    height: 80%;
    border-radius: 90%;
}

/* Estilo para editar o tomanho do icone de area */
#icon-area{
    position: relative;
    width: 8%;
    height: 90%;    
}

/*Estilo para retirar sublinhado do texto dentro de um link */
#link-immobile{
    text-decoration: none;
    color: black;
}

/*Estilo para editar imagem*/
#img-immobile-text{
    position: absolute;
    top: 0%;
    margin-left: 0%;
    padding: 1%;
    color: white;
    background: rgba(255, 0, 0, .7);
    border-radius: 10%;
}
</style>
