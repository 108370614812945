<template>
  <div>
    <site-header />
    <div
      class="d-flex"
      id="wrapper"
    >

      <!-- Sidebar -->
      <Sidebar></Sidebar>
      <loading
        :active.sync="isLoading"
        :can-cancel="true"
        :on-cancel="onCancel"
        :is-full-page="fullPage"
      />

      <div class="container-fluid">
        <div class="text-center mt-2">
          <h3>Lista de Vendedores</h3>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="row">
                <div class="col-md-9">
                  <input
                    v-model="filter.name"
                    class="form-control"
                    id="compra_aluguel"
                    placeholder="Informe um nome para buscar..."
                    type="text"
                  >
                </div>
                <div class="col-md-3">
                  <button
                    @click.prevent="filterUsers"
                    type="button"
                    class="btn btn-info"
                  >Buscar</button>
                </div>
            </div>
          </div>
        </div>
        <div v-if="listUsersExists">
          <table class="table table-borderless mt-5">
            <tbody>
              <tr
                v-for="user in listUsers"
                :key="user.id"
              >
                <div class="card">
                  <div class="card-body">
                    <td class="col-md-1 pl-4 align-middle">
                      <img
                        v-if="user.profile !== null && user.profile.photograph || user.profile.telephone !== '(99) 99999-9999'"
                        width="48"
                        height="48"
                        :src="endpoint_images+user.profile.photograph"
                        :alt="'Foto do Usuário'"
                        class="rounded-circle"
                      >
                      <img
                        v-else
                        width="48"
                        height="48"
                        :src="fotoUsuarioPadrao"
                        :alt="'Foto do Usuário'"
                        class="rounded-circle"
                      >
                    </td>
                    <td class="col-md-4 align-middle">{{user.name}}<span class="font-italic blockquote-footer">{{user.email}}</span></td>
                    <td class="col-md-3 align-middle">{{user.profile.telephone}}</td>
                    <td
                      v-if="user.status === 'ATIVO' || user.status === null"
                      class="col-md-2 align-middle"
                    >
                      <p
                        id="situation-active"
                        class="text-success"
                      >Ativo</p>
                    </td>
                    <td
                      v-else
                      class="col-md-2 align-middle"
                    >
                      <p
                        id="situation-inactive"
                        class="text-danger"
                      >Inativo</p>
                    </td>
                    <td class="col-md-2">
                      <div class="dropdown">
                        <a
                          class="btn dropdown-toggle border border-primary text-primary"
                          href="#"
                          role="button"
                          id="dropdownMenuLink"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Ações
                        </a>
                        <div
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuLink"
                        >
                          <a
                            class="dropdown-item text-primary"
                            href="#"
                            :data-id="user.id"
                            @click="visualizarPersilUsuario(user.id)"
                          ><span class="fas fa-user pr-2"></span>Visualizar perfil</a>
                          <a
                            v-if="user.status === 'ATIVO' || user.status === null"
                            class="dropdown-item text-warning"
                            href="#"
                            @click="desativarUsuario(user.id)"
                          ><span class="fas fa-times-circle pr-2"></span>Desativar Usuário</a>
                          <a
                            v-else
                            class="dropdown-item text-success"
                            href="#"
                            @click="ativarUsuario(user.id)"
                          ><span class="fas fa-check-circle pr-2"></span>Ativar Usuário</a>
                          <!-- <a
                            class="dropdown-item text-danger"
                            href="#"
                            @click="deletarUsuario(user.id)"
                          ><span class="fas fa-trash-alt pr-2"></span>Deletar Usuário</a> -->
                        </div>
                      </div>
                    </td>
                  </div>
                </div>

                <!-- <hr
                      width="95%"
                      align="center"
                    > -->
              </tr>

            </tbody>
          </table>
          <div class="d-flex content-justify-center">
            <div class="text-center">
              <paginate
                :source="pagination"
                @navigate="navigate"
              ></paginate>
            </div>
          </div>
        </div>
        <div v-else>
          <h5 class="text-center mt-5">Não existem usuários a exibir</h5>
        </div>
      </div>
    </div>
    <!-- /#wrapper -->
  </div>
</template>

<script>
import SiteHeader from '../../components/SiteHeader.vue';
import Sidebar from '../../components/Sidebar.vue';
//sweet alert
import swal from 'sweetalert'
import alerta from 'sweetalert2'
//importa o axios e o http
import { http } from '@/http';
// Import do component de loading
import Loading from 'vue-loading-overlay'
//import mapState
import { mapState } from 'vuex'
// paginação
import Paginate from '../../components/Paginate.vue'
//importando lodash
import { debounce } from 'lodash'

export default {
  name: 'ListSellers',
  components: {
    SiteHeader,
    Loading,
    Sidebar,
    Paginate
  },
  props: {
  },
  data () {
    return {
      filter: {
        name: '',
        availability: '',

      },
      listUsers: [],
      pagination: {},
      idUserLogged: 0,
      endpoint_sellers: process.env.VUE_APP_USERS_LIST_FOR_ADMINS,
      endpoint_images: process.env.VUE_APP_IMAGES,
      endpoint_suspend_user: process.env.VUE_APP_SUSPEND_USER_FOR_ADMINS,
      isLoading: false,
      fullPage: false,
      listUsersExists: false,
      fotoUsuarioPadrao: require('../../assets/img/usuario_padrao.jpg'),
      contagem: 0,
    }
  },
  async mounted () {
    try {
      this.isLoading = true;
      /* pegando o id do usuario logado */
      this.idUserLogged = await this.user.user.id;
      await http.get(this.endpoint_sellers + this.idUserLogged,
        {
          headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
          }
        }).then((response) => {
          // console.log('response', response);
          if (response) {
            this.isLoading = false
            let retorno = response.data.data
            this.pagination = response.data
            this.populaListaDeUsuarios(retorno)
          }
          console.log('this.listUsers', this.listUsers)
        }).catch((error) => {
          console.log('error', error)
          this.isLoading = false
          swal('Erro', 'Não foi possível localizar os dados do usuário', 'error')
            .then(() => {

            })
        })
    } catch (error) {
      console.log('error', error);
      console.log('visitante');
    }
  },
  computed: {
    ...mapState(['user'])
  },
  methods: {
    debounceUsers: debounce(function () {
      this.filterUsers();
    }, 500),
    async filterUsers () {
      //loading
      this.isLoading = true;
      //monta a query string para a url enviada a API
      const query = Object.keys(this.filter).map((key) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(this.filter[key])}`
      }).join('&');
      var dados = await http.get(this.endpoint_sellers + this.user.user.id + '?' + query,
      {
          headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
          }
      }).then(response => {
        this.isLoading = false;
        return { response: [...response.data.data], pagination: response.data}
      }).catch(function (error) {
        console.log(error);
      })
      this.contagem++
      this.listUsers = dados.response
      this.pagination = dados.pagination
    },
    //popula a listagem de usuários compradores
    populaListaDeUsuarios (listaDeUsuarios) {
      console.log('listagem filtrada', listaDeUsuarios)
      listaDeUsuarios.forEach( element => {
        if (element.profile) {
          this.listUsers.push(
            {
              id: element.id,
              name: element.name,
              email: element.email,
              status: element.status,
              role: element.role,
              profile: {
                telephone: element.profile.telephone,
                photograph: element.profile.photograph
              }
            })
            
        } else {
          this.listUsers.push(
            {
              id: element.id,
              name: element.name,
              email: element.email,
              status: element.status,
              role: element.role,
              profile: {
                telephone: '(99) 99999-9999',
                photograph: require('../../assets/img/usuario_padrao.jpg'),
                // photograph:'https://png.pngtree.com/png-vector/20190710/ourmid/pngtree-user-vector-avatar-png-image_1541962.jpg',
              }
            })
        }
      });
      //variável para verificar se tem usuários na listagem, se executou o código acima é porque existe lista de usuários
      this.listUsersExists = true
    },
    /** método para navegação  */
    navigate (page) {
      // console.log('navigate', page)
      this.loading = true
      http.get(this.endpoint_sellers + this.user.user.id + '?page=' + page)
        .then(response => {
          this.loading = false
          this.listUsers = response.data.data
          this.pagination = response.data
          // console.log('this.listUser', response.data.data)
        })
        .catch(function (error) {
          console.log(error);
        })
        .then(function () {

        })
    },
    /** método para visualizar perfil do usuário */
    visualizarPersilUsuario (id) {
      if (id) {
        this.$router.push({ name: 'ShowProfileUserForAdmins', params: { id: id } })
      } else {
        alerta.fire({
          icon: 'info',
          showCloseButton: true,
          showCancelButton: true,
          cancelButtonText: '<i class="fa fa-thumbs-down"></i>',
        })
      }
    },
    /** método para desativar usuário */
    desativarUsuario (id) {
      try {
        var dados = new FormData()
        //passando o id do corretor a ser bloqueado
        dados.append('id', id)
        //exibe o loading
        this.isLoading = true;
        //envia os dados do form para a API para salvar o usuário
        http.post(
            this.endpoint_suspend_user, 
            dados,
            { headers: {
              'Content-type': 'multipart/form-data',
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + localStorage.getItem('token')
              } 
            }).then( (response) => {
                console.log('resposta =',response.data)
                this.isLoading = false;
                alerta.fire({
                  icon: 'success',
                  title: 'Comprador Bloqueado com sucesso.',
                  text: 'Um aviso foi enviado ao email do comprador para que entre em contato com a administração do clube de imóveis.',
                  showCloseButton: true,
                }).then( ()=>{
                  window.location.reload()
                })
        }).catch( (error) => {
            alerta.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Algo saiu errado'+error.message,
              showCloseButton: true,
              showCancelButton: true,
              cancelButtonText: '<i class="fa fa-thumbs-down"></i>',
            })
        })
      } catch (error) {
        alerta.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Algo saiu errado'+error.message,
              showCloseButton: true,
              showCancelButton: true,
              cancelButtonText: '<i class="fa fa-thumbs-down"></i>',
            })
      }
      
    },
    /** método para ATIVAR usuário */
    ativarUsuario(id) {
      try {
        var dados = new FormData()
        //passando o id do corretor a ser bloqueado
        dados.append('id', id)
        //exibe o loading
        this.isLoading = true;
        //envia os dados do form para a API para salvar o usuário
        http.post(
            this.endpoint_suspend_user, 
            dados,
            { headers: {
              'Content-type': 'multipart/form-data',
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + localStorage.getItem('token')
              } 
            }).then( (response) => {
                console.log('resposta =',response.data)
                this.isLoading = false;
                alerta.fire({
                  icon: 'success',
                  title: 'Comprador Bloqueado com sucesso.',
                  text: 'Um aviso foi enviado ao email do comprador para que entre em contato com a administração do clube de imóveis.',
                  showCloseButton: true,
                }).then( ()=>{
                  window.location.reload()
                })
        }).catch( (error) => {
            alerta.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Algo saiu errado'+error.message,
              showCloseButton: true,
              showCancelButton: true,
              cancelButtonText: '<i class="fa fa-thumbs-down"></i>',
            })
        })
      } catch (error) {
        alerta.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Algo saiu errado'+error.message,
              showCloseButton: true,
              showCancelButton: true,
              cancelButtonText: '<i class="fa fa-thumbs-down"></i>',
            })
      }
      
    },
    onCancel () {
      console.log('cancelou o loading');
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>