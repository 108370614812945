.<template>
   <div class="row position-static ml-5">
     <div class="card mb-3 shadow-sm p-2 bg-white rounded" style="width: 97%;">
       <div v-if="isLoading">
          <VueSkeletonLoader
            v-if="isLoading"
            type="rect"
            :width="400"
            :height="20"
            animation="wave"
          />
          <VueSkeletonLoader
            class="mt-1"
            v-if="isLoading"
            type="rect"
            :width="200"
            :height="20"
            animation="wave"
          />
        </div>
        <div v-else class="card-title border-bottom">
            <h6 class="text-center"><ins>Comentários</ins></h6>
        </div>  
        <div class="card-body">
          <div v-if="comments">
            <blockquote  v-for="(i,index) in comments" :key="index" class="blockquote mb-4">
              <p>{{i.comment}}</p>
              <footer class="small text-secondary" style="margin-top:-20px"> <cite title="teste">{{i.user.name}} <small style="font-size:0.65em">{{i.created_at | formatDate}}</small></cite></footer>
            </blockquote>
          </div>
          <div v-else-if="!comments">
            <p>Não há comentários deste imóvel,</p>
          </div>
          <div v-else-if="!localStorage.getItem('user')">
            <p>Não há comentários deste imóvel,</p>
          </div>
        </div>
      </div>
   </div>
</template>

<script>
//importa a moment.js
import moment from 'moment'
//importa o skeleton
import VueSkeletonLoader from 'skeleton-loader-vue';
//importa os getters do vuex
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  components : {
    VueSkeletonLoader
  },
  data () {
    return {
      isLoading: true,
      fullPage: false,
      idDoAnuncio: this.$route.params.id,
      // path: process.env.VUE_APP_COMMENTS_OF_THE_ANNOUNCEMENT,
    }
  },
  filters: {
    formatDate (value) {
      if(value){
        return moment(String(value)).format('DD/MM/YYYY hh:mm')
      }
    }
  },
  async mounted () {
    try {
      console.log('parametro passado na rota',this.$route.params.id)
    //  let annoucementId = await this.announcement.id
     let annoucementId = await this.$route.params.id
      console.log('this.announcementId', annoucementId)
      await this.getComments({ id: annoucementId }).then( (response) => {
        console.log('retorno obter todos os comentarios', response)
      } ).catch( (error)=> {
        console.log('error ao obter comentarios', error)
      })
      this.isLoading = false; 
    } catch (error) {

      this.isLoading = false
    } 
  },
  computed: {
    ...mapState(['announcement', 'comments']),
    ...mapGetters(['getUser', 'getAnnouncement'])
  },
  methods: {
    ...mapActions({
      getComments: (dispatch, payload, options) =>{
          return dispatch('allCommentsOfTheAnnouncement', payload, options)
      }
    }),
    /*async getComments (annoucementId) {
      let token = localStorage.getItem('token')
      return new Promise( (resolve, reject)=> {
        axios(
          {
            method: 'get',
            url: this.path + annoucementId, 
            headers: {
              Authorization: 'Bearer ' + token
            }
          }
        ).then( (response)=> {
          console.log('response.data', response.data)
          this.comments = response.data;
          resolve(response.data)
        }).catch( (error)=> {
          reject(error)
        });
      })
    },*/
    onCancel () {
      console.log('User cancelled the loader.')
    },
  },
}
</script>

<style>

</style>