<template>
  <div>
    <!-- Loading -->
    <loading
      :active.sync="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    ></loading>
    <!-- fim Loading -->

    <div class="row d-flex justify-content-center">
      <div
        class="col-md-12"
        style="background-color:#fff"
      >
        <div class="mx-auto">
          <h1 class="logomarca">Clube de Imóveis </h1>
          <h3 class="subtitle mt-2 mb-3">Código de confirmação</h3>
          <p class="paragraph"> Informe abaixo o código de confirmação que foi enviado para seu email.</p>
        </div>
      </div>
    </div>

    <div
      class="d-flex align-items-center justify-content-center"
      style="height: 150px"
    >
      <div class="p-2 bd-highlight col-example">
        <input
          @input="setFocusTwo"
          v-model="one"
          :class="{ 'is-invalid': $v.one.$dirty && $v.one.$invalid}"
          ref="one"
          type="text"
          class="input-confirmation"
          size="1"
        >
        <small
          class="text-danger"
          v-if="$v.one.$dirty && $v.one.$invalid"
        >Código obrigatória.</small>
      </div>
      <div class="p-2 bd-highlight col-example">
        <input
          @input="setFocusThree"
          v-model="two"
          :class="{ 'is-invalid': $v.two.$dirty && $v.two.$invalid}"
          ref="two"
          type="text"
          class="input-confirmation"
          size="1"
        >
        <small
          class="text-danger"
          v-if="$v.two.$dirty && $v.two.$invalid"
        >Código obrigatória.</small>
      </div>
      <div class="p-2 bd-highlight col-example">
        <input
          @input="setFocusFour"
          v-model="three"
          :class="{ 'is-invalid': $v.three.$dirty && $v.three.$invalid}"
          ref="three"
          type="text"
          class="input-confirmation"
          size="1"
        >
        <small
          class="text-danger"
          v-if="$v.three.$dirty && $v.three.$invalid"
        >Código obrigatória.</small>
      </div>
      <div class="p-2 bd-highlight col-example">
        <input
          type="text"
          v-model="four"
          :class="{ 'is-invalid': $v.four.$dirty && $v.four.$invalid}"
          ref="four"
          class="input-confirmation"
          size="1"
        >
        <small
          class="text-danger"
          v-if="$v.four.$dirty && $v.four.$invalid"
        >Código obrigatória.</small>
      </div>
    </div>

    <div class="text-center mt-4 mb-5">
      <button
        @click="sendConfirmation"
        class="btn btn-success"
      >Confirmar</button>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import swal from 'sweetalert'
import { required } from 'vuelidate/lib/validators';
// Import do component de loading
import Loading from 'vue-loading-overlay';
// Import do stylesheet do loading
import 'vue-loading-overlay/dist/vue-loading.css';


export default {
  components: {
    Loading
  },
  data () {
    return {
      one: '',
      two: '',
      three: '',
      four: '',
      isLoading: false,
      fullPage: true,
      endpoint: process.env.VUE_APP_CONFIRMATION_CODE,
    }
  },
  validations: {
    one: { required },
    two: { required },
    three: { required },
    four: { required },
  },
  methods: {
    setFocusOne: function () {
      this.$refs.one.focus()
    },
    setFocusTwo: function () {
      this.$refs.two.focus()
    },
    setFocusThree: function () {
      this.$refs.three.focus()
    },
    setFocusFour: function () {
      this.$refs.four.focus()
    },
    onCancel () {
      console.log('User cancelled the loader.')
    },
    sendConfirmation: function () {
      const self = this;
      this.$v.one.$touch();
      this.$v.two.$touch();
      this.$v.three.$touch();
      this.$v.four.$touch();
      if (!this.$v.one.$invalid || !this.$v.two.$invalid || !this.$v.three.$invalid || !this.$v.four.$invalid) {
        let dados = new FormData();
        let verificationCode = this.one + this.two + this.three + this.four;
        //concatena o endpoint com o id do usuário
        let url = this.endpoint + this.$route.params.id
        console.log(verificationCode);
        dados.append('verification_code', verificationCode);
        this.isLoading = true;
        axios.post(url,
          dados,
        ).then((response) => {
          // if(response.data.data === 1)
          console.log(response.data)
          swal('Sucesso', 'Código confirmado com sucesso. \n Aguarde a liberação do seu acesso pelo administrador do Clube de Imóveis. Você será avisado por email.', 'success').then(() => {
            self.isLoading = false;
            self.$router.push({ path: '/realtor-login' })
          })
          // }
        }).catch((error) => {
          swal('Erro', error.message, 'error').then(() => {
            self.isLoading = false;
          })
        })
      }
    }
  },
}
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lobster&family=Sacramento&display=swap");

.logomarca {
  color: #000;
}

.subtitle {
  font-family: "Montserrat", sans-serif;
  font-size: 2em;
  margin: auto;
  text-align: center;
}

.paragraph {
  font-family: "Montserrat", sans-serif;
  font-size: 1em;
  margin: auto;
  text-align: center;
}

.input-confirmation {
  display: block;
  width: 50px;
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.divcentralizada {
  height: 100%;
  width: 200px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: row;
}
</style>