<template>
  <div>
    <site-header />
    <loading
      :active.sync="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    ></loading>

    <div
      class="row h-100 justify-content-center align-items-center mb-5"
      style="margin-top:20px"
    >
      <div class="card">

      </div>
      <div class="col-xs-12 col-sm-8 col-md-6">
        <!-- col-sm-offset-2 col-md-offset-3 -->
        <form class="">
          <fieldset>
            <h2>Redefinição de Senha</h2>
            <hr class="colorgraph">
            <!-- Email -->
            <div class="form-group">
              <input
                type="email"
                v-model="email"
                id="email"
                class="form-control input-lg"
                :class="{ 'is-invalid': $v.email.$dirty && $v.email.$invalid}"
                placeholder="Email"
              >
              <small
                class="text-danger"
                v-if="$v.email.$dirty && $v.email.$invalid"
              >O email é obrigatório.</small>
            </div>
            <hr class="colorgraph">
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-12">
                <button
                  @click.prevent="enviar"
                  type="button"
                  class="btn btn-lg btn-success btn-block botao_salvar"
                >Enviar</button>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </div>

  </div>
</template>
   
<script>

import { mask } from 'vue-the-mask'
import { required, email } from 'vuelidate/lib/validators'
import axios from 'axios'
import swal from 'sweetalert'
// Import do component de loading
import Loading from 'vue-loading-overlay';
// Import do stylesheet do loading
import 'vue-loading-overlay/dist/vue-loading.css';
import SiteHeader from '../../components/SiteHeader.vue';


//importando variaveis de endpoints
import { constantes } from '../../consts.js'

export default {
  directives: { mask },
  name: 'ForgotPassword',
  components: {
    Loading,
    SiteHeader
  },
  props: {
    msg: String
  },
  data () {
    return {
      email: '',
      isLoading: false,
      fullPage: true,
      endpoint: constantes.endpoint_forgot,
    }
  },
  validations: {
    email: {
      required,
      email
    }
  },
  methods: {
    enviar () {
      this.$v.email.$touch()
      if (this.$v.email.$invalid) {
        //exibe os erros no HTML
      } else {
        //tudo certo pode prosseguir
        var formData = new FormData()
        //passa o email
        formData.append('email', this.email)
        //envia os dados do form para a API para enviar email ao clienet
        this.isLoading = true
        axios.post(this.endpoint, formData).then((response) => {
          console.log(response.data);
          this.isLoading = false
          swal('Email enviado', 'enviamos um e-mail para ' + this.email + ' com as instruções e o link para você trocar a senha. caso você não receba o e-mail em alguns minutos, verifique a sua caixa de spam ou repita o processo.', 'success').then(() => {
            // this.$router.push({ name: 'ResetPassword', params: { token: response.data.token } })
          })
        }).catch((error) => {
          swal('Houve um erro', 'Não foi possível realizar a operação entre em contato conosco. \n ' + error.message, 'error').then(() => { })
        })
      }
    },
    onCancel () {
      console.log('User cancelled the loader.')
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url("https://fonts.googleapis.com/css?family=Roboto");
@import url("https://fonts.googleapis.com/css?family=Italianno");

.botao_salvar {
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 32px !important;
}
.termos-de-uso {
  text-align: left;
  font-family: "Roboto", sans-serif;
  line-height: 1em;
}
h2 {
  font-family: "Italianno", sans-serif;
  font-size: 68px;
  text-shadow: 4px 4px 4px rgb(230, 220, 220);
  /* color:#047204; */
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
