<template>
  <div id="app" class="conteudo_principal">
    <site-header />

    <section class="secao_principal pt-5">

      <!-- busca do airbnb -->
      <div class="search-sec">
        <h3 class="titulo_tabs">O que você procura?</h3>
        <form
          action="#"
          method="post"
          novalidate="novalidate"
        >

          <div class="inputs_busca">
            <div class="d-flex justify-content-center">
              <nav class="tabs_busca">
                <a
                  @click.prevent="filterImmobiles(true,false)"
                  class="link_tabs_busca"
                  style="color:#fff"
                >Compra</a>
                <a
                  @click.prevent="filterImmobiles(false,true)"
                  class="link_tabs_busca"
                  href="#"
                >Aluguel</a>
                <a
                  class="link_tabs_busca"
                  href="#"
                  style="width: 165px; padding-left:7px; padding-right:7px;"
                >Encontrar Corretor</a>
              </nav>
            </div>
            <div class="inputs">
              <div class="row">
                <div class="col-lg-3 col-md-3 col-sm-12 pl-1">
                  <select
                    v-model="filter.type_id"
                    class="form-control search-slt"
                    name="immobile_type"
                    id="compra_aluguel"
                    
                  >
                    <option>Selecione....</option>
                    <option value="" selected>Todos os tipos</option>
                    <optgroup label="Residencial">
                      <option value="2">Apartamento</option>
                      <option value="1">Casa</option>
                      <option value="3">Chácara</option>
                      <option value="4">Cobertura</option>
                      <option value="5">Duplex</option>
                      <option value="8">Estúdio</option>
                      <option value="10">Flat</option>
                      <option value="12">Garagem</option>
                      <option value="13">Kitnet</option>
                      <option value="14">Loft</option>
                      <option value="16">Lote</option>
                      <option value="21">Sobrado</option>
                      <option value="22">Terreno</option>
                    </optgroup>
                    <optgroup label="Comercial">
                      <option value="1">Casa (comercial)</option>
                      <option value="6">Edifício</option>
                      <option value="7">Escritorio</option>
                      <option value="11">Galpão</option>
                      <option value="15">Loja</option>
                      <option value="17">Negócio</option>
                      <option value="19">Prédio</option>
                      <option value="18">Ponto Comercial</option>
                      <option value="16">Lote (comercial)</option>
                    </optgroup>
                    <optgroup label="Rural">
                      <option value="9">Fazenda</option>
                      <option value="20">Sítio</option>
                    </optgroup>
                  </select>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12">
                  <select
                    v-model="filter.availability"
                    class="form-control search-slt"
                    name="compra_aluguel"
                    id="compra_aluguel"
                    aria-placeholder="Compra ou Aluguel"
                  >
                    <option
                      value=""
                      selected
                      disabled
                    >Comprar ou alugar</option>
                    <option value="Vender">Comprar</option>
                    <option value="Alugar">Alugar</option>
                  </select>
                </div>
                
                <div class="col-lg-3 col-md-3 col-sm-12 pl-2">
                  <button
                    @click.prevent="filterImmobiles"
                    type="button"
                    class="btn btn-danger wrn-btn"
                  >Buscar</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
    <!-- fim gusca -->

    <div class="row p-2">
        <div class="col-md-12 text-right p-4">
          <h4>
            <button type="button" class="btn btn-secondary">
              <span><router-link to="/" style="color:#fff"> Ver Lista </router-link></span>
            </button>
          </h4>
        </div>
    </div>

    <!-- Visualização no Mapa -->
    <div class="row m-1">
      <div class="col-md-6 overflow-auto" style="max-height:526px">
        <div class="row">
          <div class="col-md-6" v-for="(immobile, index) in immobiles" :key="index">
            <ImmobileBox :immobileBox="immobiles[index] " />
            <!-- exibe loading se não localizar imóveis -->
            <Esqueleto
              v-if='loading' 
              width='300px'
              height='300px'
            />
            <!-- Loading -->
            <div>
              <loading
                :active.sync="isLoading"
                :is-full-page="false"
                :can-cancel="false"
              ></loading>
            </div>

          </div>
          <!-- paginate -->
          <!-- <div class="text-center">
            <paginate :source="pagination" @navigate="navigate"></paginate>
          </div> -->
        </div>
      </div>
      <div class="col-md-6">
        <mapa v-if="imoveisCarregados" :imoveis="imoveisMapa" @lista-imoveis-regiao-mapa="listaImoveisMapaAtualizado" class="col-md-12" style="max-height:526px"></mapa>
      </div>
    </div>

  </div>

</template>

<script>

// import axios from 'axios'
import axios from 'axios'
//componente immobileBox
import ImmobileBox from '../../components/ImmobileBox.vue'
//componente squeleton
import Esqueleto from '../../components/Esqueleto.vue'
/* Importantando a navbar do site */
import SiteHeader from '../../components/SiteHeader.vue'
//importando lodash
import {debounce} from 'lodash'
// paginação
// import Paginate from '../../components/Paginate.vue'
// Import do component de loading
import Loading from 'vue-loading-overlay'
//teste google maps
// import { Loader } from '@googlemaps/js-api-loader';

//mapa de teste
import Mapa from '../../components/mapa/Mapa.vue'

export default {
  name: 'FrontPage',
  components: {
    Mapa,
    ImmobileBox,
    SiteHeader,
    Esqueleto,
    // Paginate,
    Loading
  },
  data () {
    return {
      ins: [1,2,3,4,5,6,7,8,9,10,11,12],
      markers: null,
      paths: [],
      visualizacaoNoMapa: false,
      isLoading: false,
      immobiles: [],
      imoveisMapa: [],
      imoveisCarregados: false,
      imoveisFiltrados: [],
      pagination: {},
      bdsSouth: null,
      bdsNorth: null,
      filter: {
        type_id: '',
        availability: '',

      },
      endpoint_immobile_index: process.env.VUE_APP_IMMOBILEINDEX,
      loading: {
        default: true,
        type: Boolean
      },
      contagem: 0,
    }
  },
  props: {

  },
  async mounted() {
    //chama a função que retorna a lista de imóveis primeiramente sem filtros
    await this.getImmobiles();
  },
  beforeUpdate() {
    this.loading = false

  },
  methods: {
    getImmobiles() {
      //lista de imóveis da API
      axios.get(this.endpoint_immobile_index)
      .then(response => { 
          this.immobiles = response.data.data;
          this.pagination = response.data;
          this.imoveisCarregados = true;
          this.imoveisMapa = response.data.data;
          // this.carregaMapa(this.immobiles[0].latitude, this.immobiles[0].longitude)
        })
      .catch(function (error) {
        console.log(error);
      })
    },
    listaImoveisMapaAtualizado(imoveisDentroBoundsMapa){
      console.log('lista de imóveis atualizada', imoveisDentroBoundsMapa);
      this.immobiles = imoveisDentroBoundsMapa;
    },
    /*carregaMapa(lat, lng){
      const loader = new Loader({
        apiKey: "AIzaSyDOF_uaOR63rpqAXBDjhJ3B4siyzifdraY",
        version: "weekly",
        libraries: ["places"]
      });
      const mapOptions = {
        center: {
          lat: parseFloat(lat),
          lng: parseFloat(lng)
        },
        zoom: 15
      };
      loader.load().then((google) => {
        console.log('google.maps', google.maps)
        new google.maps.Map(this.$refs.mapa, mapOptions);
        console.log('this.$refs.mapa', this.$refs.mapa)
        console.log('mapOptions', mapOptions)
      })
      .catch(e => {
        // do something
        console.log(e)
      });
    },*/
    atualizaListaImoveisConformeMapa(){
      // console.log(this.gmapsloader)
      // let bounds = new google.maps;
      // console.log('this.$refs.gmap.$mapObject', this.$refs.gmap.$mapObject.getBounds)
      // console.log('google maps', bounds)

      let arrayLatLgnImoveis = [];
      let imoveis = this.immobiles;
      imoveis.forEach( (m,index) => {
        if(index < 11){
          arrayLatLgnImoveis.push(
            {
              lat: m.latitude,
              lgn: m.longitude
            }
          );
        }
        return;
      })
      console.log('this.$refs.gmap', this.$refs.gmap)
      let arrayCoordenadasProntas = arrayLatLgnImoveis.filter(this.pegaCoordenadasListaImoveis)
      console.log(arrayCoordenadasProntas);
      
    },
    pegaCoordenadasListaImoveis(c) {
      console.log('lat',c.lat)
      console.log('lgn',c.lgn)
      // if(c.lat >= this.bdsNorth.lng && c.lgn >= this.bdsNorth.lat && c.lat <= this.bdsSouth.lng && c.lgn <= this.bdsSouth.lat){
      if(c.lat >= this.bdsNorth.lng && c.lgn >= this.bdsNorth.lat){
          console.log('c menor que norte',c)
          this.imoveisFiltrados.push(c);
          return c;
        }
        return;
    },
    pegaPosicao(m){
      return {
        lat: parseFloat(m.latitude),
        lng: parseFloat(m.longitude)
      }
    },
    tilesloadedEvent() {
      // console.log('>>>>>>>>>> getCenter', this.$refs.gmap.$mapObject.getCenter())
      // console.log('>>>>>>>>>> getZoom', this.$refs.gmap.$mapObject.getZoom())
      
    },
    debounceImmobiles: debounce( function() {
      this.filterImmobiles();
    }, 300), 
    filterImmobiles (venda = false, aluguel = false) {
      if(venda){ this.filter.availability = 'Vender'}
      if(aluguel){ this.filter.availability = 'Alugar'}
      console.log('venda', venda)
      console.log('aluguel', aluguel)
      //loading
      this.isLoading = true
      //monta a query string para a url enviada a API
        const query = Object.keys(this.filter).map( (key) => {
          return `${encodeURIComponent(key)}=${encodeURIComponent(this.filter[key])}`
        }).join('&');
        axios.get(this.endpoint_immobile_index+'?'+query).then(response => { 
            this.isLoading = false
            this.immobiles = [...response.data.data]
          })
          .catch(function (error) {
            console.log(error); 
          })
        this.contagem++
        console.log(this.contagem)
    },
    navigate (page) {
      console.log('navigate', page)
      this.loading = true
      axios.get(this.endpoint_immobile_index+'?page='+page)
      .then(response => { 
          this.loading = false
          this.immobiles = response.data.data 
          this.pagination = response.data
          console.log('this.immobiles', response.data)
        })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {

      })
    }

  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Importando o bootstrap 4 */
@import "../../assets/styles/bootstrap4/bootstrap.min.css";

@import "../../assets/styles/pagina_inicial.css";

/*@media (min-width: 992px){
    .search-sec{
        position: relative; 
        top: -114px;
        width: 50%;
        max-height: 200px;
        background-color: rgba(0,0,0,0.6);
        padding: 16px 18px;
        box-sizing: border-box;
        border-radius: 3px;
    }
}*/

.botao_entrar {
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 32px !important;
}
.botao_corretor {
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 32px !important;
}
</style>
