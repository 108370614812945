import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuelidate from 'vuelidate'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from './store' //store do vuex
import VueSweetalert2 from 'vue-sweetalert2'; //import do sweet alert 2
import money from 'v-money'; //import do v money
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'




//form registration step by step
//global registration
import VueFormWizard from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import Vue2Filters from 'vue2-filters'


Vue.use(VueFormWizard)
Vue.use(money, {precision: 4}) //usando o v money
Vue.use(Vuelidate)
Vue.use(VueAxios, axios)
Vue.use(store)
Vue.use(VueSweetalert2)
Vue.use(Vue2Filters)

/**teste do laravel websockets */
import Echo from "laravel-echo"

window.Pusher = require('pusher-js');
window.Echo = new Echo({
  broadcaster: 'pusher',
  key: 'd42d4d98a22591c00f2f',
  cluster: 'us2',
});
/*window.Echo = new Echo({
    broadcaster: 'pusher',
    key: 'clube_de_imoveis',
    wsHost: '3.139.7.171',
    wsPort: 6001,
    // wsPort: 80,
    forceTLS: false,
    disableStats: true,
    enabledTransports: ['ws'],
    cluster: 'mt1',
});*/

/**
 * Google Maps
 */
 /*import * as VueGoogleMaps from 'gmap-vue'
 
 Vue.use(VueGoogleMaps, {
   load: {
     key: 'AIzaSyDOF_uaOR63rpqAXBDjhJ3B4siyzifdraY',
     libraries: 'places', // This is required if you use the Autocomplete plugin
     // OR: libraries: 'places,drawing'
     // OR: libraries: 'places,drawing,visualization'
     // (as you require)
  
     //// If you want to set the version, you can do so:
     // v: '3.26',
   },
  })*/
/* fim google maps */  

/*vue-gapi library client para utilizar google */
// import VueGapi from 'vue-gapi'

/*Vue.use(VueGapi, {
  apiKey: 'AIzaSyDOF_uaOR63rpqAXBDjhJ3B4siyzifdraY',
  clientId: '469801006681-lggukssrqn3vjb8piatri75lrq4dahid.apps.googleusercontent.com',
  // discoveryDocs: ['https://sheets.googleapis.com/$discovery/rest?version=v4'],
  // scope: 'https://www.googleapis.com/auth/spreadsheets',
})*/
/*FIM vue-gapi library client para utilizar google */

//sweetalert
window.swal = require('sweetalert');

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
