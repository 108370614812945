<template>
<div class="d-flex flex-column">
  <div id="autoscroll" class="card-body align-items-end overflow-auto" style="max-height:360px">
      <div
        class="d-flex flex-row"
        v-for='(msg,index) in mensagens'
        :key="index"
      >
        <div class="p-2 text-center" style="margin-rigth:2px">
          <img
            v-if="msg.photograph"
            class="rounded-circle"
            :src="endpoint_images + msg.photograph"
            width="30"
            height="30"
          >
          <img
            v-else
            class="rounded-circle"
            src="https://png.pngtree.com/png-vector/20190710/ourmid/pngtree-user-vector-avatar-png-image_1541962.jpg"
            width="30"
            height="30"
          >
        </div>
        <div class="p-2">
          <dl>
            <dt>{{msg.name}}</dt>
            <dd>{{ msg.message }}</dd>
            <dd><span style="font-size: 10px">{{msg.created_at}}</span></dd>
          </dl>
        </div>
      </div>
    <!-- </div> -->

    <!-- <div class="row mt-bottom mt-4" > -->
      
    <!-- </div> -->

  </div>
    <div class="d-flex flex-row">
        <div class="p-2 mr-2">
          <textarea
            v-model="mensagem"
            @keydown.enter="enviar"
            placeholder="Insira uma mensagem..."
            class="form-control ml-3"
            type="text"
            rows="2"
          ></textarea>
        </div>
        <div class="p-2">
          <button
            @click="enviar"
            class="btn btn-success mt-2"
          >Enviar</button>
        </div>
    </div>
  </div>
</template>

<script>

import { format } from 'date-fns-tz'
import { mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import Swal from 'sweetalert2'


export default {
  name: 'Mensagens',
  props: ['usuarios_da_conversa'],
  data () {
    return {
      mensagens: [],
      mensagem: null,
      endpoint_images: process.env.VUE_APP_IMAGES,
      format,
      userActive: null,
    }
  },
  validations: {
    mensagem: { required }
  },
  async mounted () {
        let idUser= await this.usuarios_da_conversa[0].id
        console.log('idUser = ', idUser)
        this.$store.dispatch('setInitialConversationsForUsersChat', {
          announcementId: this.$route.params.announcement_id,
          userId: idUser,
          loggedUserId: this.userLogged.user.id,
        })
      this.mensagens = await this.initialListConversationsChat  
      
      // console.log('mensagens', this.mensagens)
      //teste
      this.userActive = await this.currentUser
      // console.log('userActive', this.userActive)
      //faz o scroll automático
      this.scrollToBottom()
     
      //ouve o canal websocket de comunicação
      var that = this;
      window.Echo.channel(`chat`)
        .listen('.SendMessage', async (m) => {
          if(that.userActive && that.userActive === m.message.from){
            //o emitente é igual ao usuário da conversa, faz o push nas mensagens
            var novamensagem = {
              id: m.message.id,
              from: m.message.from,
              to: m.message.to,
              name: m.message.name,
              announcement: m.message.announcement,
              city: m.message.city,
              created_at: m.message.created_at,
              message: m.message.message,
              photograph: m.message.photograph,
              read: m.message.read,
              state: m.message.state
            }
            await that.mensagens.push(novamensagem)
            that.scrollToBottom()
          }else{
            //notificação
            const user = this.usuarios_da_conversa.filter( (u) => {  
              if(u.id === m.message.from){
                return u
              }
            })
            if(user){
              this.$store.dispatch('setNotification', true)
            }
          }
        })
  },
  watch: {
    initialListConversationsChat (n, o) {
      o
      this.mensagens = JSON.parse(JSON.stringify(n))
    },

  },
  computed: {
    ...mapState({
      userLogged: state => state.user,
      /*listUsersChat: state => state.listUsersChat,
      listConversationsChat: state => state.listConversationsChat,*/
      initialListConversationsChat: state => state.initialListConversationsChat,
      currentUser: state => state.currentUser,
      notification: state => state.notification,
    }),

  },
  methods: {
    //método de teste para pusher
    incomingChat (chatMessage) {
      console.log('chatMessage', chatMessage)
    },
    scrollToBottom () {
      if (this.mensagens.length > 5) {
        var container = this.$el.querySelector('#autoscroll');
        container.scrollTop = container.scrollHeight;
      }
    },
    async enviar () {
      this.$v.mensagem.$touch()
      //verifica se mensagem não está vazia
      if (!this.$v.mensagem.$invalid) {
        /*
          *obtém o tamanho da lista de usuários do chat, se a lista for maior do que 1 usuário, 
        +
          então precisa selecionar o usuário para depois iniciar o chat
          *aqui o usuário logado é o ANUNCIANTE
          *NÃO selecionou nenhum usuário para o chat
          *então deve por padrão selecionar o primeiro usuário da lista
          *que será o destinatário e o emissor o anunciante logado
        */
          
          if(this.currentUser !== undefined || this.currentUser !== null){
            await this.$store.dispatch('addNewMessageChat', {
              fromUser: this.userLogged.user.id,
              toUser: this.currentUser,
              message: this.mensagem,
              announcementId: this.$route.params.announcement_id,
              immobileId: this.$route.params.immobile_id,
            })
          }else{
            await this.$store.dispatch('addNewMessageChat', {
              fromUser: this.userLogged.user.id,
              toUser: this.listUsersChat[0].id,
              message: this.mensagem,
              announcementId: this.$route.params.announcement_id,
              immobileId: this.$route.params.immobile_id,
            }) 
          }
        this.mensagem = ''
        this.scrollToBottom()
      } else {
        //exibe erro na tela
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'É necessário preencher a mensagem!',
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1024px) {
  .textareademensagem {
    height: 150px;
  }
}

@media screen and (max-width: 800px) {
  .textareademensagem {
    height: 150px;
  }
}

@media screen and (max-width: 600px) {
  .textareademensagem {
    height: 100px;
  }
}
.flex-container {
  display: flex;
  flex-direction: row;
}
.mensagens {
  width: 552px;
  height: 399px;

  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  padding: 5px;
}

.mensagem {
  display: flex;
  align-self: flex-start;
  padding: 10px;
  min-width: 400px;
  height: fit-content;
  vertical-align: text-bottom;
}

.msgimg {
  display: flex;
  font-size: 1rem;
  align-items: flex-start;
  padding-right: 10px;
}

.msgtxt {
  display: flex;

  max-width: 400px;
  height: fit-content;
  border-radius: 10px;
  color: black;
  font-size: 1rem;
  font-weight: 500;
  align-items: flex-start;
  flex-wrap: wrap;
  -ms-word-wrap: break-all;
  word-break: break-word;
  white-space: pre-wrap;
  word-wrap: break-all;
  flex-direction: column;
}

.msgtxt p {
  padding: 0;
  font-size: 1rem;
}

.mensagens .mensagem .msgimg img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

.tempo {
  font-size: 12px;
  text-align: right;
}

.containerinput {
  padding-top: 18px;
  text-align: center;
}

.chatinput {
  width: 520px;
  height: 40px;
  max-height: 60px;
  word-break: break-word;
  border-radius: 10px;
  border: 1px solid #dadada;
  padding: 5px;
}

.msginputbtn {
  height: 40px;
  background-color: #28a745;
  border: 0;
  color: white;
  font-weight: 500;
}

.msginputbtn:hover {
  background-color: #1f8036;
}
</style>