<template>
  <div  v-if="imoveis" class="App"/>
</template>

<script>
  import axios from 'axios'
  // import MarkerClusterer from '@google/markerclusterer';
  import gmapsInit from '../../utils/gmaps';

export default {
  name: `Mapa`,
  props: ['imoveis'],
  data () {
    return {
      imoveisMapa: [],
      coordenadaCentral: null,
      endpoint_immobiles_into_bounds: process.env.VUE_APP_IMOVEIS_CONTIDOS_BOUNDS,
    }
  },
  async mounted() {
    try {   
      console.log()
      
      //carrega api google map
      const google = await gmapsInit();
      
      // const geocoder = new google.maps.Geocoder();
      //parse para float
      let latitude = await parseFloat(this.imoveis[0].latitude);
      let longitude = await parseFloat(this.imoveis[0].longitude);
      
      const centro = { lat: latitude, lng: longitude };
      const map = new google.maps.Map(this.$el, {
        zoom: 14,
        center: centro
      });
      
      // google.maps.event.addListener(map, 'bounds_changed', function(test) {
      google.maps.event.addListener(map, 'idle', () => {
                  var bounds =  map.getBounds();
                  var NElat = bounds.getNorthEast().lat();
                  var NElng = bounds.getNorthEast().lng();
                  var SWlat = bounds.getSouthWest().lat();
                  var SWlng = bounds.getSouthWest().lng();
                  //chama a função que atualizará a lista de imóveis
                  this.atualizaListaImoveisContidosNoRetanguloDoMapa(NElat, NElng, SWlat, SWlng);

                });
      // var bounds =  map.getBounds();
      // console.log('fora do evento bounds_changed map.fitBounds()',map.fitBounds(bounds));
      this.imoveis.map((c) => {
          let latLng = {lat: parseFloat(c.latitude), lng: parseFloat(c.longitude)};
          // const marker = new google.maps.Marker({ ...location, map });
          const marker = new google.maps.Marker({ position: latLng, map });
          marker
          // marker.addListener(`click`, () => markerClickHandler(marker));
          // return marker;
        });
      // const markers = listaDeImoveis.map((location) => {
      

      // põe os marcadores no mapa
      /*new google.maps.Marker({
        position: centro,
        map: map,
      });*/
      // map.setCenter(new google.maps.LatLng(-34, 151));
      /*geocoder.geocode({ address: `Austria` }, (results, status) => {
        if (status !== `OK` || !results[0]) {
          throw new Error(status);
        }
        map.setCenter(results[0].geometry.location);
        map.fitBounds(results[0].geometry.viewport);
      });*/
      /*const markerClickHandler = (marker) => {
        map.setZoom(15);
        map.setCenter(marker.getPosition());
      };
      const markers = locations
        .map((location) => {
          const marker = new google.maps.Marker({ ...location, map });
          marker.addListener(`click`, () => markerClickHandler(marker));
          return marker;
        });
      // eslint-disable-next-line no-new
      new MarkerClusterer(map, markers, {
        imagePath: `https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m`,
      });*/
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  },
  computed: {
    arrImoveis (){
      return this.imoveis
    }
  },
  methods: {
    atualizaListaImoveisContidosNoRetanguloDoMapa(NElat, NElng, SWlat, SWlng){
      //busca na API
      let dados = new FormData();
      dados.append('NElat', NElat);
      dados.append('NElng', NElng);
      dados.append('SWlat', SWlat);
      dados.append('SWlng', SWlng);
      axios.post(
        this.endpoint_immobiles_into_bounds,
        dados,
        {
            headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }).then(response => { 
          let imoveisDentroBoundsMapa = response.data;
          this.$emit('lista-imoveis-regiao-mapa',imoveisDentroBoundsMapa);
          return imoveisDentroBoundsMapa;
        })
      .catch(function (error) {
        console.log(error);
      })
    },
    getImmobiles() {
      //lista de imóveis da API
      axios.get(this.endpoint_immobile_index)
      .then(response => { 
          this.coordenadaCentral = {lat: response.data.data[0].latitude, lng: response.data.data[0].longitude}
          this.imoveisMapa = response.data.data
          return response.data.data
        })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {

      });
    },
  }
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
}
.App {
  /* width: 50vw; */
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}
</style>